import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { Container } from 'react-bootstrap';
import { ProjectsService } from '../../modules/project/services';
import { ApiAppInfo } from '../../modules/project/types';
import styles from './styles.module.css';
import { useNavigate } from 'react-router-dom';
import AppResume from './components/app_resume';
import AppTabsNavbar from './components/app_tabs_navbar';
import useTitle from 'hooks/useTitle';

/**
 * The dashboard of the selected application.
 * This page shows the main information (app data, app modules, app tags and app team) about the selectioned application.
 *
 * @component
 * @route /dashboard
 * @protected
 */
function DashboardPage() {
  const [appInfo, setAppInfo] = useState<ApiAppInfo>({} as ApiAppInfo);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { app_id } = useParams();
  const [reload, setReload] = useState<boolean>(false);

  useEffect(() => {
    if (app_id) {
      const fetchAppInfo = async () => {
        const apiAppInfo = await ProjectsService.getProjectById(app_id);
        setAppInfo(apiAppInfo);
        setLoading(false);
      };
      fetchAppInfo().then();
    }
  }, [app_id, reload]);

  useTitle(appInfo.name ? `${appInfo.name}` : 'Exocoding', appInfo);

  function updateAppInfo(appinfo: ApiAppInfo) {
    setAppInfo(appinfo);
  }

  function handleDeleteApp() {
    navigate('/homepage');
  }

  return (
    <div className={styles.container}>
      <Container
        className="container"
        style={{
          width: `calc(100vh - ${document.getElementById('navbar')?.clientHeight}px)`,
          height: `calc(100vh - ${document.getElementById('navbar')?.clientHeight}px)`
        }}
      >
        <AppResume
          appInfo={appInfo}
          updateAppInfo={updateAppInfo}
          loading={loading}
          onAfterDeleteApp={handleDeleteApp}
          reload={setReload}
        />
        <AppTabsNavbar appInfo={appInfo} />
      </Container>
    </div>
  );
}

export default DashboardPage;
