import React, { useCallback, useContext, useEffect, useState } from 'react';
import { ApiAppInfo } from '../../../../../../modules/project/types';
import { useTranslation } from 'react-i18next';
import { Accordion, Form, Row, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import styles from './styles.module.css';
import HelpPopover from '../../../../../../web_ui/workboard/sidebar/controls/components/Popover';
import HelpIcon from '../../../../../../web_ui/workboard/sidebar/controls/components/HelpIcon';
import { BackendFrameworkList, DatabaseList } from '../../../../../wizard/components/step4/data';
import {
  DesignSystemList,
  FrontendFrameworkList
} from '../../../../../wizard/components/step5/data';
import { ModuleSqlOptions } from 'modules/dashboard/types';
import { Layout } from 'modules/designer/types';
import { LayoutService } from 'modules/designer/services';
import ErrorIcon from 'web_ui/workboard/sidebar/controls/components/ErrorIcon';
import { DataSource } from 'modules/project/enum';
import { AppContext } from 'modules/project/store/app_context';

interface TechStackAppProps {
  // ApiAppInfo for read only properties.
  appInfo: ApiAppInfo;
  // Whether the application uses a low level SQL API.
  sql: boolean;
  // A layout for entire pages in the application
  defaultLayout: string;
  // Setter for 'sql'.
  onChangeModuleSql: (value: ModuleSqlOptions) => void;
  // Setter for 'defaultLayout'.
  onChangeDefaultLayout: React.Dispatch<React.SetStateAction<string>>;
  // Setter for dataSource type
  onChangeDataSourceType: (value: DataSource) => void;
}

function TechStackApp(props: TechStackAppProps) {
  const { t } = useTranslation();
  const [layouts, setLayouts] = useState<Layout[]>();
  const [showDefaultLayoutError, setShowDefaultLayoutError] = useState<boolean>(false);
  const appInfo = useContext(AppContext).projectInformation;

  const fetchLayout = useCallback(async () => {
    await LayoutService.getLayoutByApp(props.appInfo.id).then((fetchedLayout) => {
      setLayouts(fetchedLayout);
    });
  }, [props.appInfo.id]);

  useEffect(() => {
    fetchLayout();
  }, [fetchLayout]);

  useEffect(() => {
    if (
      layouts &&
      props.defaultLayout &&
      props.defaultLayout !== '' &&
      layouts?.filter((layout) => layout.uuid === props.defaultLayout).length === 0
    ) {
      setShowDefaultLayoutError(true);
    } else {
      setShowDefaultLayoutError(false);
    }
  }, [layouts, props.defaultLayout]);

  return (
    <Accordion defaultActiveKey={['0', '1']} alwaysOpen>
      {appInfo?.has_backend && (
        <Accordion.Item id={'accordionDatabase'} eventKey="0">
          <Accordion.Header id={'HeaderAccordionBackend'}>
            <h6 className={`text-body-emphasis`}>{t('appResume.Backend')}</h6>
          </Accordion.Header>
          <Accordion.Body className={`${styles.bodyProperties}`}>
            {/* Framework */}
            <Form.Group className="mb-3 pt-2">
              <Form.Label>{t('appResume.Framework')}</Form.Label>
              {BackendFrameworkList.filter(
                (framework) => props.appInfo.backend.framework === framework.id
              ).map((framework) => (
                <ToggleButton
                  className="form-control"
                  id={framework.id}
                  key={framework.id}
                  value={framework.id}
                  name="backendFramework"
                  disabled
                >
                  <span className={`fa fa-${framework.icon}`} /> {framework.name}
                </ToggleButton>
              ))}
            </Form.Group>
            {/* Database */}
            {appInfo.has_database && (
              <Form.Group className="mb-3 pt-2">
                <Form.Label>{t('appResume.properties.Database')}</Form.Label>
                <ToggleButtonGroup
                  type="radio"
                  name="database"
                  className="form-control"
                  style={{ borderColor: 'transparent' }}
                  onChange={(val: DataSource) => props.onChangeDataSourceType(val)}
                  value={
                    props.appInfo.data_sources.length > 0 ? props.appInfo.data_sources[0].type : ''
                  }
                >
                  {DatabaseList.map((database) => (
                    <ToggleButton
                      key={database.id}
                      id={database.id}
                      value={database.id as DataSource}
                      disabled={database.unavailable}
                    >
                      <span className={`fa fa-${database.icon}`} /> {database.name}
                    </ToggleButton>
                  ))}
                </ToggleButtonGroup>
              </Form.Group>
            )}
            {/* JDBC */}
            <Form.Group className="mb-3 pt-2">
              <Form.Label>
                {t('appResume.RepositoryType')}
                <HelpPopover
                  helpBoxProps={{
                    title: `${t('appResume.RepositoryType')}` || 'appResume.RepositoryType',
                    description: t('appResume.JdbcDescription') || 'appResume.JdbcDescription'
                  }}
                  placement="top"
                >
                  <HelpIcon />
                </HelpPopover>
              </Form.Label>
              <Form.Check>
                <ToggleButtonGroup
                  type="radio"
                  name="jdbc"
                  className="mb-2"
                  onChange={props.onChangeModuleSql}
                  value={props.sql ? ModuleSqlOptions.JDBC : ModuleSqlOptions.JPA}
                >
                  <ToggleButton id="radio-jpa" value={ModuleSqlOptions.JPA}>
                    {t('appResume.Jpa')}
                  </ToggleButton>
                  <ToggleButton id="radio-jdbc" value={ModuleSqlOptions.JDBC}>
                    {t('appResume.Jdbc')}
                  </ToggleButton>
                </ToggleButtonGroup>
              </Form.Check>
            </Form.Group>
          </Accordion.Body>
        </Accordion.Item>
      )}
      {appInfo?.has_frontend && (
        <Accordion.Item id={'accordionFrontend'} eventKey="1">
          <Accordion.Header id={'HeaderAccordionFrontend'}>
            <h6 className={`text-body-emphasis`}>{t('appResume.Frontend')}</h6>
          </Accordion.Header>
          <Accordion.Body className={`${styles.bodyProperties}`}>
            {/* Framework */}
            <Form.Group className="mb-3 pt-2">
              <Form.Label>{t('appResume.Framework')}</Form.Label>
              {FrontendFrameworkList.filter(
                (framework) => props.appInfo.frontend.framework === framework.id
              ).map((framework) => (
                <ToggleButton
                  className="form-control"
                  id={framework.id}
                  key={framework.id}
                  value={framework.id}
                  type="radio"
                  name="frontendFramework"
                  disabled
                >
                  <span className={`fa fa-${framework.icon}`} /> {framework.name}
                </ToggleButton>
              ))}
            </Form.Group>
            {/* Design System */}
            <Form.Group className="mb-3 pt-2">
              <Form.Label>{t('appResume.DesignSystem')}</Form.Label>
              {DesignSystemList.filter((design) =>
                props.appInfo.frontend.dependencies.some((item) => item === design.id)
              ).map((design) => (
                <ToggleButton
                  className="form-control"
                  id={design.id}
                  key={design.id}
                  value={design.id}
                  type="radio"
                  name="frontendDesignSystem"
                  disabled
                >
                  <span className={`fa fa-${design.icon}`} /> {design.name}
                </ToggleButton>
              ))}
            </Form.Group>
            {/* Default layout template */}
            <Form.Group className="mb-3 pt-2">
              <Form.Label>
                {showDefaultLayoutError && (
                  <ErrorIcon
                    title={t('appResume.DefaultLayoutNotFound')}
                    description={
                      t('appResume.DefaultLayoutNotFoundDescription') || 'DefaultLayoutNotFound'
                    }
                    note={[t('appResume.DefaultLayoutNotFoundNote01')]}
                  />
                )}

                {t('appResume.DefaultLayoutTemplate')}

                <HelpPopover
                  helpBoxProps={{
                    title:
                      `${t('appResume.DefaultLayoutTemplate')}` ||
                      'appResume.DefaultLayoutTemplate',
                    description:
                      t('appResume.DefaultLayoutTemplateDescription') ||
                      'appResume.DefaultLayoutTemplateDescription'
                  }}
                  placement="top"
                >
                  <HelpIcon />
                </HelpPopover>
              </Form.Label>
              <Row className="mb-3">
                <Form.Group>
                  <Form.Select
                    value={props.defaultLayout ?? ''}
                    onChange={(ev) => props.onChangeDefaultLayout(ev.target.value)}
                  >
                    {showDefaultLayoutError && (
                      <option value={'WRONGLAYOUT'}>{t('appResume.InvalidDefaultLayout')}</option>
                    )}
                    <option value=""> --- </option>
                    {layouts &&
                      layouts.map((layout) => (
                        <option key={layout.uuid} value={layout.uuid}>
                          {layout.name}
                        </option>
                      ))}
                  </Form.Select>
                </Form.Group>
              </Row>
            </Form.Group>
          </Accordion.Body>
        </Accordion.Item>
      )}
    </Accordion>
  );
}

export default TechStackApp;
