import React, { ReactNode } from 'react';
import { Container } from 'react-bootstrap';

type EditorRootProps = {
  children: ReactNode;
  hasContainer?: boolean;
};

function EditorRoot({ children, hasContainer = true }: EditorRootProps) {
  return (
    <div
      className={`flex-grow-1 ${hasContainer && 'ms-3 pe-3'}`}
      style={{ maxHeight: 'calc(100vh - 60px)', overflowY: 'auto' }}
    >
      {hasContainer ? <Container>{children}</Container> : <>{children}</>}
    </div>
  );
}

export default EditorRoot;
