import { NavData, NavStyles } from './index';
import { LayoutComponent } from '../../../../designer/types';

export const NAV_TEMPLATE: LayoutComponent<NavData, NavStyles> = {
  uuid: 'empty',
  type: 'NAV',
  data: {
    links: {
      items: {},
      children: {}
    },
    direction: 'left',
    width: 100,
    widthUnit: '%',
    maxWidth: 'none',
    minWidth: 'none',
    widthCalc: 20,
    height: 70,
    heightUnit: 'px',
    maxHeight: 'none',
    minHeight: 'none'
  },
  styles: {
    marginTop: 10,
    marginRight: 10,
    marginBottom: 10,
    marginLeft: 10,
    borderTop: 0,
    borderRight: 0,
    borderBottom: 0,
    borderLeft: 0,
    paddingTop: 0,
    paddingRight: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    color: '#000000',
    css: ''
  }
};
