import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, Link, useSearchParams } from 'react-router-dom';
import { Button, Container, Modal, Spinner } from 'react-bootstrap';
import LoginForm from './components/login_form';
import ErrorMessage from './components/message_error';
import { AuthService } from 'modules/auth/services';
import SocialButtons from 'modules/auth/components/social_buttons';
import useTitle from 'hooks/useTitle';
import Navbar from 'web_ui/navbar';

import styles from './styles.module.css';

type RedirectState = {
  signup?: {
    email: string;
    username: string;
  };
  password?: boolean;
  error?: { title: string; message: string };
};

/**
 * User sign in process
 *
 * @component
 */
function Login() {
  const { t } = useTranslation();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const [email, setEmail] = React.useState('');
  const [openModal, setOpenModal] = React.useState(false);
  const [isFirstLogin, setIsFirstLogin] = React.useState(false);
  const [gettingValueError, setGettingValueError] = React.useState(false);
  const [valueError, setValueError] = React.useState('');

  const [isLoading, setIsLoading] = React.useState(false);

  useTitle(t('tab.login'));

  useEffect(() => {
    if (location.state) {
      setOpenModal(true);
    }
    setIsFirstLogin(searchParams.get('isfirst') !== null);
  }, [location.state, searchParams]);

  const handleCloseDialog = () => {
    setOpenModal(false);
  };

  const oneStep: string = t('login_page.OneStep');
  const confirmAccount: string = t('login_page.ConfirmAccount');

  const passwordChange: string = t('login_page.PasswordChange');
  const passwordSuccess: string = t('login_page.PasswordSuccess');

  const isSignup = () => {
    if (location.state) {
      const { signup } = location.state as RedirectState;
      if (signup) {
        return true;
      }
    }
    return false;
  };

  const modalTitle = () => {
    if (location.state) {
      const { signup, password, error } = location.state as RedirectState;
      if (signup) {
        return oneStep;
      } else if (password) {
        return passwordChange;
      } else if (error) {
        return error.title;
      }
    }
  };

  const modalBody = () => {
    if (location.state) {
      const { signup, password, error } = location.state as RedirectState;
      if (signup) {
        return confirmAccount;
      } else if (password) {
        return passwordSuccess;
      } else if (error) {
        return error.message;
      }
    }
  };

  const resendVerificationEmail = async () => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);
    try {
      if (location.state) {
        const { signup } = location.state as RedirectState;
        if (signup) {
          await AuthService.resendVerificationEmail(signup.email.toLowerCase());
        }
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className={styles.PageWrap}>
        {gettingValueError && (
          <ErrorMessage
            setgettingValueError={setGettingValueError}
            valueError={valueError}
            email={email}
          />
        )}
        <Navbar context="login" />
        <Container className={styles.LoginContainer}>
          <Container className={`${styles.LoginFormCard}`}>
            {/* Sign In form */}
            <LoginForm
              isFirstLogin={isFirstLogin}
              setgettingValueError={setGettingValueError}
              setValueError={setValueError}
              email={email}
              setEmail={setEmail}
            />
            {/* Social links */}
            <Container className={`${styles.LoginExternalLink} ${styles.LoginExternalLinkCenter}`}>
              <Container>{t('ContinueWith')}</Container>
              <SocialButtons />
            </Container>
            {/* Create account link */}
            <Container className={`${styles.LoginExternalLink} ${styles.LoginExternalLinkCenter}`}>
              <Link id={'signUplink'} to="/signup">
                {t('login_page.NewInExocode')}
              </Link>
            </Container>
          </Container>
        </Container>
      </div>
      <Modal data-bs-theme={'dark'} show={openModal} onHide={handleCloseDialog} backdrop="static">
        <Modal.Header closeButton className={'bg-dark text-white border border-secundary'}>
          <Modal.Title>{modalTitle()}</Modal.Title>
        </Modal.Header>
        <Modal.Body className={'bg-dark text-white border border-secundary'}>
          {modalBody()}
          {isSignup() && (
            <div className="pt-2 d-flex justify-content-end">
              <Button
                id="button-resend-verification-email"
                onClick={() => resendVerificationEmail()}
                disabled={isLoading}
              >
                {isLoading && (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                )}
                Resend Email
              </Button>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Login;
