import React, { useEffect } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Navbar as BootstrapNavbar, Container, Nav, Breadcrumb } from 'react-bootstrap';
import UserProfileDropdown from 'routes/layout/user_profile_dropdown';
import CodeDownloadList from 'routes/layout/code_download_list';
import { ProjectsService } from 'modules/project/services';
import { DashboardService } from 'modules/dashboard/services';
import { ModuleInfo } from 'modules/dashboard/types';
import { ApiAppInfo } from 'modules/project/types';
import SessionContext from 'modules/auth/store';
import { Authorization } from 'modules/auth/session/authorization';
import { ContextRole, RoleAuthorities } from 'modules/auth/types/auth_types';
import { BreadcrumbModuleDropdown } from './components/navbar_dropdowns';
import ApplicationAvatar from 'web_ui/application_avatar';

import styles from './styles.module.css';
import Icon from 'web_ui/icon';
import { useQuery } from '../../../../hooks/useQuery';
import UserFeedback from 'routes/layout/user_feedback';
import ExocodingWalkthroughModal from 'web_ui/walkthrough_modal';
import { useTranslation } from 'react-i18next';

type NavbarProps = {
  application: ApiAppInfo;
  isFromVsCodeExtension?: boolean;
};

/**
 * Navbar used almost everywhere
 * TODO: Stop propagation - clicking on Modal should not close Dropdown menu
 *
 * @component
 */
function Navbar(props: NavbarProps) {
  const { t } = useTranslation();
  const queryParameters = useQuery();
  const { app_id, module_id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const session = React.useContext(SessionContext);
  const [modules, setModules] = React.useState<ModuleInfo[]>();
  const [selectedModule, setSelectedModule] = React.useState<ModuleInfo>();
  const [width, setWidth] = React.useState(window.innerWidth);
  const [displayOptions, setDisplayOptions] = React.useState<boolean>(false);
  const [showFeedback, setShowFeedback] = React.useState<boolean>(false);
  const [showAlert, setShowAlert] = React.useState<boolean>(false);
  const [showWalkthrough, setShowWalkthrough] = React.useState<boolean>(false);

  const isFromVsCodeExt = (): boolean => {
    const itemFound = queryParameters.get('vscode');
    if (itemFound) {
      return Boolean(itemFound);
    } else {
      return false;
    }
  };

  const setDownloads = session.setDownloads;
  useEffect(() => {
    async function loadDownloadList() {
      if (!app_id) return;
      const downloadList = await ProjectsService.getActiveJobsByApp(app_id);
      setDownloads(downloadList);
    }
    loadDownloadList();
  }, [app_id, setDownloads]);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  /**
   * Activated in the dropdown page list items 'onClick'
   * Stay on the same Studio mode after changing modules.
   */
  const handleModuleSelectOnChange = React.useCallback(
    (module: ModuleInfo) => {
      // Extract the studio mode from location.pathname
      // e.g. match[1] === "schema/829fdea7-75fd-4202-9d0c-d237567ef52f"
      const match = location.pathname.match(/\/?app\/[a-z0-9-]+\/module\/[a-z0-9-]+\/(.*)\/?/);
      if (match) {
        const mode = match[1].split('/')[0].toLowerCase();
        const tab = match[1].split('/')[1].toLowerCase();
        if (mode === 'ui') {
          navigate(
            `/app/${app_id}/module/${module.id}/ui${isFromVsCodeExt() ? '?vscode=true' : ''}`
          );
        } else if (mode === 'schema') {
          navigate(
            `/app/${app_id}/module/${module.id}/schema/829fdea7-75fd-4202-9d0c-d237567ef52f${
              isFromVsCodeExt() ? '?vscode=true' : ''
            }`
          );
        } else if (mode === 'logic') {
          navigate(
            `/app/${app_id}/module/${module.id}/logic/${tab}${
              isFromVsCodeExt() ? '?vscode=true' : ''
            }`
          );
        }
      }
    },
    [app_id, location.pathname, navigate]
  );

  /**
   * Fetch modules list.
   */
  const fetchModules = React.useCallback(async () => {
    if (app_id) {
      const modules = await DashboardService.getModulesByApp(app_id);
      if (module_id) {
        // set the selectedModule (default value shown in the dropdown list)
        const selectedModule = modules.find((x) => x.id === module_id);
        setSelectedModule(selectedModule);
      }
      setModules(modules);
    }
  }, [app_id, module_id]);

  React.useEffect(() => {
    try {
      fetchModules();
    } catch (error) {
      console.error(error);
    }
  }, [fetchModules]);

  const preventingClickFromVsCode = (ev: React.MouseEvent<HTMLElement, MouseEvent>) => {
    if (props.isFromVsCodeExtension) {
      ev.preventDefault();
      ev.stopPropagation();
    }
  };

  const displayHideOptionsOnMobile = () => {
    setDisplayOptions(!displayOptions);
  };

  useEffect(() => {
    if (!session.user?.creationTime) return;

    const accountCreationDate = new Date(session.user.creationTime);
    const currentDate = new Date();
    const diffTime = Math.abs(currentDate.getTime() - accountCreationDate.getTime());
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    let formsOpened: string[] = [];
    const storedFormsOpened = localStorage.getItem('formsOpened');
    if (storedFormsOpened) {
      try {
        formsOpened = JSON.parse(storedFormsOpened);
        if (!Array.isArray(formsOpened)) {
          formsOpened = [];
        }
      } catch {
        formsOpened = [];
      }
    }

    let formKey: string | null = null;
    if (diffDays <= 4) {
      formKey = 'form1to4';
    } else if (diffDays <= 10) {
      formKey = 'form5to10';
    } else if (diffDays <= 20) {
      formKey = 'form11to20';
    } else {
      formKey = 'formAfter21';
    }

    if (formKey && !formsOpened.includes(formKey)) {
      setShowAlert(true);
    } else {
      setShowAlert(false);
    }
  }, [session.user?.creationTime]);

  const handleFeedbackClick = () => {
    if (!session.user?.creationTime) return;

    setShowFeedback(true);
    setShowAlert(false);

    let formsOpened: string[] = [];
    const storedFormsOpened = localStorage.getItem('formsOpened');
    if (storedFormsOpened) {
      try {
        formsOpened = JSON.parse(storedFormsOpened);
        if (!Array.isArray(formsOpened)) {
          formsOpened = [];
        }
      } catch {
        formsOpened = [];
      }
    }

    const accountCreationDate = new Date(session.user.creationTime);
    const currentDate = new Date();
    const diffTime = Math.abs(currentDate.getTime() - accountCreationDate.getTime());
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    let formKey: string | null = null;
    if (diffDays <= 4) {
      formKey = 'form1to4';
    } else if (diffDays <= 10) {
      formKey = 'form5to10';
    } else if (diffDays <= 20) {
      formKey = 'form11to20';
    } else {
      formKey = 'formAfter21';
    }

    if (formKey && !formsOpened.includes(formKey)) {
      formsOpened.push(formKey);
      localStorage.setItem('formsOpened', JSON.stringify(formsOpened));
    }
  };

  function handleQuestionClick() {
    setShowWalkthrough(true);
  }

  return (
    <BootstrapNavbar className={`shadow-sm ${styles.StudioNavbar}`} expand="lg">
      <Container className={styles.StudioNavbarContainer}>
        {/* Left side */}
        <BootstrapNavbar.Brand
          onClick={(ev) => preventingClickFromVsCode(ev)}
          href={`/app/${app_id}/dashboard`}
        >
          <ApplicationAvatar name={props.application.name} />
        </BootstrapNavbar.Brand>
        <Nav className={`me-auto ${styles.navbarToHide}`}>
          <Breadcrumb className={styles.StudioNavbarBreadcrumbs}>
            <Breadcrumb.Item
              id={session.user?.username}
              onClick={(ev) => preventingClickFromVsCode(ev)}
              href={`/app/${app_id}/dashboard`}
              className={styles.lastOne}
            >
              {session.user?.username}
            </Breadcrumb.Item>
            <Breadcrumb.Item
              onClick={(ev) => preventingClickFromVsCode(ev)}
              id={props.application.name}
              href={`/app/${app_id}/dashboard`}
              className={styles.hidingThird}
            >
              {props.application.name}
            </Breadcrumb.Item>
            <div className={styles.hidingSecond}>
              <div className={styles.BreadcrumbDivider}>/</div>
              <div className={styles.BreadcrumbItem}>
                <BreadcrumbModuleDropdown
                  modulesList={modules}
                  selectedModule={selectedModule}
                  handleSelectModule={handleModuleSelectOnChange}
                  handleUpdateModulesList={() => fetchModules()}
                />
              </div>
            </div>
          </Breadcrumb>
        </Nav>
      </Container>
      {/* Right side */}
      {props.isFromVsCodeExtension ? (
        <></>
      ) : (
        <>
          {width <= 550 ? (
            <>
              <div className={styles.containerElipsis} onClick={() => displayHideOptionsOnMobile()}>
                <Icon iconName="ellipsis" extraProps="fs" />
              </div>
              {displayOptions && (
                <div className={styles.containerShowedElipsis}>
                  <Link to={'/settings/support'} style={{ marginRight: '10px' }}>
                    {t('Walkthrough.requestSupport')}
                  </Link>
                  <div style={{ borderBottom: '1px solid gray', width: '100%' }} />
                  <button className={styles.feedbackButton}>
                    <Icon iconName="question" onClick={handleQuestionClick}></Icon>
                  </button>
                  <div style={{ borderBottom: '1px solid gray', width: '100%' }} />
                  <Authorization
                    context={ContextRole.APP}
                    allowedAuthorities={[RoleAuthorities.GENERATE_CODE]}
                  >
                    <CodeDownloadList downloads={session.downloads} />
                  </Authorization>
                  <div style={{ borderBottom: '1px solid gray', width: '100%' }} />
                  <button className={styles.feedbackButton}>
                    {showAlert && <span className={styles.alertIcon}>!</span>}
                    <Icon iconName="bullhorn" onClick={handleFeedbackClick}></Icon>
                  </button>
                  <div style={{ borderBottom: '1px solid gray', width: '100%' }} />
                  <UserProfileDropdown session={session} />
                </div>
              )}
            </>
          ) : (
            <>
              <div
                style={{
                  flex: 0.5,
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  textAlign: 'right'
                }}
              >
                <Link to={'/settings/support'} style={{ marginRight: '10px' }}>
                  {t('Walkthrough.requestSupport')}
                </Link>
                <button className={styles.feedbackButton}>
                  <Icon iconName="question" onClick={handleQuestionClick}></Icon>
                </button>
                <button className={styles.feedbackButton}>
                  {showAlert && <span className={styles.alertIcon}>!</span>}
                  <Icon iconName="bullhorn" onClick={handleFeedbackClick}></Icon>
                </button>
                <Authorization
                  context={ContextRole.APP}
                  allowedAuthorities={[RoleAuthorities.GENERATE_CODE]}
                >
                  <CodeDownloadList downloads={session.downloads} />
                </Authorization>
              </div>
              <UserProfileDropdown session={session} />
            </>
          )}
        </>
      )}
      {session.user && (
        <UserFeedback
          show={showFeedback}
          onClose={() => setShowFeedback(false)}
          userCreationTime={session.user?.creationTime}
        />
      )}
      <ExocodingWalkthroughModal
        show={showWalkthrough}
        onClose={() => setShowWalkthrough(false)}
        fromHelp={true}
      />
    </BootstrapNavbar>
  );
}

export default Navbar;
