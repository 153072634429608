import React, { ChangeEvent, useCallback, useContext, useEffect, useState } from 'react';
import styles from './styles.module.css';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import {
  ParameterEndpoint,
  INPUT_TYPES,
  InputType,
  DATA_TYPES,
  DataType,
  ObjectSimple,
  FunctionParameter,
  ParameterEndpointCrud
} from 'modules/logic_builder/types';
import { EndpointsService, ObjectsService } from 'modules/logic_builder/services';
import LogicBuilderContext from 'modules/logic_builder/store';
import { useTranslation } from 'react-i18next';
import { parameterNameRegex } from '../../../../../utils/regex';
import { CrudData } from 'routes/automation_wizard/components/wizard_steps/crud';

type EndpointParameterCreatorProps = {
  show: boolean;
  fetchParameters: () => void;
  onClose: () => void;
  endpointId: string;
  parameterWithObjectTypeExists: boolean;
  crudData?: CrudData;
};

export function EndpointParameterCreatorDialog(props: EndpointParameterCreatorProps) {
  const { module_id } = useContext(LogicBuilderContext);
  const [isNameInvalid, setIsNameInvalid] = useState(false);
  const [endpointParameter, setEndpointParameter] = useState<ParameterEndpointCrud>();
  const [objects, setObjects] = useState<ObjectSimple[]>([]);
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState<boolean>(false);

  // disableTypeSelectInput is used as a condition to show JsonEditor and Type select input.
  // If true enforce JSON Type select input and show JsonEditor.
  const [disableTypeSelectInput, setDisableTypeSelectInput] = useState(false);

  const fetchObjects = useCallback(async () => {
    if (!module_id) return;
    await ObjectsService.getObjectsByModule(module_id).then((fetchedObjects) => {
      setObjects(fetchedObjects);
    });
  }, [module_id]);

  useEffect(() => {
    if (!props.show) return;

    fetchObjects();
  }, [fetchObjects, props.show]);

  useEffect(() => {
    if (!props.show) return;

    setEndpointParameter({
      name: '',
      description: '',
      required: false,
      type: 'BOOLEAN',
      list: false,
      objectUuid: '',
      inputType: 'NONE'
    });
    setDisableTypeSelectInput(false);
  }, [props.show]);

  async function onSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.stopPropagation();
    event.preventDefault();

    if (!endpointParameter || !module_id) return;

    const localEndpointParameter = { ...endpointParameter };

    await EndpointsService.createParameter(props.endpointId, localEndpointParameter)
      .then(() => {
        props.fetchParameters();
        props.onClose();
      })
      .catch((err) => {
        setErrorMessage(true);
      });
  }

  function parameterInputTypeOnChange(event: ChangeEvent<HTMLSelectElement>) {
    const localEndpointParameter = {
      ...endpointParameter,
      inputType: event.target.value as InputType
    } as ParameterEndpointCrud;

    if (event.target.value === 'BODY') {
      localEndpointParameter.type = 'OBJECT';
      setDisableTypeSelectInput(true);
    } else {
      if (disableTypeSelectInput) {
        localEndpointParameter.objectUuid = '';
        localEndpointParameter.type = 'BOOLEAN';
        setDisableTypeSelectInput(false);
      }
    }
    setEndpointParameter(localEndpointParameter);
  }

  return (
    <Modal
      show={props.show}
      onHide={() => {
        setErrorMessage(false);
        props.onClose();
      }}
      className={styles.ParameterCreator}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>{t('logicBuilder.createEndpointDialog.ParameterCreator')}</Modal.Title>
      </Modal.Header>
      <Form onSubmit={onSubmit}>
        <Modal.Body>
          {errorMessage && (
            <span className={styles.errorMsg}>
              {`*${t('logicBuilder.errorCreatingEditingEndpointParameters')}`}
            </span>
          )}
          <Form.Group className="mb-3" controlId="formName">
            <Form.Label>{t('logicBuilder.Name')}</Form.Label>
            <Form.Control
              type="text"
              placeholder={`${t('logicBuilder.Name')}...`}
              isInvalid={isNameInvalid}
              value={endpointParameter?.name ?? ''}
              onChange={(e) => {
                setIsNameInvalid(!parameterNameRegex.test(e.target.value));
                setEndpointParameter({
                  ...endpointParameter,
                  name: e.target.value
                } as ParameterEndpointCrud);
              }}
              required
            />
            <Form.Control.Feedback type="invalid">
              {t('logicBuilder.ValidName')}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3" controlId="formInput">
            <Form.Label>Input</Form.Label>
            <Form.Select
              required
              onChange={parameterInputTypeOnChange}
              value={endpointParameter?.inputType ?? 'default'}
            >
              {!endpointParameter?.inputType && (
                <option value={'default'}>Select input type</option>
              )}
              {Object.keys(INPUT_TYPES).map((input) => {
                return (
                  <option
                    key={input}
                    value={input}
                    disabled={props.parameterWithObjectTypeExists && input === 'BODY'}
                  >
                    {INPUT_TYPES[input as InputType]}
                  </option>
                );
              })}
            </Form.Select>
          </Form.Group>

          <Form.Group className="mb-3" controlId="formType">
            <Form.Label> {t('logicBuilder.Type')}</Form.Label>
            <Form.Select
              required
              disabled={disableTypeSelectInput}
              onChange={(e) =>
                setEndpointParameter({
                  ...endpointParameter,
                  type: e.target.value,
                  objectUuid: '' // Reset objectUuid.
                } as ParameterEndpointCrud)
              }
              value={endpointParameter?.type ?? 'default'}
            >
              {!endpointParameter?.type && <option value={'default'}>Select parameter type</option>}
              {Object.keys(DATA_TYPES).map((type) => {
                if (type === 'OBJECT' && !disableTypeSelectInput) return null;

                return (
                  <option key={type} value={type}>
                    {DATA_TYPES[type as DataType]}
                  </option>
                );
              })}
            </Form.Select>
          </Form.Group>

          <Form.Group className="mb-3" controlId="formDescription">
            <Form.Label>{t('logicBuilder.Description')}</Form.Label>
            <Form.Control
              as="textarea"
              placeholder={`${t('logicBuilder.Description')}...`}
              rows={2}
              maxLength={64}
              className="ml-3"
              onChange={(e) =>
                setEndpointParameter({
                  ...endpointParameter,
                  description: e.target.value
                } as ParameterEndpointCrud)
              }
              value={endpointParameter?.description ?? ''}
            />
          </Form.Group>

          <Row className="mb-3">
            <Col sm={6}>
              <Form.Group controlId="formIsArray">
                <Form.Check
                  inline
                  label={`${t('logicBuilder.createEndpointDialog.IsArray')}`}
                  type="checkbox"
                  name="isArray"
                  onChange={(e) =>
                    setEndpointParameter({
                      ...endpointParameter,
                      list: e.target.checked
                    } as ParameterEndpointCrud)
                  }
                  checked={endpointParameter?.list ?? false}
                />
              </Form.Group>
            </Col>

            <Col sm={6}>
              <Form.Group controlId="formIsRequired">
                <Form.Check
                  inline
                  label={`${t('logicBuilder.createEndpointDialog.IsRequired')}`}
                  type="checkbox"
                  name="isRequired"
                  onChange={(e) =>
                    setEndpointParameter({
                      ...endpointParameter,
                      required: e.target.checked
                    } as ParameterEndpointCrud)
                  }
                  checked={endpointParameter?.required ?? false}
                />
              </Form.Group>
            </Col>
          </Row>
          <Form.Group className="mb-3" controlId="formType">
            <Form.Label>{t('logicBuilder.Objects')}</Form.Label>
            <Form.Select
              required
              disabled={!disableTypeSelectInput}
              onChange={(e) =>
                setEndpointParameter({
                  ...endpointParameter,
                  objectUuid: e.target.value === 'default' ? '' : e.target.value
                } as ParameterEndpointCrud)
              }
              value={endpointParameter?.objectUuid ?? 'default'}
            >
              {!endpointParameter?.objectUuid && <option value={'default'}>Empty object</option>}
              {objects.map((object) => {
                return (
                  <option key={object.uuid} value={object.uuid}>
                    {object.name}
                  </option>
                );
              })}
            </Form.Select>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => props.onClose()}>
            Cancel
          </Button>
          <Button
            id="saveButton"
            variant="primary"
            type="submit"
            disabled={isNameInvalid || !endpointParameter?.inputType || !endpointParameter?.type}
          >
            {t('logicBuilder.Save')}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
