import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import { DashboardService } from 'modules/dashboard/services';
import { ModuleInfo } from 'modules/dashboard/types';
import Module from '../../../module';
import CreateModuleModal from '../../../../../../modules/project/components/create_module_modal';
import { useTranslation } from 'react-i18next';
import Icon from 'web_ui/icon';
import EmptyMessage from 'web_ui/empty';
import { ListGroup } from 'react-bootstrap';
import { Authorization } from 'modules/auth/session/authorization';
import { ContextRole, RoleAuthorities } from 'modules/auth/types/auth_types';

import styles from './styles.module.css';
import HelpPopover from 'web_ui/workboard/sidebar/controls/components/Popover';

function SearchModules() {
  const [modules, setModules] = useState<ModuleInfo[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const { app_id } = useParams();
  const { t } = useTranslation();

  const [showModal, setShowModal] = useState<boolean>(false);

  const moduleListHeaderRef = useRef<HTMLHeadingElement>(null);
  const moduleListRef = useRef<HTMLHeadingElement>(null);

  const fetchModules = useCallback(async () => {
    if (!app_id) return;
    setLoading(true);
    await DashboardService.getModulesByApp(app_id)
      .then((modulesByApp) => {
        setModules(modulesByApp);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [app_id]);

  useEffect(() => {
    fetchModules();
  }, [fetchModules]);

  const onRefresh = useCallback(() => {
    fetchModules();
  }, [fetchModules]);

  const handleCloseCreateModuleModal = useCallback(() => {
    setShowModal(false);
  }, []);

  const updateModulesList = (module: ModuleInfo) => {
    const concat = modules.concat(module);
    setModules(concat);
  };

  return (
    <>
      <div className={`${styles.searchListHeader} justify-content-end`} ref={moduleListHeaderRef}>
        <Authorization
          context={ContextRole.APP}
          allowedAuthorities={[RoleAuthorities.MANAGE_MODULE]}
        >
          <HelpPopover
            helpBoxProps={{
              title: t('appResume.module.New') || 'appResume.module.New'
            }}
            placement="top"
          >
            <Button id={'createButton'} onClick={() => setShowModal(true)}>
              <Icon iconName="plus"></Icon>
              {/* {t('appResume.module.New')} */}
            </Button>
          </HelpPopover>
        </Authorization>
      </div>

      {loading && (
        <div className={`fa-3x ${styles.spinner}`}>
          <i className="fas fa-spinner fa-spin"></i>
        </div>
      )}

      {!loading && (
        <div
          className={`bg-body border ${styles.moduleList} ${
            modules.length < 1 ? styles.alignVertical : ''
          }`}
          ref={moduleListRef}
        >
          {modules.length > 0 && (
            <ListGroup>
              {modules.map((module, index) => {
                return (
                  <Module
                    key={module.id + '' + index}
                    module={module}
                    onRefresh={onRefresh}
                    modules={modules}
                  />
                );
              })}
            </ListGroup>
          )}

          {modules.length < 1 && (
            <div className="w-100 h-100" id="bodyMessage">
              <EmptyMessage
                message={t('appResume.module.NoModules')}
                icon="exclamation"
                actionMessage={t('appResume.module.CreateModule') ?? ''}
                linkAction={() => setShowModal(true)}
              />
            </div>
          )}
        </div>
      )}

      {app_id && (
        <CreateModuleModal
          modules={modules}
          onCloseRequest={handleCloseCreateModuleModal}
          showModal={showModal}
          updateModulesList={updateModulesList}
          currentModules={modules}
          loading={loading}
        />
      )}
    </>
  );
}

export default SearchModules;
