import React, { useContext, useState } from 'react';
import { Endpoint, METHODS } from '../../types';
import styles from './styles.module.css';
import { Badge } from 'react-bootstrap';
import { EndpointEditorDialog } from '../dialogs';
import Icon from 'web_ui/icon';
import { CrudData } from 'routes/automation_wizard/components/wizard_steps/crud';
import EditButton from '../edit_button/edit_button';
import { useTranslation } from 'react-i18next';
import LogicBuilderContext from 'modules/logic_builder/store';
import { TagItem } from '../dialogs/endpoint_editor_dialog/tag_item';
import useWindowDimensions from 'modules/designer/hooks/window';
import { CodePreviewType } from 'web_ui/code_editor_modal/editor';
import CodeEditorModal from 'web_ui/code_editor_modal';

type EndpointInstanceProps = {
  // tagUuid?: string;
  showEndpointEditorOnClick: boolean;
  // The full list of roles.
  roles?: string[];
  // Apply custom styles.
  className?: string;
  // enableDialog?: boolean;
  cursor?: string;
  endpoint: Endpoint;
  controllerPath?: string;
  crudData?: CrudData;
  showEditBtn: boolean;
};

type EndpointStyles = {
  badge: string;
  border: string;
  background: string;
};

export const ENDPOINT_COLORS: { [key: string]: EndpointStyles } = {
  [METHODS.POST]: {
    badge: 'PostBackgroundColor', // css classes because using values did not work with bootstrap
    border: '1px solid #198754',
    background: 'rgba(25, 135, 84, 0.1)'
  },
  [METHODS.PUT]: {
    badge: 'PutBackgroundColor',
    border: '1px solid #FFC107',
    background: 'rgba(255, 193, 7, 0.1)'
  },
  [METHODS.GET]: {
    badge: 'GetBackgroundColor',
    border: '1px solid #0DCAF0',
    background: 'rgba(13, 202, 240, 0.1)'
  },
  [METHODS.PATCH]: {
    badge: 'PatchBackgroundColor',
    border: '1px solid #BA38E8',
    background: 'rgba(186, 56, 232, 0.1)'
  },
  [METHODS.DELETE]: {
    badge: 'DeleteBackgroundColor',
    border: '1px solid #DC3545',
    background: 'rgba(220, 53, 69, 0.1)'
  }
};

export function EndpointInstance(props: EndpointInstanceProps) {
  const { tags } = useContext(LogicBuilderContext);
  const [showEndpointEditorDialog, setShowEndpointEditorDialog] = useState(false);
  const [showCodePreviewDialog, setShowCodePreviewDialog] = useState<boolean>(false);
  const { t } = useTranslation();
  const windowDimension = useWindowDimensions();

  const renderPath = () => {
    const controller =
      props?.crudData?.controllers[
        props.crudData?.controllers.findIndex((item) => item.uuid === props.endpoint.controllerUuid)
      ]?.path;
    const controllerPath = props.controllerPath ?? controller ?? '';
    return `${controllerPath}${props.endpoint.path}`;
  };

  const openEditor = () => {
    setShowEndpointEditorDialog(true);
  };
  return (
    <>
      <div
        id={props.endpoint.name}
        className={`${styles.EndpointWrapper} ${props.className}`}
        style={{
          background: ENDPOINT_COLORS[props.endpoint.method].background,
          border: ENDPOINT_COLORS[props.endpoint.method].border,
          cursor: props.cursor ?? 'default',
          position: 'relative'
        }}
      >
        <div className={`${styles.wrapMethodAndPath}`} id={props.endpoint.path}>
          <div
            className={`${styles.EndpointDescription} ${props.roles && styles.EndpointDescription}`}
          >
            <Badge
              className={`${styles.Badge} ${styles[ENDPOINT_COLORS[props.endpoint.method].badge]}`}
            >
              {props.endpoint.method}
            </Badge>
          </div>
          <div>
            <div className={styles.UrlRequest}>
              <p className="mb-0">{renderPath()}</p>
            </div>
            {windowDimension.width > 775 && (
              <div
                className={styles.tagContainer}
                style={windowDimension.width <= 1175 ? { width: 300 } : {}}
              >
                {Object.values(props.endpoint.tags).map((tagId) => {
                  const tag = tags[tagId];
                  if (!tag) return null;
                  return <TagItem tag={tag} key={tag.id} viewOnly={true} />;
                })}
              </div>
            )}
          </div>
        </div>
        <div
          className={`${styles.Details}`}
          style={{ color: `${ENDPOINT_COLORS[props.endpoint.method].border.split(' ')[2]}` }}
        >
          <p className="mb-0 d-none d-md-block">
            {props.endpoint.summary && props.endpoint.summary.length > 36
              ? props.endpoint.summary.substring(0, 32) + '...'
              : props.endpoint.summary}
          </p>

          {props.endpoint.deprecated && (
            <div
              className={`badge text-bg-warning d-flex justify-content-center align-items-center ${styles.Badge}`}
            >
              {t('logicBuilder.Deprecated')}
            </div>
          )}

          {props.endpoint.accessLevel === 'PUBLIC' && (
            <span className="badge rounded-pill text-bg-light">
              <Icon iconName="lock-open"></Icon>
            </span>
          )}
          <div
            id="codePreview"
            className={`bg-body-tertiary shadow-sm ${styles.CodePreviewBtn}`}
            onClick={() => setShowCodePreviewDialog(true)}
          >
            <Icon iconName="fa-solid fa-code" extraProps="text-secondary h7" />
          </div>
          {props.showEditBtn && <EditButton execute={openEditor} />}
        </div>
        {/* Endpoint editor Dialog does not have roles list. */}
        {/* {props.roles && (
          <div className={styles.EndpointRolesSelection}>
            {props.roles.map((role) => {
              return (
                <div key={role} className={styles.HeaderRoleCheckbox}>
                  <input
                    type="checkbox"
                    id={role}
                    name={role}
                    value={role}
                    onClick={(e) => handleSelectRoleOnChange(e, endpoint.id)}
                    defaultChecked={endpoint.activeRoles.includes(role)}
                  />
                </div>
              );
            })}
          </div>
          )} */}
        {/* This div helps with alignment between roles */}
        <div className={styles.EndpointToggle} />
      </div>
      <EndpointEditorDialog
        endpointId={props.endpoint.uuid}
        show={showEndpointEditorDialog}
        onClose={() => setShowEndpointEditorDialog(false)}
      />
      {props.endpoint.uuid && (
        <CodeEditorModal
          show={showCodePreviewDialog}
          handleClose={() => setShowCodePreviewDialog(false)}
          id={props.endpoint.uuid}
          previewType={CodePreviewType.ENDPOINT}
        />
      )}
    </>
  );
}
