import React, { useContext } from 'react';
import styles from './styles.module.css';
import { Badge, Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { AUTOMATIONS_STEPS, AUTOMATION_TYPES, SelectedAutomation } from '../../types';
import { AppContext } from '../../../../modules/project/store/app_context';

type Step0Props = {
  selectedAutomation: SelectedAutomation;
  setSelectedAutomation: (s: SelectedAutomation) => void;
};

export function Step0(props: Step0Props) {
  const { t } = useTranslation();
  const appInfo = useContext(AppContext).projectInformation;

  function handleAutomationOnClick(automation: keyof typeof AUTOMATION_TYPES): void {
    props.setSelectedAutomation(AUTOMATIONS_STEPS[automation]);
  }

  return (
    <div className={styles.StepWrapper}>
      {/* Cards */}
      <div id="headerMessage" className={styles.Title}>
        {t('automation.Step0Title')}
      </div>
      <div id="list-group" className={styles.CardsWrapper}>
        {Object.values(AUTOMATIONS_STEPS)
          .filter(
            (automation) =>
              (!automation.needFrontend || appInfo?.has_frontend) &&
              (!automation.needBackend || appInfo?.has_backend) &&
              (!automation.needDatabase || appInfo?.has_database)
          )
          .map((automation) => {
            return (
              <Card
                key={automation.name}
                className={`${styles.Card}`}
                onClick={() =>
                  handleAutomationOnClick(automation.name as keyof typeof AUTOMATION_TYPES)
                }
              >
                <Card.Body
                  id={`${automation.title.toLocaleLowerCase()}Card`}
                  style={{ padding: 0 }}
                >
                  <Card.Title
                    className={`${styles.CardTitle} ${
                      props.selectedAutomation.name === automation.name && styles.SelectedCard
                    }`}
                  >
                    {automation.title}
                  </Card.Title>
                  <Card.Text
                    id={`${automation.name.toLocaleLowerCase()}Description`}
                    className={styles.CardText}
                  >
                    {automation.description}
                  </Card.Text>
                </Card.Body>
                <Card.Footer className={styles.CardFooter}>
                  {automation.tags.map((tag, index) => {
                    return (
                      <Badge key={index} bg="success" className={styles.CardTags}>
                        {tag}
                      </Badge>
                    );
                  })}
                </Card.Footer>
              </Card>
            );
          })}
      </div>
    </div>
  );
}
