import {
  DataType,
  ObjectType,
  SchemaItem,
  SchemaItems,
  SchemaProperties
} from 'modules/logic_builder/types';
import { Column, EnumFrame, Relationship, Table } from 'modules/modeler/types';
import { v4 as uuidv4 } from 'uuid';
import { RelationshipTypes } from '../../../modeler/studio/frames/table';
import { isEmptyOrOnlySpaces } from '../../../../utils/inputValidation';
import { ModuleInfo } from '../../../dashboard/types';

const isFrom = (relationship: Relationship, tableId: string): boolean => {
  return relationship.from === tableId;
};

export const addTableRelationshipsColumns = (
  tableId: string,
  moduleId: string | undefined,
  modules: ModuleInfo[],
  tables: Table[],
  relationships: Relationship[],
  tablesToCheck: string[],
  tablesChecked: string[],
  propertiesList: Property[],
  order: number
): void => {
  for (const relationship of Object.values(relationships)) {
    // Exclude all relationships unrelated to this table
    if (tableId !== relationship.from && tableId !== relationship.to) {
      continue;
    }

    let isFkSide = false;
    if (relationship.type === RelationshipTypes.ONE2MANY) {
      isFkSide = !isFrom(relationship, tableId);
    } else {
      // 'ONE2ONE', 'MANY2ONE'
      isFkSide = isFrom(relationship, tableId);
    }

    let isList = false;
    if (relationship.type === RelationshipTypes.ONE2MANY) {
      isList = isFrom(relationship, tableId);
    } else if (relationship.type === RelationshipTypes.MANY2ONE) {
      isList = !isFrom(relationship, tableId);
    }

    // Check if the relationship is bidirectional
    if (!relationship.bidirectional && !isFkSide) {
      continue;
    }

    const otherTableId = isFrom(relationship, tableId) ? relationship.to : relationship.from;
    const otherTable = tables.find((t) => t.uuid === otherTableId);
    if (!otherTable) {
      continue;
    }

    // Do not create relationships to the same table
    // since it would repeat the same subtree in the editor (same ID's)
    if (tablesChecked.includes(otherTableId)) {
      continue;
    }

    // Composite property derived from a relationship (can be related to one/many columns)
    const entityName = otherTable.content.data.name;
    const fromToName = isFrom(relationship, tableId) ? relationship.fromName : relationship.toName;
    const objectName = isEmptyOrOnlySpaces(fromToName) ? entityName : fromToName;

    let propertyName = objectName + (isList ? 'List' : '');
    const nrDuplicates = propertiesList.filter(
      // Search for duplicates: same entity + same name beginning
      (p) => p.entityId === otherTableId && p.name.startsWith(propertyName)
    ).length;
    if (nrDuplicates !== 0) {
      // add a suffix
      propertyName += '_' + nrDuplicates;
    }

    const moduleName =
      otherTable.moduleId !== moduleId
        ? modules.find((mod) => mod.id === otherTable.moduleId)?.name
        : undefined;

    const prop: Property = {
      id: uuidv4(),
      name: propertyName,
      objectName: objectName,
      description: '',
      type: 'OBJECT',
      list: isList,
      object: uuidv4(), //otherTableId, relationship.id
      readOnly: false,
      exported: true,
      exportedName: propertyName,
      checked: false,
      entityName: entityName,
      fromEntity: true,
      relationshipId: relationship.id,
      isFK: isFkSide,
      isPK: false,
      columnId: undefined,
      entityId: otherTableId,
      showWhenObjectIsComplete: true,
      itemOrder: order++,
      required: false,
      moduleName: moduleName
    };
    if (!tablesChecked.includes(otherTableId)) {
      tablesToCheck.push(otherTableId);
    }
    propertiesList.push(prop);
  }
};

export const buildPropertiesListFromEntityColumns = (
  tableId: string,
  tables: Table[],
  columns: Column[],
  enums: EnumFrame[],
  relationships: Relationship[],
  isObjectComplete: boolean,
  propertiesList: Property[]
): number => {
  let order = 0;
  const tableInfo = tables.find((t) => t.uuid === tableId);
  if (!tableInfo) {
    return order;
  }

  for (const columnId of tableInfo.content.data.columns) {
    const column = columns.find((c) => c.uuid === columnId);
    if (!column) continue;

    // Find the table (entity) id.
    let relationshipId = undefined;
    if (column.isFK) {
      const tableId = column.tableUUID;
      for (const relationship of Object.values(relationships)) {
        // Exclude all relationships unrelated to this table
        if (tableId !== relationship.from && tableId !== relationship.to) {
          continue;
        }
        const findComponentKey = Object.keys(relationship.components).find(
          (fromColumnId) =>
            columnId === fromColumnId || columnId === relationship.components[fromColumnId]
        );
        if (!findComponentKey) {
          continue;
        }

        let isFkSide = false;
        if (relationship.type === 'ONE2MANY') {
          isFkSide = !isFrom(relationship, tableId);
        } else {
          // 'ONE2ONE', 'MANY2ONE'
          isFkSide = isFrom(relationship, tableId);
        }

        if (isFkSide) {
          relationshipId = relationship.id;
          break;
        }
      }
    }

    let enumName = '';
    if (column.type === 'ENUM') {
      const findEnum = enums.find((e) => e.uuid === column.enumUUID);
      enumName = findEnum?.content.data.name;
    }

    let objDataType: DataType;
    switch (column.type) {
      case 'CHAR':
        {
          // a CHAR whose length is greater than one is a STRING
          // otherwise is a CHAR
          const len = parseInt(column.properties ? column.properties['length'] : '');
          objDataType = len > 1 ? 'STRING' : 'CHAR';
        }
        break;
      default:
        objDataType = column.type as DataType;
        break;
    }

    // Simple property connected to a single column.
    const prop: Property = {
      id: uuidv4(),
      name: column.name,
      objectName: '',
      description: column.description,
      type: objDataType,
      list: false,
      object: undefined,
      enum: column.enumUUID,
      enumName: enumName,
      readOnly: false,
      exported: true,
      exportedName: column.name,
      checked: true,
      entityName: undefined,
      fromEntity: true,
      isFK: column.isFK,
      isPK: column.isPK,
      columnId: column.uuid,
      entityId: column.tableUUID,
      relationshipId: relationshipId,
      itemOrder: order++,
      required: column.isPK
    };

    propertiesList.push(prop);
  }
  return order;
};

// For non entity mode. Called when saving the object.
export function buildObject(
  objectName: string,
  objectDescription: string,
  backend: boolean,
  frontend: boolean,
  rootObject: string,
  entityId: string,
  properties: Record<string, Property[]>
): ObjectType | null {
  const fullObject: ObjectType = {
    uuid: rootObject,
    name: objectName,
    description: objectDescription,
    entityUuid: entityId,
    items: {},
    fields: {},
    backend: backend,
    frontend: frontend
  };
  const propertiesList: Property[] = properties[rootObject || '0'];
  const schemaItems: SchemaItems = {};
  const schemaProperties: SchemaProperties = { [rootObject || '']: [] };
  const hasExported = backend && frontend;
  for (const prop of propertiesList) {
    if (!prop.checked) continue;
    const item: SchemaItem = {
      type: prop.type || 'INTEGER',
      name: prop.name,
      description: prop.description,
      list: prop.list,
      readOnly: prop.readOnly,
      exported: hasExported ? prop.exported : false,
      exportedName: hasExported ? prop.exportedName : '',
      objectUuid: prop.object,
      columnUuid: prop.columnId,
      relationshipUuid: prop.relationshipId,
      enumUuid: prop.enum,
      itemOrder: prop.itemOrder
    };
    schemaItems[prop.id] = item;
    schemaProperties[rootObject || ''].push(prop.id);
  }

  fullObject.items = schemaItems;
  fullObject.fields = schemaProperties;
  return fullObject;
}

// For entity mode. Called when saving the objects.
export function buildObjectsForEntityMode(
  objectName: string,
  objectDescription: string,
  backend: boolean,
  frontend: boolean,
  rootObject: string,
  isObjectComplete: boolean,
  properties: Record<string, Property[]>
): ObjectType[] | null {
  const fullObjects: ObjectType[] = [];
  const tablesChecked: string[] = [];
  const tablesToCheck: {
    objectName: string;
    objectDescription: string;
    id: string;
    entityId: string;
  }[] = [
    {
      objectName: objectName,
      objectDescription: objectDescription,
      id: rootObject,
      // rootObject has the same id as the entity it represents.
      entityId: rootObject
    }
  ];
  const hasExported = backend && frontend;
  while (tablesToCheck.length) {
    const table = tablesToCheck.pop();
    if (!table) continue;
    if (tablesChecked.includes(table.id)) continue;
    tablesChecked.push(table.id);

    const schemaItems: SchemaItems = {};
    const schemaProperties: SchemaProperties = { [table.id || '']: [] };

    if (!properties[table.id]) continue;

    for (const prop of properties[table.id]) {
      if (!prop.checked) continue;
      if (prop.showWhenObjectIsComplete && !isObjectComplete) continue;

      if (prop.object && prop.fromEntity && isObjectComplete) {
        tablesToCheck.push({
          objectName: prop.objectName || '',
          objectDescription: prop.description,
          id: prop.object,
          // prop.entityId is not empty when prop.fromEntity = true,
          // prop.objectId is also not empty.
          entityId: prop.entityId || ''
        });
      }

      const schemaItemType = prop.object ? 'OBJECT' : prop.type;
      const item: SchemaItem = {
        type: schemaItemType,
        name: prop.name,
        description: prop.description,
        list: prop.list,
        readOnly: prop.readOnly,
        exported: hasExported ? prop.exported : false,
        exportedName: hasExported ? prop.exportedName : '',
        objectUuid: prop.object,
        columnUuid: prop.columnId,
        relationshipUuid: prop.relationshipId,
        enumUuid: prop.enum,
        itemOrder: prop.itemOrder
      };
      schemaItems[prop.id] = item;
      schemaProperties[table.id || ''].push(prop.id);
    }

    if (Object.keys(schemaItems).length === 0) return null;

    const fullObj: ObjectType = {
      uuid: table.id,
      name: table.objectName,
      description: table.objectDescription,
      entityUuid: table.entityId,
      items: schemaItems,
      fields: schemaProperties,
      backend: backend,
      frontend: frontend
    };

    fullObjects.push(fullObj);
  }

  return fullObjects;
}

export type SelectedProperty = {
  id: string;
  objectId: string;
};

export type PreSelectedEntity = {
  id: string;
  name: string;
};

export type ObjectItemSource = {
  object: string;
  property: string;
};

export type Property = {
  id: string;
  name: string;
  // objectName is the name of the object created from a foreign key.
  objectName?: string;
  description: string;
  type: DataType;
  list: boolean;
  // We use the entityId as the object id and then change it in the backend.
  object?: string;
  enum?: string;
  enumName?: string;
  itemOrder: number;
  readOnly: boolean;
  exported: boolean;
  exportedName: string;
  checked?: boolean;
  entityName?: string;
  // Check if property was created automatically from an entity.
  fromEntity: boolean;
  isFK: boolean;
  isPK: boolean;
  columnId?: string;
  entityId?: string;
  relationshipId?: string;
  // Manually created properties from relationships have this property set as true.
  showWhenObjectIsComplete?: boolean;
  required: boolean;
  moduleName?: string;
};
