import React, { ReactNode, useEffect, useState } from 'react';
import SessionContext from './index';
import { UserProfile } from '../types/auth_types';
import { AccountService } from '../services';
import { Preferences } from '../enum';
import { JobInfo } from '../../project/types';

type SessionWrapperProps = {
  children: ReactNode;
};

const HOUR_IN_MILLISECONDS = 60 * 60 * 1000;

function SessionWrapper(props: SessionWrapperProps) {
  const [isLoggedIn, setLoggedIn] = useState<boolean>(false);
  const [user, setUser] = useState<UserProfile | null>(null);
  const [preferences, setPreferences] = useState<{ [key: string]: any }>({});
  const [language, setLanguage] = useState<string>('en');
  const [downloads, setDownloads] = useState<Record<string, JobInfo>>({});
  const darkThemePreferences = preferences[Preferences.DARK_THEME];

  // The server refreshes the token expiry date in every request.
  // If the user doesn't send any request to the server this useEffect will
  // keep the token expiry date still valid.
  useEffect(() => {
    if (!isLoggedIn) {
      return;
    }
    const refreshExpiryDateInterval = setInterval(() => {
      reloadUser();
    }, HOUR_IN_MILLISECONDS);
    return () => clearInterval(refreshExpiryDateInterval);
  }, [isLoggedIn]);

  const changePreferences = async function (key: string, value: any) {
    try {
      await AccountService.changeUserPreferences(key, value);
      preferences[key] = value;
      setPreferences({ ...preferences });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const currentURL = window.location.href;
    if (currentURL && !currentURL.includes('/preview')) {
      const bodyElem = document.getElementsByTagName('body');

      if (bodyElem[0]) {
        bodyElem[0].setAttribute('data-bs-theme', darkThemePreferences ? 'dark' : 'light');
      }
    }
  }, [darkThemePreferences]);

  const reloadUser = async () => {
    const userInfo = await AccountService.getAccountInfo();
    setUser(userInfo);
    return userInfo;
  };

  return (
    <SessionContext.Provider
      value={{
        isLoggedIn,
        setLoggedIn,
        user,
        setUser,
        preferences,
        setPreferences,
        changePreferences,
        language,
        setLanguage,
        downloads,
        setDownloads,
        reloadUser
      }}
    >
      {props.children}
    </SessionContext.Provider>
  );
}

export default SessionWrapper;
