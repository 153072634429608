import { ComponentUUID, ViewUUID } from 'modules/designer/types';
import React, { forwardRef, memo } from 'react';
import { removeDeclarationsWithUndefinedValue } from 'utils/cssUtils';
import SectionWrapper from '../section_wrapper';
import { COMPONENT_TYPES } from '..';
import ComponentsRenderer from '../components_renderer';

export type GridColumnData = {
  title: string;
  width: number;
};

export type GridData = {
  columns: GridColumnData[];
  name: string;
  height?: number | string;
  heightUnit?: number | string;
  maxHeight?: number | string;
  maxHeightUnit?: string;
  minHeight?: number | string;
  minHeightUnit?: string;
};

export type GridStyles = {
  borderRadius?: number;
  marginTop?: number;
  marginBottom?: number;
  marginLeft?: number;
  marginRight?: number;
  borderTop?: number;
  borderBottom?: number;
  borderLeft?: number;
  borderRight?: number;
  paddingTop?: number;
  paddingLeft?: number;
  paddingRight?: number;
  paddingBottom?: number;
  css?: string;
};

export type GridComponentProps = {
  uuid: ComponentUUID;
  data: GridData;
  styles: GridStyles;
  viewUUID: ViewUUID;
  parentUUID?: ComponentUUID;
  custom_uuid: ComponentUUID;
  new_custom_uuid: ComponentUUID;
};

function GridComponent(props: GridComponentProps, ref: React.Ref<any>) {
  const style: Record<string, string | number | undefined> = {
    ...(props.data.height &&
      !props.data.heightUnit && {
        height: props.data.height
      }),
    ...(props.data.height &&
      props.data.heightUnit && {
        height: props.data.height + '' + props.data.heightUnit
      }),
    ...(props.data.maxHeight &&
      props.data.maxHeightUnit && {
        maxHeight: props.data.maxHeight + '' + props.data.maxHeightUnit
      }),
    ...(props.data.maxHeight &&
      !props.data.maxHeightUnit && {
        maxHeight: props.data.maxHeight
      }),
    ...(props.data.minHeight &&
      props.data.minHeightUnit && {
        minHeight: props.data.minHeight + '' + props.data.minHeightUnit
      }),
    ...(props.data.minHeight &&
      !props.data.minHeightUnit && {
        minHeight: props.data.minHeight
      }),
    borderRadius: `${props.styles.borderRadius}px`,
    borderTop: `${props.styles.borderTop}px solid #333`,
    borderRight: `${props.styles.borderRight}px solid #333`,
    borderBottom: `${props.styles.borderBottom}px solid #333`,
    borderLeft: `${props.styles.borderLeft}px solid #333`,
    paddingTop: `${props.styles.paddingTop}px`,
    paddingRight: `${props.styles.paddingRight}px`,
    paddingBottom: `${props.styles.paddingBottom}px`,
    paddingLeft: `${props.styles.paddingLeft}px`,
    marginTop: `${props.styles.marginTop}px`,
    marginRight: `${props.styles.marginRight}px`,
    marginBottom: `${props.styles.marginBottom}px`,
    marginLeft: `${props.styles.marginLeft}px`
  };

  removeDeclarationsWithUndefinedValue(style);

  return (
    <div ref={ref} className="row" style={{ ...style }}>
      {props.data.columns.map((column: GridColumnData) => (
        <SectionWrapper
          key={column.title}
          section={column.title}
          uuid={props.uuid}
          type={COMPONENT_TYPES.COLUMN}
          viewUUID={props.viewUUID}
          parentUUID={props.parentUUID}
          custom_uuid={props.custom_uuid}
          data={column}
        >
          <div style={{ width: '100%', height: '100%' }}>
            <ComponentsRenderer
              section={column.title}
              viewUUID={props.viewUUID}
              parentUUID={props.uuid}
              custom_uuid={props.custom_uuid}
              new_custom_uuid={props.new_custom_uuid}
            />
          </div>
        </SectionWrapper>
      ))}
    </div>
  );
}

export default memo(forwardRef(GridComponent));
export * from './manifest';
export * from './template';
