import React, { useCallback, useEffect, useState } from 'react';
import { Button, Card, Form, Modal, Spinner } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { Layout, Template } from 'modules/designer/types';
import { TemplatesService } from 'modules/designer/services';
import styles from './styles.module.css';
import { CrudData } from '..';
import { CrudAutomationService } from '../../../../../../modules/logic_builder/services';
import { CrudInputViews } from '../../../../../../modules/logic_builder/types';
import { useTranslation } from 'react-i18next';

type AddPagesDialogProps = {
  dialogOnClose: () => void;
  showDialog: boolean;
  onCreate: () => void;
  layoutsList: Layout[];
  parentUuid: string;
  crudData: CrudData;
  onChange: (data: CrudData) => void;
  loading?: (val: boolean) => void;
};

const API_URL = process.env.REACT_APP_API_URL;

function AddPagesDialog(props: AddPagesDialogProps) {
  const [isLoading, setIsLoading] = useState(false);
  const { module_id, app_id } = useParams();
  const [selectedControllerId, setSelectedControllerId] = useState('');
  const [selectedObject, setSelectedObject] = useState('');
  const [templates, setTemplates] = useState<Template[]>();
  const [selectedTemplates, setSelectedTemplates] = useState<string[]>([]);
  const { t } = useTranslation();

  async function onSubmit() {
    if (!module_id || !props.crudData.entityUuid || !props.crudData.selectedFolder) return;
    props.loading && props.loading(true);

    const crudInputViews: CrudInputViews = {
      controllerUuid: selectedControllerId,
      objectUuid: selectedObject,
      folderUuid: props.crudData.selectedFolder.uuid,
      templates: selectedTemplates
    };

    const crudPages = await CrudAutomationService.getViewsPrototypes(
      module_id,
      props.crudData.entityUuid,
      crudInputViews
    );

    const newCrud = {
      ...props.crudData,
      crudPages: crudPages
    };

    props.onChange(newCrud);
    props.dialogOnClose();
    props.loading && props.loading(false);
  }

  const fetchTemplates = useCallback(async (objectId, controllerId) => {
    if (!module_id || !props.crudData.entityUuid) return;
    setIsLoading(true);

    props.loading && props.loading(true);

    const response = await TemplatesService.getCrudTemplates(
      module_id,
      props.crudData.entityUuid,
      objectId,
      controllerId
    );

    const newCrud = {
      ...props.crudData,
      masterEndpoints: response.masterEndpoints,
      detailEndpoints: response.detailEndpoints
    };
    props.onChange(newCrud);

    setTemplates(response.templates);
    setSelectedTemplates([]);
    setIsLoading(false);
    props.loading && props.loading(false);
  }, []);

  const handlePageOnClick = (templateId: string) => {
    if (selectedTemplates.includes(templateId)) {
      setSelectedTemplates(selectedTemplates.filter((id) => id !== templateId));
    } else {
      setSelectedTemplates([...selectedTemplates, templateId]);
    }
  };

  useEffect(() => {
    if (!selectedObject || !selectedControllerId) return;
    fetchTemplates(selectedObject, selectedControllerId);
  }, [selectedObject, selectedControllerId]);

  useEffect(() => {
    if (!app_id || !props.showDialog) return;
    if (props.crudData.controllers[0] && props.crudData.controllers[0].uuid)
      setSelectedControllerId(props.crudData.controllers[0].uuid);
    if (props.crudData.objects[0] && props.crudData.objects[0].uuid)
      setSelectedObject(props.crudData.objects[0].uuid);

    fetchTemplates(
      props.crudData.objects[0].uuid,
      props.crudData.controllers[0] && props.crudData.controllers[0].uuid
    );
  }, [app_id, fetchTemplates, props.showDialog]);

  function isTemplateAvailable(template: Template) {
    if (template.type === 'MASTER' || template.name === 'Master')
      return props.crudData.masterEndpoints.length > 0;
    if (template.type === 'DETAIL' || template.name === 'Detail')
      return props.crudData.detailEndpoints.length > 0;
    return false;
  }

  const thumbnailSource = (template: Template) => {
    if (template.thumbnail) {
      return `${API_URL}${template.thumbnail}`;
    } else if (template.isNative) {
      return `/assets/templates/${template.name.toLowerCase().replaceAll(' ', '_')}.png`;
    } else {
      return `/assets/default.png`;
    }
  };

  const onClose = () => {
    props.dialogOnClose();
  };

  return (
    <Modal centered show={props.showDialog} onHide={onClose} size="lg">
      <Modal.Header closeButton id="formModal">
        <Modal.Title>{t('automation.step5.addPage')}</Modal.Title>
      </Modal.Header>
      <Modal.Body id="bodyModal">
        {isLoading ? (
          <div className="d-flex justify-content-center">
            <Spinner animation="border" variant="secondary" />
          </div>
        ) : (
          <>
            <div className={`${styles.SelectsWrapper} mb-4`}>
              <div className={styles.SelectWrapper}>
                <div style={{ width: 'fit-content' }} className="me-2">
                  {t('automation.step4.controller')}:
                </div>
                <Form.Select
                  id="selectController"
                  onChange={(e) => setSelectedControllerId(e.target.value)}
                >
                  {Object.values(props.crudData.controllers).length &&
                    Object.values(props.crudData.controllers).map((controller) => {
                      return (
                        <option key={controller.name} value={controller.uuid}>
                          {controller.name}
                        </option>
                      );
                    })}
                  ;
                </Form.Select>
              </div>
              <div className={styles.SelectWrapper}>
                <div style={{ width: 'fit-content', whiteSpace: 'nowrap' }} className="me-2">
                  {t('automation.step4.DataObject')}
                </div>
                <Form.Select
                  id="selectDataObject"
                  onChange={(e) => setSelectedObject(e.target.value)}
                >
                  {props.crudData.objects &&
                    Object.values(props.crudData.objects).map((object, index) => {
                      return (
                        <option key={index} value={object.uuid}>
                          {object.name}
                        </option>
                      );
                    })}
                </Form.Select>
              </div>
            </div>
            <div className={styles.pageOptions}>
              {templates &&
                templates.map((template) => (
                  <Card
                    key={template.uuid}
                    className={`${styles.Card} ${
                      selectedTemplates.includes(template.uuid) ? styles.CardSelected : ''
                    } ${isTemplateAvailable(template) ? '' : styles.CardDisabled}`}
                    onClick={() => handlePageOnClick(template.uuid)}
                  >
                    <Card.Body id={`${template.name.toLowerCase()}Template`} style={{ padding: 0 }}>
                      <Card.Title className={`${styles.CardTitle}`}>{template.name}</Card.Title>
                      <Card.Img src={thumbnailSource(template)} style={{ borderRadius: 0 }} />
                    </Card.Body>
                    <Card.Footer className={styles.CardFooter}>
                      {template.name === 'Master' && (
                        <>
                          <div className={styles.footerTag}>{'Read Many'}</div>
                          <div className={styles.footerTag}>{'Delete One'}</div>
                        </>
                      )}
                      {template.name === 'Detail' && (
                        <>
                          <div className={styles.footerTag}>{'Create One'}</div>
                          <div className={styles.footerTag}>{'Update One'}</div>
                          <div className={styles.footerTag}>{'Read One'}</div>
                        </>
                      )}
                    </Card.Footer>
                  </Card>
                ))}
            </div>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button id="cancelButton" onClick={props.dialogOnClose}>
          {t('automation.step4.Cancel')}
        </Button>
        <Button id="saveButton" onClick={() => onSubmit()}>
          {t('automation.step4.Save')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default AddPagesDialog;
