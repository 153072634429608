import { InputFileData, InputFileStyles } from '.';
import { LayoutComponent } from 'modules/designer/types';

export const INPUT_FILE_TEMPLATE: LayoutComponent<InputFileData, InputFileStyles> = {
  uuid: 'empty',
  type: 'INPUTFILE',
  data: {
    label: 'Drag and drop the file here, or click to select',
    buttonText: 'Upload file',
    icon: 'FaFileUpload',
    width: 300,
    widthUnit: 'px',
    // maxWidth: 'none',
    // minWidth: 'none',
    height: 'fit-content',
    heightUnit: ''
    // maxHeight: 'none',
    // minHeight: 'none'
  },
  styles: {
    marginTop: 10,
    marginRight: 10,
    marginBottom: 10,
    marginLeft: 10,
    borderTop: 3,
    borderRight: 3,
    borderBottom: 3,
    borderLeft: 3,
    paddingTop: 24,
    paddingRight: 24,
    paddingBottom: 24,
    paddingLeft: 24,
    // textAlign: 'left',
    // css: '',
    color: 'primary'
  }
};
