import styles from './styles.module.css';
import React, { useEffect, useState } from 'react';
import { Column, ColumnIndexType, IndexID, sortOrder } from '../../../../../../../types';
import Icon from '../../../../../../../../../web_ui/icon';
import { DATA_TYPES, DataType } from '../../../../../../../../logic_builder/types';
import { DatabaseStudioState, indexColumnState } from '../../../../../../store';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'react-bootstrap';
import { v1 as uuidv1 } from 'uuid';
import { updateIndexColumns } from '../../../../../../store/actions/indexes';

type IndexColumnsListProps = {
  tableColumns: Column[];
  indexColumns: indexColumnState;
  indexID: IndexID;
};

export default function IndexColumnsList({
  tableColumns,
  indexColumns,
  indexID
}: IndexColumnsListProps) {
  const [localColumns, setLocalColumns] = useState<ColumnIndexType[]>([]);
  const columns = useSelector((state: DatabaseStudioState) => state.columns);
  const dispatch = useDispatch();

  const orderColumnsToRender = () => {
    const cols: ColumnIndexType[] = [];
    tableColumns.forEach((col) => {
      const isIndexColumn =
        indexColumns &&
        Object.values(indexColumns).find((indexCol) => indexCol.columnId === col.uuid);
      if (isIndexColumn) {
        cols.push({ ...isIndexColumn, selected: true });
      } else {
        const newIndexCol = {
          id: uuidv1(),
          columnId: col.uuid,
          sortOrder: 'ASC' as sortOrder,
          columnOrder: col.columnOrder + 100,
          selected: false
        };
        cols.push(newIndexCol);
      }
    });

    return cols.sort((a, b) => a.columnOrder - b.columnOrder);
  };
  useEffect(() => setLocalColumns(orderColumnsToRender()), [indexColumns, tableColumns]);

  const renderColumnsList = () => {
    return localColumns && localColumns.map((col, i) => renderColumn(col, i));
  };

  const renderColumn = (column: ColumnIndexType, index: number) => {
    return (
      <div className={styles.ColumnWrapper} key={index} onDrop={(e) => handleDrop(e, index)}>
        <div
          id={columns[column.columnId]?.name}
          className={`${styles.KeyValue} ${styles.SelectedColumn} ${styles.DetailsWrapper}`}
        >
          <div className={`${styles.ButtonWrapper}`} style={{ marginRight: '8px' }}>
            <Form.Check
              id={`${columns[column.columnId]?.name}Checkbox`}
              checked={column.selected}
              onChange={(e) => handleCheck(e, index)}
            />
          </div>
          <div className={styles.Key}>{columns[column.columnId]?.name}</div>
          <div className={`text-muted ${styles.Value}`}>
            {DATA_TYPES[columns[column.columnId]?.type as DataType]}
          </div>
          <span style={{ fontSize: '13px' }}>{icon(columns[column.columnId])}</span>
        </div>
        <div className={`${styles.ButtonWrapper} ${styles.ReorderButton}`}>
          <i
            className={`fa fa-grip-vertical text-secondary ${styles.Button}`}
            draggable={true}
            onDragStart={(e) => handleDragStart(e, index)}
          />
        </div>
      </div>
    );
  };

  const icon = (column: Column) => {
    if (column?.isFK) {
      return <Icon iconName="link" />;
    }
    if (column?.isPK) {
      return <Icon iconName="key" extraProps={styles.KeyIcon} />;
    }
  };

  const handleCheck = function (e: React.ChangeEvent<HTMLInputElement>, index: number) {
    e.preventDefault();
    const cols = localColumns;

    e.target.checked ? addColumnToIndex(cols[index]) : removeColumnFromIndex(cols[index]);
    updateIndexCols(cols);
  };

  const addColumnToIndex = function (col: ColumnIndexType) {
    col.selected = true;
    col.columnOrder = getLastIndexColumnOrder();
  };

  const getLastIndexColumnOrder = function () {
    for (let i = 0; i <= localColumns.length; i++) {
      if (!localColumns[i]?.selected) return i;
    }
    return 0;
  };

  const removeColumnFromIndex = function (col: ColumnIndexType) {
    col.selected = false;
    col.columnOrder = columns[col.columnId].columnOrder + 100;
  };

  const updateIndexCols = function (cols: ColumnIndexType[]) {
    const indexColumns = {} as indexColumnState;
    cols
      .filter((col) => col.selected)
      .forEach((col, i) => {
        col.columnOrder = i;
        indexColumns[col.id] = col;
      });
    dispatch(updateIndexColumns(indexID, indexColumns));
  };

  const handleDragStart = function (e: React.DragEvent<HTMLElement>, columnIndex: number) {
    e.dataTransfer.setData('exocode/dragged-column-index', `${columnIndex}`);
    e.dataTransfer.dropEffect = 'copy';
  };

  const handleDrop = async function (e: React.DragEvent<HTMLElement>, columnIndex: number) {
    const draggedColumnIndex = Number.parseInt(
      e.dataTransfer.getData('exocode/dragged-column-index')
    );
    if (!localColumns[draggedColumnIndex].selected) return;

    const cols = localColumns;
    const draggedCol = cols[draggedColumnIndex];
    cols.splice(draggedColumnIndex, 1);
    cols.splice(columnIndex, 0, draggedCol);

    updateIndexCols(cols);
  };

  return <div className={`card p-2 ${styles.ListWrapper}`}>{renderColumnsList()}</div>;
}
