import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import SessionContext from '../../modules/auth/store';
import { AccountService } from 'modules/auth/services';
import styles from './styles.module.css';
import { Container, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import useTitle from 'hooks/useTitle';

function RedirectLoginPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const session = useContext(SessionContext);
  const [searchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  useTitle(t('tab.redirecting'));

  useEffect(() => {
    setErrorMessage(searchParams.get('error'));
  }, [searchParams]);

  useEffect(() => {
    const fetchAccount = async () => {
      try {
        const user = await AccountService.getAccountInfo();
        session.setUser(user);
        session.setLoggedIn(true);
        setIsLoading(false);
      } catch (_e: any) {
        session.setLoggedIn(false);
        setIsLoading(false);
      }
    };

    if (!session.isLoggedIn) {
      fetchAccount();
    } else {
      setIsLoading(false);
    }
  }, [session]);

  return (
    <div className={styles.PageWrap}>
      {errorMessage}
      {isLoading && (
        <Container className={`${styles.LoginContainer}`}>
          <Spinner animation="border" variant="light" />
        </Container>
      )}

      {!isLoading &&
        (errorMessage == null
          ? navigate('/homepage')
          : navigate('/login', {
              state: {
                error: { title: 'Error in sign up with social login', message: errorMessage }
              }
            }))}
    </div>
  );
}

export default RedirectLoginPage;
