import React, { useCallback, useEffect, useRef, useState } from 'react';
import styles from './styles.module.css';
import { Form } from 'react-bootstrap';
import { Step0 } from './components/wizard_steps/step0';
import Crud from './components/wizard_steps/crud';
import { AUTOMATION_TYPES, AUTOMATIONS_STEPS, Entity, SelectedAutomation } from './types';
import ModelGeneratorAutomation from './components/wizard_steps/model_generator';
import { PopupAlert } from 'web_ui/popup_alert';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import { fetchEntities } from './helpers/fetchEntities';
import { ModuleInfo } from 'modules/dashboard/types';
import { useTranslation } from 'react-i18next';
import { ProjectsService } from 'modules/project/services';
import { ApiAppInfo } from 'modules/project/types';
import useTitle from 'hooks/useTitle';

export type AutomationHandler = {
  handleFinish: () => void;
};

export function AutomationWizard() {
  const location = useLocation();
  const { module_id, app_id } = useParams();
  const [searchParams] = useSearchParams();
  const formRef = useRef<HTMLFormElement>(null);
  const [currentStep, setCurrentStep] = useState(0);
  const [formValidity, setFormValidity] = useState(true);
  const [selectedAutomation, setSelectedAutomation] = useState<SelectedAutomation>(
    AUTOMATIONS_STEPS['CRUD']
  );
  const [errorMessage, setErrorMessage] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);
  const [currEntities, setCurrEntities] = useState<{ [p: string]: Entity }>();
  const [mod, setMod] = useState<ModuleInfo>();
  const [app, setApp] = useState<ApiAppInfo>();
  const { t } = useTranslation();

  const setCorrectTabName = React.useCallback(async () => {
    if (!app_id || !module_id) return;
    const projectData = await ProjectsService.getProjectById(app_id);
    const moduleData = await ProjectsService.getModuleById(module_id);
    setApp(projectData);
    setMod(moduleData);
  }, [app_id, module_id]);

  useEffect(() => {
    setCorrectTabName();
  }, []);

  useTitle(
    app?.name && mod?.name ? `Automation Wizard · ${mod.name} - ${app.name}` : 'Exocoding',
    mod
  );

  const locationState = location.state;
  useEffect(() => {
    if (locationState) {
      setCurrentStep(locationState.step);
    }
  }, [locationState]);

  const getSelectedEntity = useCallback((): string => {
    return searchParams.get('entity') ?? '';
  }, [searchParams]);

  useEffect(() => {
    if (getSelectedEntity() !== '') {
      setCurrentStep(2);
    }
  }, [getSelectedEntity]);

  useEffect(() => {
    const gettingVal = searchParams.get('entityGenerator') ?? '';
    if (gettingVal && gettingVal != '') {
      setSelectedAutomation(AUTOMATIONS_STEPS.ENTITIES);
      setCurrentStep(1);
      setFormValidity(false);
    }
  }, []);

  useEffect(() => {
    if (!module_id) return;
    (async () => {
      fetchEntities(module_id, true).then((entities) => {
        setCurrEntities(entities);
      });
    })();
  }, [module_id]);

  return (
    <>
      <div className={styles.wizardContainer}>
        <Form ref={formRef} id="wizard-form" style={{ flexGrow: 2 }}>
          {currentStep === 0 && (
            <Step0
              selectedAutomation={selectedAutomation}
              setSelectedAutomation={(s: SelectedAutomation) => {
                setSelectedAutomation(s);
                setCurrentStep(1);
                setFormValidity(false);
              }}
            />
          )}
          {selectedAutomation.name === AUTOMATION_TYPES.CRUD && currentStep > 0 && (
            <Crud
              currentStep={currentStep}
              setCurrentStep={setCurrentStep}
              formValidity={formValidity}
              setFormValidity={setFormValidity}
              selectedEntityId={getSelectedEntity()}
              setIsLoading={(isLoading: boolean) => setIsLoading(isLoading)}
              isLoading={isLoading}
              entities={currEntities}
            />
          )}
          {selectedAutomation.name === AUTOMATION_TYPES.ENTITIES && currentStep > 0 && (
            <ModelGeneratorAutomation
              currentStep={currentStep}
              setCurrentStep={setCurrentStep}
              formValidity={formValidity}
              setFormValidity={setFormValidity}
              setErrorMessage={(message: string) => setErrorMessage(message)}
              setIsLoading={(isLoading: boolean) => setIsLoading(isLoading)}
              isLoading={isLoading}
              selectedAutomation={selectedAutomation}
            />
          )}
        </Form>
        {errorMessage && <PopupAlert i18nKey={errorMessage} onClose={() => setErrorMessage('')} />}
      </div>
    </>
  );
}
