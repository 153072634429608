import { RunFunctionDataInterface } from './function_editor/function_actions/types/action_data';
import { FrontendVariable, PageParam, ViewUUID } from '../designer/types';
import { RestDataInterface } from '../designer/function_editor/function_actions/types/action_data';

export const METHODS = {
  GET: 'GET',
  POST: 'POST',
  PATCH: 'PATCH',
  PUT: 'PUT',
  DELETE: 'DELETE'
} as const;

export type Method = keyof typeof METHODS;

export const ACCESS_LEVELS = {
  PUBLIC: 'PUBLIC',
  PROTECTED: 'PROTECTED'
} as const;

export type AccessLevel = keyof typeof ACCESS_LEVELS;

// ----------------------------- Controllers ---------------------------------
export type Controller = {
  uuid?: string;
  name: string;
  description: string;
  path: string;
  native: boolean;
  entityUuid?: string;
  new?: boolean;
  folder_id?: string;
};

// ----------------------------- Service ---------------------------------
export type Service = {
  uuid?: string;
  name: string;
  native: boolean;
  description: string;
  entityUuid?: string;
  new?: boolean;
  selected?: boolean;
  folder_id?: string;
};

// ----------------------------- Endpoints ---------------------------------
export type EndpointSimple = {
  controllerUuid: string;
  name: string;
  path: string;
  method: Method;
  summary: string;
};

export type Endpoint = EndpointSimple & {
  uuid: string;
  description: string;
  deprecated: boolean;
  accessLevel: AccessLevel;
  pageable: boolean;
  sortable: boolean;
  filterable: boolean;
  allowAllRoles: boolean;
  native: boolean;
  roles: number[];
  crudType: string;
  crudObjectId: string;
  crudServiceId: string;
  parameters: ParameterEndpointCrud[];
  action: Action<RunFunctionDataInterface>;
  isNew: boolean;
  tags: number[];
};

export type EndpointExtended = Endpoint & {
  action: Action<any>;
  parameters: ParameterEndpoint[];
};

export const INPUT_TYPES = {
  NONE: 'NONE',
  PATH: 'PATH',
  QUERY: 'QUERY',
  HEADER: 'HEADER',
  COOKIE: 'COOKIE',
  BODY: 'BODY'
} as const;

export type InputType = keyof typeof INPUT_TYPES;

export type ParameterEndpoint = {
  uuid?: string;
  name: string;
  description: string;
  required: boolean;
  type: DataType;
  list: boolean;
  objectUuid?: string;
  native?: boolean;
  inputType: InputType;
};

export type ParameterEndpointCrud = ParameterEndpoint & FunctionParameterCrud;

// ----------------------------- Tags ---------------------------------

export type Tag = {
  id?: number;
  appId?: string;
  name: string;
  description: string;
};

// -------------------------- Data Types ------------------------------

export const DATA_TYPES = {
  BOOLEAN: 'Boolean',
  SMALLINT: 'SmallInt',
  INTEGER: 'Integer',
  BIGINT: 'BigInt',
  DECIMAL: 'Decimal',
  FLOAT: 'Float',
  DOUBLE: 'Double',
  CHAR: 'Char',
  VARCHAR: 'Varchar',
  STRING: 'String',
  DATE: 'Date',
  TIME: 'Time',
  TIMESTAMP: 'Timestamp',
  BLOB: 'Blob',
  CLOB: 'Clob',
  AUTOINCREMENT: 'AutoIncrement',
  UUID: 'UUID',
  ENUM: 'Enum',
  PAGE: 'Page',
  OBJECT: 'Object'
} as const;

// Subset of DATA_TYPES.
export const NUMERIC_DATA_TYPES = {
  TINYINT: 'TinyInt',
  SMALLINT: 'SmallInt',
  INTEGER: 'Integer',
  BIGINT: 'BigInt',
  NUMERIC: 'Numeric'
} as const;

export type DataType = keyof typeof DATA_TYPES;

export const SORT_TYPES = {
  NONE: 'None',
  ASCENDING: 'Ascending',
  DESCENDING: 'Descending'
} as const;

export type SortType = keyof typeof SORT_TYPES;

// ----------------------------- Functions ---------------------------------
export type FunctionSimple = {
  uuid?: string;
  serviceUuid: string;
  name: string;
  description: string;
};

export type FunctionType = FunctionSimple & {
  pageable: boolean;
  sortable: boolean;
  native: boolean;
  filterable: boolean;
  returnVariableUuid: string;
  functionDefinition: string;
  serviceName?: string;
  moduleName?: string;
};

export type FunctionExtended = FunctionType & {
  actions: Action<any>[];
  parameters: FunctionParameter[];
  variables: FunctionVariable[];
  crudType?: CrudType;
  crudObjectId?: string;
  crudEntities?: boolean;
};

export type FunctionParameter = {
  uuid?: string;
  name: string;
  description: string;
  required: boolean;
  type: DataType;
  list: boolean;
  objectUuid?: string;
  enumUuid?: string;
  native?: boolean;
  order: number;
};

export type FunctionParameterCrud = {
  uuid?: string;
  name: string;
  description: string;
  required: boolean;
  type: string;
  list: boolean;
  objectUuid?: string;
  enumUuid?: string;
  native?: boolean;
  order?: number;
};

export type FunctionVariable = {
  uuid?: string;
  name: string;
  description: string;
  type: DataType;
  list: boolean;
  objectUuid?: string;
  enumUuid?: string;
  native?: boolean;
};

export type FunctionReturn = {
  returnVariableUuid: string;
  type: DataType;
  object: ObjectType;
};

export type SchedulerJob = {
  uuid?: string;
  moduleUuid: string;
  functionUuid: string;
  cronExpression: string;
  name: string;
  description: string;
  folder_id?: string;
};

export enum CrudType {
  CREATE_ONE,
  READ_ONE,
  UPDATE_ONE,
  DELETE_ONE,
  CREATE_MANY,
  READ_MANY,
  UPDATE_MANY,
  DELETE_MANY,
  DELETE_MANY_ID
}

export enum CrudBodyType {
  OBJECT = 'OBJECT',
  PARENT_CHILD = 'PARENT_CHILD',
  BUSINESS_RULES = 'BUSINESS_RULES'
}

export type CrudBodyTypeKeys = keyof typeof CrudBodyType;

// ----------------------------- CRUD ---------------------------------
export type CrudInputFunctionDetail = {
  single: boolean;
  multi: boolean;
  multiId?: boolean;
};

export type CrudInputFunction = {
  crudBodyType?: CrudBodyType;
  objectUuid?: string;
  create: CrudInputFunctionDetail;
  read: CrudInputFunctionDetail;
  update: CrudInputFunctionDetail;
  delete: CrudInputFunctionDetail;
};

export type CrudCreateFunction = {
  service: Service;
  functions: FunctionSimple[];
};

export type CrudInputEndpointDetail = {
  single: boolean;
  multi: boolean;
  multiId?: boolean;
  secure: boolean;
};

export type CrudInputEndpoint = {
  serviceUuid?: string;
  objectUuid?: string;
  post: CrudInputEndpointDetail;
  get: CrudInputEndpointDetail;
  put: CrudInputEndpointDetail;
  delete: CrudInputEndpointDetail;
};

export type CrudCreateEndpoint = {
  controller: Controller;
  endpoints: Endpoint[];
};

export type CrudInputViews = {
  controllerUuid: string;
  objectUuid: string;
  folderUuid: string;
  templates: string[];
};

export type CrudPage = {
  uuid: ViewUUID;
  folderUuid: ViewUUID;
  name: string;
  route: string;
  params: PageParam[];
  roles: number[];
  variables: FrontendVariable[];
  templateUuid: string;
  templateName: string;
  templateIsNative: boolean;
  templateThumbnail: string;
  allowReadMulti: boolean;
  allowReadOne: boolean;
  allowDeleteOne: boolean;
  allowUpdateOne: boolean;
  allowCreateOne: boolean;
  extractForm: boolean;
  includeInMenu: boolean;
  submitAction?: RestDataInterface;
  fetchAction?: RestDataInterface;
  deleteAction?: RestDataInterface;
};

// ----------------------------- Actions ---------------------------------
export type Action<T> = {
  uuid?: string;
  order: number;
  type: ActionType;
  data: T;
  returnVariableUuid: string;
};

export const ACTION_TYPES = {
  CREATE_ENTITY: 'Create',
  READ_ENTITY: 'Read',
  UPDATE_ENTITY: 'Update',
  DELETE_ENTITY: 'Delete',
  RUN_FUNCTION: 'Run function',
  SET_VARIABLE: 'Set variable',
  DECLARE_VARIABLE: 'Declare variable',
  BEGIN_CYCLE: 'Begin cycle',
  END_CYCLE: 'End cycle',
  ADD_LIST: 'Add list',
  REMOVE_LIST: 'Remove list',
  CLEAR_LIST: 'Clear list',
  SAVE_FILE: 'Save file',
  DELETE_FILE: 'Delete file',
  READ_FILE: 'Read file',
  CUSTOM_CODE: 'Custom code',
  CUSTOM_QUERY: 'DB Query'
} as const;

export type ActionType = keyof typeof ACTION_TYPES;

// ----------------------------- Responses and Requests ---------------------------------
export type EndpointResponseSimple = {
  uuid?: string;
  description: string;
  httpStatus: string;
  list: boolean;
  objectUuid?: string;
};

export type EndpointResponse = EndpointResponseSimple & {
  object?: SchemaObject;
};

export type EndpointRequest = {
  uuid?: string;
  name: string;
  description: string;
  required: boolean;
  list: boolean;
  // If type is OBJECT then objectUuid is set.
  type: DataType;
  objectUuid?: string;
  native: boolean;
  inputType: InputType;
  object: SchemaObject;
};

// ----------------------------- Objects ---------------------------------
export type SchemaItem = {
  dataType?: DataType;
  type: DataType;
  name: string;
  description?: string;
  list: boolean;
  readOnly?: boolean;
  exported: boolean;
  exportedName?: string;
  objectUuid?: string;
  columnUuid?: string;
  relationshipUuid?: string;
  enumUuid?: string;
  itemOrder: number;
  uuid?: string;
};

export type SchemaProperties = Record<string, string[]>;

export type SchemaItems = Record<string, SchemaItem>;

export type SchemaObject = {
  uuid?: string;
  name: string;
  description: string;
  moduleUuid?: string;
  moduleName?: string;
  entityUuid?: string;
  fields: SchemaProperties;
  items: SchemaItems;
  objectItems?: SchemaItem[];
};

export type ObjectSimple = {
  uuid: string;
  moduleUuid: string;
  entityUuid: string;
  entityName?: string;
  name: string;
  description: string;
  native: boolean;
  backend: boolean;
  frontend: boolean;
  objectItems?: SchemaItem[];
  folder_id?: string;
};

export type ObjectType = {
  uuid?: string;
  moduleUuid?: string;
  entityUuid?: string;
  name: string;
  description: string;
  native?: boolean;
  backend: boolean;
  frontend: boolean;
  fields: SchemaProperties;
  items: SchemaItems;
};

export type ObjectTypeCrud = ObjectSimple & {
  uuid?: string;
  moduleUuid?: string;
  entityUuid?: string;
  name: string;
  description: string;
  native?: boolean;
  backend: boolean;
  frontend: boolean;
  fields: SchemaProperties;
  items: SchemaItems;
  isNew?: boolean;
};

// ----------------------------- Roles ---------------------------------
export type ExoRole = {
  id?: number;
  name: string;
  hasDependency?: boolean;
};

// ----------------------------- Errors --------------------------------
export enum Errors {
  CONFLICT = 'conflict'
}

// ----------------------------- Logic Builder Concepts --------------------------------
export enum LogicBuilderConcept {
  OBJECT,
  FUNCTION,
  SCHEDULER,
  ENDPOINT
}

export function getLogicBuilderConceptInfo(concept: LogicBuilderConcept) {
  let obj = LogicBuilderConceptInfo.OBJECT;
  switch (concept) {
    case LogicBuilderConcept.FUNCTION:
      obj = LogicBuilderConceptInfo.FUNCTION;
      break;
    case LogicBuilderConcept.SCHEDULER:
      obj = LogicBuilderConceptInfo.SCHEDULER;
      break;
    case LogicBuilderConcept.ENDPOINT:
      obj = LogicBuilderConceptInfo.ENDPOINT;
      break;
  }
  return obj;
}

const LogicBuilderConceptInfo = {
  OBJECT: {
    icon: 'database',
    title: 'automation.step4.Objects',
    parentTitle: 'automation.step4.Objects'
  },
  FUNCTION: {
    icon: 'code', // 'florin-sign',
    title: 'automation.step4.Functions',
    parentTitle: 'logicBuilder.service.Services'
  },
  SCHEDULER: {
    icon: 'clock',
    title: 'automation.step4.Scheduler',
    parentTitle: 'automation.step4.Scheduler'
  },
  ENDPOINT: {
    icon: 'cloud',
    title: 'automation.step4.Endpoints',
    parentTitle: 'appResume.Controllers'
  }
};

export const SHOW_ALL_URL = 'all';
