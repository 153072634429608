import React, { useState } from 'react';
import styles from './styles.module.css';
import { Button, Container, Form, Spinner } from 'react-bootstrap';
import { AuthService } from 'modules/auth/services';
import InputGroup from 'react-bootstrap/InputGroup';
import { PopupAlert, PopupAlertVariant } from '../../../../web_ui/popup_alert';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import PasswordFields, { validatePassword } from 'web_ui/password_fields';

type ChooseNewPasswordProps = {
  token: string;
};

/**
 * Change password
 *
 * @component
 */
function ChooseNewPassword(props: ChooseNewPasswordProps) {
  const [password, setPassword] = React.useState('');
  const [passwordConfirmation, setPasswordConfirmation] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const [passwordInputFieldType, setPasswordInputFieldType] = React.useState('password');
  const [passwordConfirmationType, setPasswordConfirmationType] = React.useState('password');
  const [alertMessage, setAlertMessage] = useState('');
  const [alertVariant, setAlertVariant] = useState<PopupAlertVariant>('success');
  const [passwordValidity, setPasswordValidity] = React.useState<boolean | undefined>(undefined);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const showErrorPopup = (message: string) => {
    setAlertVariant('danger');
    setAlertMessage(message);
  };

  const hideAlertPopup = () => {
    setAlertMessage('');
  };

  const pwd: string = t('Password');
  const pwdConfirm: string = t('PasswordConfirmation');

  const redirectToLogin = () => {
    navigate('/login', {
      state: {
        password: true
      }
    });
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    if (password !== passwordConfirmation) {
      return showErrorPopup('PasswordsDontMatch');
    }
    if (!passwordValidity) {
      return showErrorPopup('PasswordInvalid');
    }
    setIsLoading(true);
    try {
      await AuthService.updatePasswordWithToken(props.token, password);
      setIsLoading(false);
      redirectToLogin();
    } catch (error) {
      showErrorPopup('UpdatePasswordError');
      setIsLoading(false);
    }
  };

  function handlePassword(event: React.ChangeEvent<HTMLInputElement>) {
    setPassword(event.target.value);
    setPasswordValidity(validatePassword(event.target.value));
  }

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Container className={`text-white ${styles.ChangePasswordText}`}>
          {t('password_reset.choose_new_password.ChangePassword')}
        </Container>
        <div className={styles.passFieldsContainer}>
          <PasswordFields
            newPassword={password}
            confirmPassword={passwordConfirmation}
            handleNewPassword={handlePassword}
            handleConfirmPassword={(e) => setPasswordConfirmation(e.currentTarget.value)}
            isLoading={false}
          />
        </div>
        <Container className={styles.ButtonContainer}>
          <Button
            variant="primary"
            type="button"
            onClick={() => navigate('/login')}
            className={styles.Buttons}
            disabled={isLoading}
          >
            {t('navigation_options.Back')}
          </Button>
          <Button variant="primary" type="submit" className={styles.Buttons} disabled={isLoading}>
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
              className={isLoading ? `${styles.ButtonSpinner}` : `${styles.DisabledButtonSpinner}`}
            />
            {t('navigation_options.Submit')}
          </Button>
        </Container>
      </Form>
      {alertMessage && (
        <PopupAlert i18nKey={alertMessage} onClose={hideAlertPopup} variant={alertVariant} />
      )}
    </>
  );
}

export default ChooseNewPassword;
