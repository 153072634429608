import * as React from 'react';
import { TagEditor } from 'modules/logic_builder/components/dialogs/endpoint_editor_dialog/tag_editor';
import LogicBuilderContext from 'modules/logic_builder/store';
import { Button, Form, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Icon from 'web_ui/icon';
import styles from './styles.module.css';
import { TagCreateEditDialog } from 'modules/logic_builder/components/dialogs/tag_create_edit_dialog';

interface tagManagerModalProps {
  open: boolean;
  onHide: () => void;
}

function TagManagerModal({ open, onHide }: tagManagerModalProps) {
  const [search, setSearch] = React.useState('');
  const { t } = useTranslation();
  const { tags } = React.useContext(LogicBuilderContext);
  const [selectedTagId, setSelectedTagId] = React.useState<number>();
  const [showCreateEditDialog, setShowCreateEditDialog] = React.useState<boolean>(false);

  const handleOpenModalCreateEditTag = () => {
    setShowCreateEditDialog(true);
  };

  return (
    <React.Fragment>
      <Modal
        show={open}
        onHide={() => {
          onHide();
          setSearch('');
        }}
        centered
      >
        <Modal.Header closeButton>{t('logicBuilder.TagManager')}</Modal.Header>
        <Modal.Body>
          <div>
            <label className="mb-2">{t('SearchItem')}</label>
            <Form.Control
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              placeholder="Ex. Exocoding"
            />
          </div>
          <div className="mt-4">
            <div className="d-flex justify-content-between align-items-center mb-2">
              <label>{t('logicBuilder.Tags')}</label>
              <Button
                variant="primary"
                className={`${styles.plusButton}`}
                onClick={() => handleOpenModalCreateEditTag()}
              >
                <Icon iconName="plus" extraProps="small" />
              </Button>
            </div>
            <TagEditor
              onClick={(tagId: any) => {
                if (
                  Object.values(tags).includes(
                    Object.values(tags).filter((item) => item.id === tagId)[0]
                  )
                ) {
                  return;
                }
              }}
              setSelectedTagId={(id: number) => setSelectedTagId(id)}
              setShowEdit={setShowCreateEditDialog}
              search={search}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              onHide();
              setSearch('');
            }}
          >
            {t('close')}
          </Button>
        </Modal.Footer>
      </Modal>
      {showCreateEditDialog && (
        <TagCreateEditDialog
          show={showCreateEditDialog}
          tagId={selectedTagId}
          onClose={() => {
            setSelectedTagId(undefined);
            setShowCreateEditDialog(false);
          }}
        />
      )}
    </React.Fragment>
  );
}

export default TagManagerModal;
