import React from 'react';
import { Alert, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { AuthService } from 'modules/auth/services';

import styles from './styles.module.css';

type ErrorMessageProps = {
  setgettingValueError: React.Dispatch<React.SetStateAction<boolean>>;
  valueError: string;
  email: string;
};

export default function ErrorMessage(props: ErrorMessageProps) {
  const { t } = useTranslation();

  React.useEffect(() => {
    setTimeout(() => {
      props.setgettingValueError(false);
    }, 5000);
  }, [props]);

  const resendVerificationEmail = async () => {
    if (props.valueError == "User isn't enabled") {
      props.setgettingValueError(false);
      await AuthService.resendVerificationEmail(props.email.toLowerCase());
    }
  };

  return (
    <Alert
      id={'alertMessage'}
      className={styles.container}
      variant={'danger'}
      onClick={resendVerificationEmail}
    >
      {props.valueError == 'Bad credentials' ? (
        t('login_page.PasswordEmailWrong')
      ) : props.valueError == "User isn't enabled" ? (
        <>
          <div>{"User isn't enabled"}</div>
          <div>Click here to request a new verification email.</div>
        </>
      ) : (
        props.valueError
      )}
    </Alert>
  );
}
