import React, { ChangeEvent, useEffect, useState } from 'react';
import { ControlProps } from '..';
import styles from './styles.module.css';
import 'highlight.js/styles/night-owl.css';
import hljs from 'highlight.js';
import css from 'highlight.js/lib/languages/css';
import { useSelector } from 'react-redux';
import { InterfaceStudioState } from 'modules/designer/studio/store';
import HelpPopover from '../components/Popover';
import HelpIcon from '../components/HelpIcon';
import { useTranslation } from 'react-i18next';

hljs.registerLanguage('css', css);

// This code was taken from https://codepen.io/WebCoder49/pen/dymYLzE
// and https://css-tricks.com/creating-an-editable-textarea-that-supports-syntax-highlighted-code/
export function CustomCssControl(props: ControlProps) {
  const textareaRef = React.createRef<HTMLTextAreaElement>();
  const codeRef = React.createRef<HTMLElement>();
  const [editingText, setEditingText] = useState('');
  const selectedComponent = useSelector(
    (state: InterfaceStudioState) => state.studio.selectedComponent.uuid
  );
  const [localSelectedComponent, setLocalSelectedComponent] = useState<string | null>();
  const { t } = useTranslation();

  // Flags used to control useEffect execution.
  const [first, setFirst] = useState(false);
  const [second, setSecond] = useState(false);

  useEffect(() => {
    setSecond(false);
    setLocalSelectedComponent(selectedComponent);
  }, [selectedComponent]);

  // Use 'first' to execute this when component is first rendered.
  useEffect(() => {
    if (first) return;

    const highlightedElement = document.querySelector('#highlighting');
    if (!highlightedElement) return;

    //! For some reason without the next 2 lines highlighting doesn't work.
    highlightedElement.innerHTML = 'div color';
    hljs.highlightElement(highlightedElement as HTMLElement);
    // Clean.
    highlightedElement.innerHTML = '';
    hljs.highlightElement(highlightedElement as HTMLElement);

    setFirst(true);
  }, [first]);

  // Use 'second' to execute this when user selects another component.
  useEffect(() => {
    if (!first) return;
    if (localSelectedComponent === selectedComponent && second) return;

    const highlightedElement = document.querySelector('#highlighting');
    if (!highlightedElement) return;

    const text = props.value ?? '';
    setEditingText(props.value ?? '');
    highlightedElement.innerHTML = text;
    hljs.highlightElement(highlightedElement as HTMLElement);

    setSecond(true);
  }, [first, localSelectedComponent, props.value, second, selectedComponent]);

  function update(text: string) {
    setEditingText(text);

    const highlightedElement = document.querySelector('#highlighting');
    if (!highlightedElement) return;

    if (text[text.length - 1] === '\n') {
      text += ' ';
    }
    const innerHTML = text
      .replace(new RegExp('&', 'g'), '&amp;')
      .replace(new RegExp('<', 'g'), '&lt;'); /* Global RegExp */
    highlightedElement.innerHTML = innerHTML;
    hljs.highlightElement(highlightedElement as HTMLElement);
  }

  function sendAction(css: string) {
    if (!props.onChange) return;
    props.onChange(css, 'css');
  }

  const handleInput = (event: ChangeEvent<HTMLTextAreaElement>) => {
    sendAction(event.target.value);
    update(event.target.value);
    syncScroll();
  };

  const syncScroll = () => {
    const highlightedElement = document.querySelector('#highlighting');
    if (!highlightedElement) return;
    if (!codeRef.current || !textareaRef.current) return;

    highlightedElement.scrollTop = textareaRef.current.scrollTop;
    highlightedElement.scrollLeft = textareaRef.current.scrollLeft;
  };

  const handleScroll = () => {
    syncScroll();
  };

  return (
    <>
      <div className="mb-3" style={{ width: '100%' }}>
        <div style={{ display: 'flex' }}>
          <div style={{ marginBottom: '8px' }}>{t('designer.right_side.CustomCSS')}</div>
          <HelpPopover
            placement="top"
            helpBoxProps={{
              title: t('designer.right_side.controls.cssControl.label')!,
              description: t('designer.right_side.controls.cssControl.desc')!
            }}
          >
            <HelpIcon />
          </HelpPopover>
        </div>
        <div style={{ height: '150px', width: '100%', position: 'relative' }}>
          <textarea
            id="editing"
            ref={textareaRef}
            spellCheck={false}
            onChange={handleInput}
            className={styles.editing}
            value={editingText}
            onScroll={handleScroll}
          />
          <pre aria-hidden="true" className={styles.preHighlighting}>
            <code id="highlighting" ref={codeRef} className={styles.highlighting} />
          </pre>
        </div>
      </div>
    </>
  );
}
