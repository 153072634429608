import { PageRepo } from './PageRepo';
import { LayoutRepo } from './LayoutRepo';
import { ModalRepo } from './ModalRepo';
import { CustomComponentRepo } from './CustomComponentRepo';
import { ViewStateRepo } from './ViewStateRepo';
import { AppLanguagesRepo } from './AppLanguagesRepo';
import { AppTranslatesRepo } from './AppTranslatesRepo';
import { ThemeRepo } from './ThemeRepo';
import { FrontendPropertiesRepo } from './FrontendPropertiesRepo';
import { TemplatesRepo } from './TemplatesRepo';
import { FolderRepo } from './FolderRepo';
import { AppPropertiesRepo } from './AppPropertiesRepo';

export const PageRepository = new PageRepo();
export const LayoutRepository = new LayoutRepo();
export const ModalRepository = new ModalRepo();
export const CustomComponentRepository = new CustomComponentRepo();
export const ViewStateRepository = new ViewStateRepo();
export const AppLanguageRepository = new AppLanguagesRepo();
export const AppTranslatesRepository = new AppTranslatesRepo();
export const ThemeRepository = new ThemeRepo();
export const FrontendPropertiesRepository = new FrontendPropertiesRepo();
export const TemplateRepository = new TemplatesRepo();
export const AppPropertiesRepository = new AppPropertiesRepo();
export const FolderRepository = new FolderRepo();
