import { createStore, applyMiddleware, compose } from 'redux';
import { studioReducer } from './reducers/studio';
import { componentsReducer } from './reducers/components';
import { viewsReducer } from './reducers/views';
import { widgetsReducer } from './reducers/widgets';
import { linksReducer } from './reducers/links';
import { saveMiddleware } from './middlewares/save';
import {
  ComponentUUID,
  LayoutComponent,
  ViewUUID,
  View,
  Editor,
  FrontendFunctionUUID,
  FrontendPropertyUUID,
  FrontendVariableUUID,
  FrontendFunction,
  FrontendVariable,
  ParamsUUID,
  PageParam,
  FrontendProperty
} from '../../types';
import { Widget, WidgetUUID } from 'web_ui/workboard/widgets/types';
import { themeReducer } from './reducers/theme';
import { functionsReducer } from './reducers/functions';
import { variablesReducer } from './reducers/variables';
import { objectsReducer } from './reducers/schemas';
import { ExoRole, SchemaObject } from '../../../logic_builder/types';
import { ParamsReducer } from './reducers/params';
import { combineReducers } from 'packages/redux-utils';
import { propertiesReducer } from './reducers/properties';
import { designerStudioReducer } from './reducers/designerStudio';
import { roleReducer } from './reducers/roles';

export type DESIGNER_COMPONENT_SELECTION = 'FOCUS_TEXT';

export type DraggedComponent = {
  componentId: ComponentUUID | null;
  componentType: string | null;
};

export interface ViewsState {
  [key: ViewUUID]: View;
}

export interface PageParamsState {
  [key: ParamsUUID]: PageParam;
}

export interface ComponentsState {
  [key: ComponentUUID]: LayoutComponent;
}

export interface LinksState {
  [key: ComponentUUID]: ComponentUUID[];
}

export interface WidgetsState {
  [key: WidgetUUID]: Widget;
}

export interface ThemeState {
  [key: string]: {
    [key: string]: string;
  };
}

export interface FunctionsState {
  [key: FrontendFunctionUUID]: FrontendFunction;
}

export interface VariablesState {
  [key: FrontendVariableUUID]: FrontendVariable;
}

export interface PropertiesState {
  [key: FrontendPropertyUUID]: FrontendProperty;
}

export type DesignerMode = 'DESIGN' | 'BEHAVIOR';

export type ViewportMode = 'desktop' | 'mobile' | 'tablet';

export interface ObjectsState {
  [key: string]: SchemaObject;
}

export interface RoleState {
  [key: number]: ExoRole;
}

export interface InterfaceStudioState {
  studio: StudioState;
  views: ViewsState;
  widgets: WidgetsState;
  components: ComponentsState;
  links: LinksState;
  theme: ThemeState;
  functions: FunctionsState;
  variables: VariablesState;
  properties: PropertiesState;
  objects: ObjectsState;
  params: PageParamsState;
  roles: ExoRole[];
}

export type StateHistory = {
  redoStack: string[];
  undoStack: string[];
  // Last valid entry is the last action the backend processed successfully.
  lastValidEntry: string | null;
};

export interface StudioState {
  history: StateHistory;
  selectedComponent: {
    uuid: ComponentUUID | null;
    type?: DESIGNER_COMPONENT_SELECTION;
  };
  designerMode: DesignerMode;
  viewportMode: ViewportMode;
  hoveredComponent: ComponentUUID | null;
  selectedView: ViewUUID | null;
  selectedTheme: string | null;
  view_id: ViewUUID;
  module_id: ViewUUID;
  draggedComponent: DraggedComponent | null;
  editor: keyof typeof Editor | null;
  errorMessage: string;
}

export const initialState: InterfaceStudioState = {
  studio: {
    history: {
      redoStack: [],
      undoStack: [],
      lastValidEntry: null
    },
    designerMode: 'DESIGN',
    viewportMode: 'desktop',
    editor: null,
    selectedComponent: {
      uuid: null
    },
    hoveredComponent: null,
    selectedView: null,
    selectedTheme: null,
    draggedComponent: null,
    view_id: '',
    module_id: '',
    errorMessage: ''
  },
  views: {},
  widgets: {},
  components: {},
  links: {},
  theme: {},
  functions: {},
  variables: {},
  objects: {},
  params: {},
  properties: {},
  roles: []
};

const appReducers = combineReducers(
  {
    studio: studioReducer || (() => null),
    views: viewsReducer || (() => null),
    widgets: widgetsReducer || (() => null),
    components: componentsReducer || (() => null),
    links: linksReducer || (() => null),
    theme: themeReducer || (() => null),
    functions: functionsReducer || (() => null),
    variables: variablesReducer || (() => null),
    objects: objectsReducer || (() => null),
    params: ParamsReducer || (() => null),
    properties: propertiesReducer || (() => null),
    roles: roleReducer || (() => null)
  },
  designerStudioReducer
);

export const RESET_DESIGNER_STUDIO = 'RESET_DESIGNER_STUDIO';
export const REDO_ONCE = 'REDO_ONCE';
export const UNDO_ONCE = 'UNDO_ONCE';
export const RESTORE_LAST_VALID_STATE = 'RESTORE_LAST_VALID_STATE';
export const SAVE_LAST_VALID_STATE = 'SAVE_LAST_VALID_STATE';

const enhancedReducer = (state: any, action: any) => {
  if (action.type === RESET_DESIGNER_STUDIO) {
    return initialState;
  }

  if (action.type === RESTORE_LAST_VALID_STATE) {
    if (state.studio.history.lastValidEntry == null) {
      return state;
    }

    const toRestoreState: InterfaceStudioState = JSON.parse(state.studio.history.lastValidEntry);

    if (toRestoreState != null) {
      toRestoreState.studio.history.lastValidEntry = state.studio.history.lastValidEntry;
      return toRestoreState;
    }

    return state;
  } else if (action.type === SAVE_LAST_VALID_STATE) {
    const newState: InterfaceStudioState = { ...state };
    const lastValidEntry = JSON.stringify({
      views: state.views,
      widgets: state.widgets,
      components: state.components,
      links: state.links,
      theme: state.theme,
      functions: state.functions,
      variables: state.variables,
      properties: state.properties,
      objects: state.objects,
      params: state.params,
      studio: {
        ...state.studio,
        history: {
          redoStack: [],
          undoStack: [],
          lastValidEntry: null
        }
      }
    });
    if (lastValidEntry) {
      newState.studio = {
        ...newState.studio,
        history: {
          ...newState.studio.history,
          lastValidEntry: lastValidEntry
        }
      };
    }

    return newState;
  }

  if (action.type === UNDO_ONCE) {
    if (state.studio.history.undoStack.length === 1) {
      return state;
    }

    const current = state.studio.history.undoStack.pop();

    const toRestore = state.studio.history.undoStack[state.studio.history.undoStack.length];

    if (current != null) {
      state.studio.history.redoStack.push(current);
      const toRestoreState: InterfaceStudioState = JSON.parse(toRestore);
      toRestoreState.studio = state.studio;
      return toRestoreState;
    }

    return state;
  } else if (action.type === REDO_ONCE) {
    if (state.studio.history.redoStack.length === 0) {
      return state;
    }

    const toRestore = state.studio.history.redoStack.pop();

    if (toRestore != null) {
      state.studio.history.undoStack.push(toRestore);
      const toRestoreState: InterfaceStudioState = JSON.parse(toRestore);
      toRestoreState.studio = state.studio;
      return toRestoreState;
    }

    return state;
  }

  const appState = appReducers(state, action);

  // Create shouldCreateEntry function to avoid saving state after every action.
  // const entry = {
  //   views: appState.views,
  //   widgets: appState.widgets,
  //   components: appState.components,
  //   links: appState.links,
  //   theme: appState.theme,
  //   functions: appState.functions,
  //   variables: appState.variables,
  //   objects: appState.objects,
  //   params: appState.params,
  //   properties: appState.properties
  // };
  // const dehydratedEntry = JSON.stringify(entry);
  // if (dehydratedEntry) {
  //   if (appState.studio == null) {
  //     return {
  //       ...appState,
  //       studio: {
  //         history: {
  //           lastValidEntry: null,
  //           undoStack: [dehydratedEntry],
  //           redoStack: []
  //         }
  //       }
  //     };
  //   }

  //   return {
  //     ...appState,
  //     studio: {
  //       ...appState.studio,
  //       history: {
  //         ...appState.studio.history,
  //         undoStack: [...appState.studio.history.undoStack, dehydratedEntry],
  //         redoStack: []
  //       }
  //     }
  //   };
  // }

  return appState;
};

// @ts-ignore
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(
  enhancedReducer,
  initialState,
  composeEnhancers(applyMiddleware(saveMiddleware))
);
