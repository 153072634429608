import React, { ReactNode } from 'react';

type SidebarContentProps = {
  children?: ReactNode;
};

function SidebarContent({ children }: SidebarContentProps) {
  return (
    <div className={'p-2 d-flex h-100'} style={{ width: '100%' }}>
      {children}
    </div>
  );
}

export default SidebarContent;
