import React, { DragEvent, MouseEvent, useCallback, useRef, useState } from 'react';
import { Combobox } from '@headlessui/react';
import { ReturnType } from 'web_ui/function_editor/store/types/functions';
import styles from './styles.module.css';
import Icon from 'web_ui/icon';
import { Float } from '@headlessui-float/react';
import useWindowDimensions from 'modules/designer/hooks/window';

type ColumnDataTypePickerProps = {
  handleChange: (type: ReturnType) => void;
  type: ReturnType;
  types?: ReturnType[];
  disabled?: boolean;
  advanced?: boolean;
};

export const ColumnDataTypePicker = React.forwardRef<HTMLInputElement, ColumnDataTypePickerProps>(
  ({ handleChange, type, types, disabled = false, advanced }, ref) => {
    const [query, setQuery] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    // const [, setIsOpen] = useState<number>();
    const refCombo = useRef<HTMLButtonElement>(null);
    const refButton = useRef<HTMLButtonElement>(null);

    const rect = refCombo.current?.getBoundingClientRect();

    function filteredTypes(): ReturnType[] {
      return query === ''
        ? types ?? []
        : types
        ? types.filter((item) => {
            return (
              (item.name && item.name.toLowerCase().includes(query.toLowerCase())) ||
              (item.type && item.type.toLowerCase().includes(query.toLowerCase()))
            );
          })
        : [];
    }

    const displayValue = (): string => {
      if (type.type !== 'ENUM') return type.type;

      if (!types) return type.type;

      for (const t of types) {
        if (t?.enumUuid && t.enumUuid === type.enumUuid && t?.name) return t.name;
      }
      return type.type;
    };

    const preventDragPropagation = useCallback((event: DragEvent<HTMLElement>): void => {
      event.preventDefault();
      event.stopPropagation();
    }, []);

    const preventClickPropagation = useCallback((event: MouseEvent<HTMLElement>): void => {
      event.stopPropagation();
    }, []);

    const getSelectedItem = (): ReturnType | undefined => {
      if (!types) return undefined;
      for (const t of types) {
        if (type.enumUuid && t.enumUuid === type.enumUuid) {
          return t;
        } else if (type.objectUuid && t.objectUuid === type.objectUuid) {
          return t;
        } else if (!type.objectUuid && !type.enumUuid && t.type === type.type) {
          return t;
        }
      }
      return undefined;
    };

    return (
      <Combobox
        onChange={(type: ReturnType) => {
          setQuery('');
          handleChange(type);
        }}
        value={getSelectedItem()}
        disabled={disabled}
        ref={refCombo}
      >
        <div
          id="typeField"
          className={`${styles.ReturnTypeCombo} ${disabled ? 'bg-body-secondary' : ''}`}
          draggable={true}
          onDragStart={preventDragPropagation}
        >
          <Combobox.Input
            id="selectType"
            ref={ref}
            className={`${styles.ReturnTypeComboInput} ${
              isOpen ? 'rounded-top-2 rounded-bottom-0' : 'rounded-2'
            }`}
            onChange={(e) => setQuery(e.target.value)}
            autoComplete={'off'}
            displayValue={() => displayValue()}
            draggable={true}
            onDragStart={preventDragPropagation}
          />
          <Float
            show={isOpen}
            strategy="fixed"
            flip={0}
            placement="bottom"
            offset={rect?.y && rect?.y > 750 ? -190 : -10}
          >
            <Combobox.Button
              ref={refButton}
              id="dropdown"
              style={{
                fontSize: '0.7rem',
                float: 'right',
                height: '100%',
                paddingTop: '3px',
                cursor: 'pointer',
                width: '30px'
              }}
              className="d-flex align-items-center justify-content-center"
              as={'span'}
              draggable={true}
              onDragStart={preventDragPropagation}
            >
              {({ open }) => {
                setIsOpen(open);
                if (open) {
                  return <Icon iconName={'chevron-up'} />;
                } else {
                  return <Icon iconName={'chevron-down'} />;
                }
              }}
            </Combobox.Button>
            <Combobox.Options
              className={`${styles.ReturnTypeComboOptions}`}
              draggable={true}
              onDragStart={preventDragPropagation}
              style={{ right: advanced ? '43%' : '115px' }}
            >
              {filteredTypes()?.map((item, index) => (
                <Combobox.Option
                  id={`${item.type.toLowerCase()}Type`}
                  className={({ active }) =>
                    active
                      ? `bg-secondary ${styles.ReturnTypeComboOption}`
                      : `${styles.ReturnTypeComboOption}`
                  }
                  value={item}
                  key={index}
                  draggable={true}
                  onDragStart={preventDragPropagation}
                  onClick={preventClickPropagation}
                  style={{ cursor: 'default', textAlign: 'center' }}
                >
                  {item.name ?? item.type}
                </Combobox.Option>
              ))}
            </Combobox.Options>
          </Float>
        </div>
      </Combobox>
    );
  }
);
