import { BadgesButtonData, BadgesButtonStyles } from './index';
import { LayoutComponent } from '../../../../designer/types';

export const BADGES_BUTTON_TEMPLATE: LayoutComponent<BadgesButtonData, BadgesButtonStyles> = {
  uuid: 'empty',
  type: 'BADGESBUTTON',
  data: {
    text: 'Notifications ',
    width: 140,
    widthUnit: 'px',
    maxWidth: 'none',
    minWidth: 'none',
    widthCalc: 20,
    height: 30,
    heightUnit: 'px',
    maxHeight: 'none',
    minHeight: 'none',
    type: 'Normal',
    count: '99+'
  },
  styles: {
    badgesColor: 'secondary',
    themeColor: 'primary',
    paddingTop: 0,
    paddingRight: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    borderTop: 0,
    borderRight: 0,
    borderBottom: 0,
    borderLeft: 0,
    marginTop: 10,
    marginRight: 10,
    marginBottom: 10,
    marginLeft: 10,
    css: ''
  },
  events: {}
};
