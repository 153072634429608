import React, { ChangeEvent, useEffect, useState } from 'react';
import { AppFullInfo } from 'modules/project/types';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import locations from '../../../../assets/locations.json';
import { AppLanguages } from '../../../../modules/designer/types';
import HelpPopover from 'web_ui/workboard/sidebar/controls/components/Popover';
import HelpIcon from 'web_ui/workboard/sidebar/controls/components/HelpIcon';
import { validateDescriptionInputs } from 'utils/inputValidation';
import { Authorization } from 'modules/auth/session/authorization';
import { SystemRoleAuthorityName } from 'modules/auth/types/auth_types';

export type Step2Props = {
  /* Application info to use on the form */
  appInfo: AppFullInfo;
  /* Callback on form changes */
  onChange: <K extends keyof AppFullInfo>(key: K, value: AppFullInfo[K]) => void;
  /* set state of errors */
  updateErrorMessage: (s: string) => void;
};

/**
 * Step2 of the application creation wizard.
 * In this step the user must insert the application details.
 *
 * @component
 */
function Step2(props: Step2Props) {
  const { t } = useTranslation();
  const [appNameOnFocus, setAppNameOnFocus] = useState('');
  const [descriptionValidation, setDescriptionValidation] = useState({
    feedback: '',
    valid: true
  });

  useEffect(() => {
    const validation = checkDescriptionIsValid(props.appInfo.description);
    setDescriptionValidation(validation);
    if (!validation.valid) {
      props.updateErrorMessage(validation.feedback);
    }
  }, [props.appInfo.description]);

  function handleAppInfoChange<K extends keyof AppFullInfo>(key: K, value: AppFullInfo[K]) {
    if (key === 'domain' && value) {
      const text = value.toString().toLowerCase().trim().replace(/\s/g, '');
      props.onChange('domain', text);
    } else props.onChange(key, value);
  }

  const handleLanguageChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const split = e.target.value.split('-');
    const newLanguage: AppLanguages = {
      id: '',
      name: split[1],
      locale: split[0]
    };
    props.onChange('mainLanguage', newLanguage);
  };

  const handleProjectNameBlur = () => {
    const correctAppName = (name: string) => {
      return name
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .replace(/[^a-zA-Z\s-]/g, '')
        .replace(/\s+/g, '_')
        .toLowerCase();
    };

    const packageName = props.appInfo.domain.split('.');
    if (packageName.length >= 3) {
      const appName = correctAppName(props.appInfo.name);
      const prevAppName = correctAppName(appNameOnFocus);

      if (prevAppName !== packageName[2]) {
        return;
      }

      packageName[2] = appName;
      const formattedPackageName = packageName.join('.');
      props.onChange('domain', formattedPackageName);
    }
  };

  const checkDescriptionIsValid = (input: string) => {
    const { code, valid } = validateDescriptionInputs(input, 255, false);
    let descriptionFeedback = '';
    if (code === 'EXCEEDS_MAX_LENGTH') {
      descriptionFeedback = 'inputValidationErrorMessages.ProjectDescriptionExceedsMaxLength';
    } else if (code === 'EMPTY_STRING') {
      descriptionFeedback = 'inputValidationErrorMessages.ProjectDescriptionEmptyString';
    }
    props.updateErrorMessage(descriptionFeedback);
    return { feedback: descriptionFeedback, valid };
  };

  const handleDescriptionChange = (description: string) => {
    checkDescriptionIsValid(description);
    handleAppInfoChange('description', description);
  };

  useEffect(() => {
    !props.appInfo.name
      ? props.updateErrorMessage(t('inputValidationErrorMessages.ProjectNameNoLength'))
      : props.updateErrorMessage('');
  }, [props, t]);

  return (
    <>
      <h4 className="text-body-emphasis">{t('new_application.step2.MoreAboutApplication')}</h4>

      <div className="mt-5 mb-3">
        <Form.Group className="mb-3" controlId="formName">
          <Form.Label className="text-body-primary">{t('Name')}</Form.Label>
          <Form.Control
            type="text"
            autoFocus
            placeholder={t('new_application.step2.ApplicationName') ?? 'App Name'}
            onChange={(e) => {
              handleAppInfoChange('name', e.currentTarget.value);
            }}
            value={props.appInfo.name}
            maxLength={64}
            required
            onBlur={handleProjectNameBlur}
            onFocus={() => setAppNameOnFocus(props.appInfo.name)}
            isInvalid={!props.appInfo.name}
            onKeyDown={(ev) => {
              if (ev.key === 'Enter') {
                ev.stopPropagation();
                ev.preventDefault();
              }
            }}
          />
          <Form.Control.Feedback type={'invalid'}>
            {t('inputValidationErrorMessages.ProjectNameNoLength')}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3" controlId="formDescription">
          <Form.Label>{t('Description')}</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            onChange={(e) => handleDescriptionChange(e.target.value)}
            value={props.appInfo.description}
            isInvalid={!descriptionValidation.valid}
          />
          <Form.Control.Feedback type={'invalid'}>
            {t(descriptionValidation.feedback)}
          </Form.Control.Feedback>
        </Form.Group>

        <Authorization allowedSystemAuthorities={[SystemRoleAuthorityName.MANAGE_LANGUAGES]}>
          <Form.Group className="mb-3" controlId="selectLanguages">
            <Form.Label>{t('new_application.step2.Language')}</Form.Label>
            <Form.Select
              onChange={(e) => {
                handleLanguageChange(e);
              }}
              value={props.appInfo.mainLanguage.locale + '-' + props.appInfo.mainLanguage.name}
              required
            >
              {Object.values(locations).map((location) => {
                const isDefault = location.code.length === 0 || location.code.startsWith('default');
                return (
                  <option
                    id={location.code}
                    key={location.code}
                    value={isDefault ? undefined : location.code + '-' + location.name}
                  >
                    {location.name}
                  </option>
                );
              })}
            </Form.Select>
          </Form.Group>
        </Authorization>
      </div>
    </>
  );
}

export default Step2;
