import React, { CSSProperties, forwardRef, memo } from 'react';
import { ComponentUUID, ViewUUID } from '../../../types';
import ComponentsRenderer from '../components_renderer';
import { FlexDirectionProperty, PositionProperty } from 'csstype';
import { Col } from 'react-bootstrap';
import { parseCustomCss, removeDeclarationsWithUndefinedValue } from 'utils/cssUtils';
import useCorrectedUrl from 'modules/designer/hooks/correctUrl';
import { useSelector } from 'react-redux';
import { InterfaceStudioState } from '../../store';
import { convertingCSS } from 'utils/utils';

export type ColumnData = {
  url?: string;
  width?: number;
  widthUnit?: string;
  height?: number | string;
  heightUnit?: number | string;
  maxHeight?: number | string;
  maxHeightUnit?: string;
  minHeight?: number | string;
  minHeightUnit?: string;
  heightCalc?: number;
  overflowx?: string;
  overflowy?: string;
  verticalAlign?: string;
  horizontalAlign?: string;
  flexDirection?: FlexDirectionProperty;
  justifyMode?: string;
  visible?: string;
};

export type ColumnStyles = {
  staticColor?: string;
  themeColor?: string;
  color?: string;
  fontSize?: number;
  borderRadius?: number;
  marginTop?: number;
  marginBottom?: number;
  marginLeft?: number;
  marginRight?: number;
  borderTop?: number;
  borderBottom?: number;
  borderLeft?: number;
  borderRight?: number;
  paddingTop?: number;
  paddingLeft?: number;
  paddingRight?: number;
  paddingBottom?: number;
  transform?: string;
  transformOrigin?: string;
  boxshadow?: string;
  css?: string;
  borderColor?: string;
};

export type ColumnComponentProps = {
  viewUUID: ViewUUID;
  uuid: ComponentUUID;
  data: ColumnData;
  styles: ColumnStyles;
  custom_uuid: ComponentUUID;
  new_custom_uuid: ComponentUUID;
};

function ColumnComponent(props: ColumnComponentProps, ref: React.Ref<any>) {
  const correctUrl = useCorrectedUrl(props.data.url);
  const theme = useSelector((state: InterfaceStudioState) => state.theme);

  const style = {
    ...(props.data.height &&
      props.data.heightUnit && {
        height: props.data.height + '' + props.data.heightUnit
      }),
    ...(props.data.height &&
      !props.data.heightUnit && {
        height: props.data.height
      }),
    ...(props.data.maxHeight &&
      props.data.maxHeightUnit && {
        maxHeight: props.data.maxHeight + '' + props.data.maxHeightUnit
      }),
    ...(props.data.maxHeight &&
      !props.data.maxHeightUnit && {
        maxHeight: props.data.maxHeight
      }),
    ...(props.data.minHeight &&
      props.data.minHeightUnit && {
        minHeight: props.data.minHeight + '' + props.data.minHeightUnit
      }),
    ...(props.data.minHeight &&
      !props.data.minHeightUnit && {
        minHeight: props.data.minHeight
      }),
    overflowX: props.data.overflowx as 'visible' | 'hidden' | 'scroll' | 'auto' | undefined,
    overflowY: props.data.overflowy as 'visible' | 'hidden' | 'scroll' | 'auto' | undefined,
    flexDirection: props.data.flexDirection,
    alignItems: props.data.verticalAlign,
    justifyContent: props.data.horizontalAlign,
    backgroundColor: props.styles.staticColor,
    color: props.styles.color,
    fontSize: `${props.styles.fontSize}px`,
    borderRadius: `${props.styles.borderRadius}px`,
    paddingTop: `${props.styles.paddingTop}px`,
    paddingRight: `${props.styles.paddingRight}px`,
    paddingBottom: `${props.styles.paddingBottom}px`,
    paddingLeft: `${props.styles.paddingLeft}px`,
    borderTop: `${props.styles.borderTop}px solid #333`,
    borderRight: `${props.styles.borderRight}px solid #333`,
    borderLeft: `${props.styles.borderLeft}px solid #333`,
    borderBottom: `${props.styles.borderBottom}px solid #333`,
    marginTop: `${props.styles.marginTop}px`,
    marginRight: `${props.styles.marginRight}px`,
    marginBottom: `${props.styles.marginBottom}px`,
    marginLeft: `${props.styles.marginLeft}px`,
    backgroundImage: props.data.url && `url(${correctUrl})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% 100%',
    transform: props.styles.transform,
    transformOrigin: props.styles.transformOrigin,
    position: 'relative' as PositionProperty, // Used on container tags to keep select tools available
    borderColor:
      props.styles.borderColor &&
      convertingCSS(props.styles.borderColor, theme.colors[props.styles.borderColor]),
    boxShadow: props.styles.boxshadow,
    overflow: props.styles.boxshadow ? 'visible' : undefined
  };

  if (props.data.heightCalc && props.data.height !== 'fit-content') {
    style.height = `calc(${style.height} - ${props.data.heightCalc}px)`;
  }

  removeDeclarationsWithUndefinedValue(style);

  return (
    <Col
      ref={ref}
      className={`d-flex bg-${props.styles.themeColor}`}
      style={{ ...style, ...parseCustomCss(props.styles.css) }}
    >
      <ComponentsRenderer
        viewUUID={props.viewUUID}
        parentUUID={props.uuid}
        custom_uuid={props.custom_uuid}
        new_custom_uuid={props.new_custom_uuid}
      />
    </Col>
  );
}

export * from './manifest';
export * from './template';
export default memo(forwardRef(ColumnComponent));
