import React, { DragEvent, useCallback, useContext, useEffect, useState } from 'react';
import Sidebar, { SidebarPosition, Studios, ToolbarItem } from 'web_ui/workboard/sidebar';
import Workboard from 'web_ui/workboard';
import DbModelerToolbar from './toolbars/dbmodeler_toolbar';
import { EnumFrame } from '../types';
import { ElementsTemplate } from 'routes/studio/data/elements';
import { v4 as uuidv4 } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import { addEnum } from './store/actions/enums';
import { addTable } from './store/actions/root';
import { DatabaseStudioState } from './store';
import ConnectorRenderer from './connectors/ConnectorRenderer';
import { ContextMenuOption } from 'web_ui/workboard/ContextMenuItem';
import { Widget, WidgetType } from 'web_ui/workboard/widgets/types';
import { WIDGETS_TEMPLATE } from 'web_ui/workboard/widgets';
import SessionContext from 'modules/auth/store';
import { addWidget } from 'modules/designer/studio/store/actions/widgets';
import {
  setCreatingRelationship,
  setSelectedConnector,
  setSelectedFrame
} from './store/actions/studio';
import { PopupAlert } from 'web_ui/popup_alert';
import { setErrorMessage } from 'modules/designer/studio/store/actions/studio';
import { ErrorBoundary } from 'react-error-boundary';
import GenericFallback from 'error_boundaries/genericFallback';
import { globalReport } from 'error_boundaries';
import { ModelToolbar } from './toolbars/model_toolbar';
import { LocalStorageManager } from 'utils/localStorage/localstorage';
import { useParams } from 'react-router-dom';
import { Folder, FolderType } from 'modules/designer/types';
import { FolderService } from 'modules/designer/services';

export type StudioProps = {
  toggleTheme(): void;
};

function Studio(props: StudioProps) {
  const tables = useSelector((state: DatabaseStudioState) => state.tables);
  const enums = useSelector((state: DatabaseStudioState) => state.enums);
  const [isRightSidebarOpen, setIsRightSidebarOpen] = useState(true);
  const frames = { ...tables, ...enums };
  const { module_id } = useSelector((state: DatabaseStudioState) => state.studio);
  const session = useContext(SessionContext);
  const errorMessage = useSelector((state: DatabaseStudioState) => state.studio.errorMessage);
  const { app_id } = useParams();
  const [folders, setFolders] = useState<Folder[]>();

  const fetchFolders = useCallback(async () => {
    if (!module_id) return;
    const folders = await FolderService.getFolderByModuleIdAndLocalType(module_id, FolderType.DB);
    setFolders(folders);
  }, [module_id]);

  useEffect(() => {
    fetchFolders();
  }, [fetchFolders]);

  const contextMenuItems: ContextMenuOption[] = [];
  contextMenuItems.push({
    label: 'Add Entity',
    icon: 'table',
    onClick: (ev, currentScale, currenPosition) =>
      handleCreateFrameOnClick(ev, 'TABLE', currentScale, currenPosition)
  });
  contextMenuItems.push({
    label: 'Add Enum',
    icon: 'columns',
    onClick: (ev, currentScale, currenPosition) =>
      handleCreateFrameOnClick(ev, 'ENUM', currentScale, currenPosition)
  });

  const dispatch = useDispatch();

  function handleCreateFrameOnClick(
    ev: DragEvent<HTMLDivElement>,
    type: string,
    workboardScale?: number,
    workboardPosition?: { posX: number; posY: number }
  ) {
    if (!workboardPosition || !workboardScale) return;
    const position = calculeCreatePosition(
      ev.clientX,
      ev.clientY,
      workboardPosition?.posX,
      workboardPosition?.posY,
      workboardScale
    );
    handleCreateFrame(position.posX, position.posY, type);
  }

  function handleCreateOnDrop(
    ev: DragEvent<HTMLElement>,
    workboardScale?: number,
    workboardPosition?: { posX: number; posY: number }
  ) {
    dispatch(setCreatingRelationship(false));
    if (!workboardPosition || !workboardScale) return;

    const position = calculeCreatePosition(
      ev.clientX,
      ev.clientY,
      workboardPosition?.posX,
      workboardPosition?.posY,
      workboardScale
    );

    if (ev.dataTransfer && ev.dataTransfer.getData('exocode/widget-type')) {
      const widgetType = ev.dataTransfer && ev.dataTransfer.getData('exocode/widget-type');
      handleCreateWidget(position.posX, position.posY, widgetType);
    } else if (ev.dataTransfer && ev.dataTransfer.getData('exocode/frame-type')) {
      const frameType = ev.dataTransfer && ev.dataTransfer.getData('exocode/frame-type');
      handleCreateFrame(position.posX, position.posY, frameType);
    }
  }

  function handleCreateFrame(posX: number, posY: number, type: string, folderId?: string) {
    if (type === 'TABLE') {
      const tableInfo = {
        uuid: uuidv4(),
        type: 'TABLE',
        name: 'TABLE' + makeName(type),
        description: '',
        posX: posX,
        posY: posY,
        idPkColumn: uuidv4(),
        idPkIndex: uuidv4(),
        idPkIndexColumn: uuidv4(),
        folderId: folderId
      };

      dispatch(addTable(tableInfo));
      // Select the table right after its creation
      dispatch(setSelectedFrame(tableInfo.uuid));
    } else if (type === 'ENUM') {
      const enumFrame: EnumFrame = JSON.parse(
        JSON.stringify(Object.assign({}, ElementsTemplate[type]))
      );
      enumFrame.uuid = uuidv4();
      enumFrame.content.data.name = 'ENUM' + makeName(type);
      enumFrame.posX = posX;
      enumFrame.posY = posY;
      dispatch(addEnum(enumFrame));
      dispatch(setSelectedFrame(enumFrame.uuid));
    }
  }

  function handleCreateWidget(posX: number, posY: number, type: string) {
    if (type === 'STICKY_NOTE') {
      let newWidget: Widget = WIDGETS_TEMPLATE[type];
      if (type === WidgetType.STICKY_NOTE) {
        newWidget = {
          ...newWidget,
          uuid: uuidv4(),
          parent: module_id,
          posX: posX,
          posY: posY,
          data: {
            ...newWidget.data,
            date: new Date().toISOString(),
            author: session.user?.username ?? '',
            height: 100,
            width: 250
          }
        };
      }
      dispatch(addWidget(newWidget));
    }
  }

  function makeName(type: string, suffix = 0): number {
    if (type === 'TABLE') {
      if (!suffix) suffix = Object.keys(tables).length + 1;
      if (Object.values(tables).find((t) => t.content.data.name === `TABLE${suffix}`)) {
        suffix += 1;
        return makeName(type, suffix);
      }
    } else {
      if (!suffix) suffix = Object.keys(enums).length + 1;
      if (Object.values(enums).find((e) => e.content.data.name === `ENUM${suffix}`)) {
        suffix += 1;
        return makeName(type, suffix);
      }
    }
    return suffix;
  }

  function calculeCreatePosition(
    mousePosX: number,
    mousePosY: number,
    workboardPosX: number,
    workboardPosY: number,
    workboardScale: number
  ) {
    const posX = (mousePosX - workboardPosX - 158) / workboardScale;
    const posY = (mousePosY - workboardPosY - 120) / workboardScale;
    return { posX, posY };
  }

  const isSidebarOpen = (isOpen: boolean) => {
    setIsRightSidebarOpen(isOpen);
  };

  function onCloseErrorAlert() {
    dispatch(setErrorMessage(''));
  }

  function handleWorkboardOnClick() {
    dispatch(setSelectedConnector(null));
    dispatch(setSelectedFrame(null));
  }

  const frames_tab: ToolbarItem = {
    name: 'Default',
    icon: 'window-maximize'
  };

  const table_tab: ToolbarItem = {
    name: 'Tables Folder',
    icon: 'table'
  };

  const getValueFromLSToLeft = (): number => {
    if (!app_id || !module_id) {
      return 0;
    }
    const copying = LocalStorageManager.getValueLocalStorageState(app_id, module_id);
    if (copying[module_id] && copying[module_id].dbmodeler?.lastSelectedLeftSidebarTab) {
      return copying[module_id].dbmodeler.lastSelectedLeftSidebarTab;
    } else {
      return 0;
    }
  };

  return (
    <>
      <Sidebar
        position={SidebarPosition.LEFT}
        // toolbarItems={[frames_tab, table_tab]}
        from={Studios.DB_MODELER}
        currentValueSaved={getValueFromLSToLeft()}
      >
        <ModelToolbar folders={folders} updateFolders={setFolders} />
      </Sidebar>
      <ErrorBoundary
        key={'MODELER'}
        FallbackComponent={({ error, resetErrorBoundary }) => (
          <GenericFallback
            error={error}
            resetErrorBoundary={resetErrorBoundary}
            title="modeler.Modeler"
          />
        )}
        onError={globalReport}
      >
        <Workboard
          showZoom={true}
          showFocus={true}
          showSettings={true}
          onDrop={handleCreateOnDrop}
          frames={frames}
          contextMenuItems={contextMenuItems}
          isRightSidebarOpen={isRightSidebarOpen}
          handleWorkboardOnClick={handleWorkboardOnClick}
        >
          <ConnectorRenderer />
        </Workboard>
      </ErrorBoundary>

      <Sidebar
        position={SidebarPosition.RIGHT}
        isOpen={isSidebarOpen}
        toolbarItems={[]}
        from={Studios.DB_MODELER}
      >
        <DbModelerToolbar />
      </Sidebar>

      {errorMessage && <PopupAlert i18nKey={errorMessage} onClose={onCloseErrorAlert} />}
    </>
  );
}

export default Studio;
