import React, { forwardRef, memo } from 'react';
import { ComponentUUID } from '../../../types';
import TextTransform from 'web_ui/workboard/sidebar/controls/TextTransform';
import { parseCustomCss, removeDeclarationsWithUndefinedValue } from 'utils/cssUtils';

export type LabelData = {
  labelType?: 'text' | 'title' | 'link' | 'email' | 'tel' | 'anchor';
  text?: string;
  url?: string;
  tooltip?: string;
  disabled?: boolean;
  width?: number;
  widthUnit?: number | string;
  maxWidth?: number | string;
  maxWidthUnit?: string;
  minWidth?: number | string;
  minWidthUnit?: string;
  widthCalc?: number;
  height?: number;
  heightUnit?: number | string;
  maxHeight?: number | string;
  maxHeightUnit?: string;
  minHeight?: number | string;
  minHeightUnit?: string;
  translateKey?: string;
  hasTranslation?: string;
  visible?: string;
};

export type LabelStyles = {
  themeColor?:
    | 'primary'
    | 'secondary'
    | 'success'
    | 'info'
    | 'warning'
    | 'danger'
    | 'light'
    | 'dark';
  staticColor?: string;
  fontSize?: number;
  fontStyle?: string;
  fontWeight?: string;
  textDecoration?: string;
  textAlign?: string;
  marginTop?: number;
  marginBottom?: number;
  marginLeft?: number;
  marginRight?: number;
  borderTop?: number;
  borderLeft?: number;
  borderRight?: number;
  borderBottom?: number;
  paddingTop?: number;
  paddingLeft?: number;
  paddingRight?: number;
  paddingBottom?: number;
  lineHeight?: number | string;
  fontFamily?: string;
  textTransform?: string | 'uppercase' | 'capitalize' | 'capitalize' | 'lowercase';
  css?: string;
};

export type LabelComponentProps = {
  uuid: ComponentUUID;
  data: LabelData;
  styles: LabelStyles;
};

function Label(props: LabelComponentProps, ref: React.Ref<any>) {
  const style = {
    ...(props.data.width &&
      props.data.widthUnit && {
        width: props.data.width + '' + props.data.widthUnit
      }),
    ...(props.data.height &&
      props.data.heightUnit && {
        height: props.data.height + '' + props.data.heightUnit
      }),
    ...(props.data.width &&
      !props.data.widthUnit && {
        width: props.data.width
      }),
    ...(props.data.height &&
      !props.data.heightUnit && {
        height: props.data.height
      }),
    ...(props.data.maxHeight &&
      props.data.maxHeightUnit && {
        maxHeight: props.data.maxHeight + '' + props.data.maxHeightUnit
      }),
    ...(props.data.maxHeight &&
      !props.data.maxHeightUnit && {
        maxHeight: props.data.maxHeight
      }),
    ...(props.data.minHeight &&
      props.data.minHeightUnit && {
        minHeight: props.data.minHeight + '' + props.data.minHeightUnit
      }),
    ...(props.data.minHeight &&
      !props.data.minHeightUnit && {
        minHeight: props.data.minHeight
      }),
    ...(props.data.maxWidth &&
      props.data.maxWidthUnit && {
        maxWidth: props.data.maxWidth + '' + props.data.maxWidthUnit
      }),
    ...(props.data.maxWidth &&
      !props.data.maxWidthUnit && {
        maxWidth: props.data.maxWidth
      }),
    ...(props.data.minWidth &&
      props.data.minWidthUnit && {
        minWidth: props.data.minWidth + '' + props.data.minWidthUnit
      }),
    ...(!props.data.minWidthUnit && {
      minWidth: props.data.minWidth
    }),
    color: `${props.styles.staticColor}`,
    fontSize: `${props.styles.fontSize}px`,
    fontStyle: `${props.styles.fontStyle}`,
    fontWeight: `${props.styles.fontWeight}`,
    textDecoration: `${props.styles.textDecoration}`,
    paddingTop: `${props.styles.paddingTop}px`,
    paddingRight: `${props.styles.paddingRight}px`,
    paddingBottom: `${props.styles.paddingBottom}px`,
    paddingLeft: `${props.styles.paddingLeft}px`,
    borderTop: `${props.styles.borderTop}px solid #333`,
    borderRight: `${props.styles.borderRight}px solid #333`,
    borderBottom: `${props.styles.borderBottom}px solid #333`,
    borderLeft: `${props.styles.borderLeft}px solid #333`,
    marginTop: `${props.styles.marginTop}px`,
    marginRight: `${props.styles.marginRight}px`,
    marginBottom: `${props.styles.marginBottom}px`,
    marginLeft: `${props.styles.marginLeft}px`,
    lineHeight: `${props.styles.lineHeight}`,
    ...(props.styles.fontFamily && {
      fontFamily: `${props.styles.fontFamily}`
    })
    // TextTransform: `${props.styles.textTransform}`
  };

  if (props.data.widthCalc && props.data.widthUnit !== 'px') {
    style.width = `calc(${style.width} - ${props.data.widthCalc}px)`;
  }

  removeDeclarationsWithUndefinedValue(style);

  switch (props.data.labelType) {
    case 'title':
      return (
        <h1
          ref={ref}
          className={`text-${props.styles.textAlign} text-${props.styles.themeColor} text-${props.styles.textTransform}`}
          style={{ ...style, ...parseCustomCss(props.styles.css) }}
        >
          {props.data.hasTranslation ? `{${props.data.translateKey}}` : props.data.text}
        </h1>
      );
    case 'link':
      return (
        <a
          ref={ref}
          className={`text-${props.styles.textAlign} text-${props.styles.themeColor} text-${props.styles.textTransform}`}
          href={props.data.url}
          style={{ ...style, ...parseCustomCss(props.styles.css) }}
          title={props.data.tooltip}
        >
          {props.data.hasTranslation ? `{${props.data.translateKey}}` : props.data.text}
        </a>
      );
    case 'email':
      return (
        <a
          ref={ref}
          className={`text-${props.styles.textAlign} text-${props.styles.themeColor} text-${props.styles.textTransform}`}
          href={`mailto:${props.data.url}`}
          style={{ ...style, ...parseCustomCss(props.styles.css) }}
          title={props.data.tooltip}
        >
          {props.data.hasTranslation ? `{${props.data.translateKey}}` : props.data.text}
        </a>
      );
    case 'tel':
      return (
        <a
          ref={ref}
          className={`text-${props.styles.textAlign} text-${props.styles.themeColor} text-${props.styles.textTransform}`}
          href={`tel:${props.data.url}`}
          style={{ ...style, ...parseCustomCss(props.styles.css) }}
          title={props.data.tooltip}
        >
          {props.data.hasTranslation ? `{${props.data.translateKey}}` : props.data.text}
        </a>
      );
    case 'anchor':
      return (
        <a
          ref={ref}
          className={`text-${props.styles.textAlign} text-${props.styles.themeColor} text-${props.styles.textTransform}`}
          href={`#:${props.data.url}`}
          style={{ ...style, ...parseCustomCss(props.styles.css) }}
          title={props.data.tooltip}
        >
          {props.data.hasTranslation ? `{${props.data.translateKey}}` : props.data.text}
        </a>
      );
    default:
      return (
        <p
          ref={ref}
          className={`text-${props.styles.textAlign} text-${props.styles.themeColor} text-${props.styles.textTransform}`}
          style={{ ...style, ...parseCustomCss(props.styles.css) }}
        >
          {props.data.hasTranslation ? `{${props.data.translateKey}}` : props.data.text}
        </p>
      );
  }
}

export * from './manifest';
export * from './template';
export default memo(forwardRef(Label));
