import React, { DragEvent } from 'react';
import { ListGroup } from 'react-bootstrap';

export type WidgetCardProps = {
  type: string;
  name: string;
  description: string;
  icon?: string;
};

function WidgetCard(props: WidgetCardProps) {
  function handleDragStart(ev: DragEvent<HTMLDivElement>) {
    ev.dataTransfer.setData('exocode/widget-type', props.type);
    ev.dataTransfer.dropEffect = 'copy';
  }

  return (
    <ListGroup.Item
      action
      style={{ cursor: 'grab' }}
      variant="secondary"
      className="bg-body-secondary rounded border-warning border-0 border-start border-4 me-1 mb-2"
      draggable={true}
      onDragStart={handleDragStart}
    >
      {/* <WidgetContainer
        className={`card mb-2`}
        style={{ cursor: 'grab' }}
        draggable={true}
        onDragStart={handleDragStart}
      > */}
      <div className="card-body" style={{ padding: '0.4rem 0.5rem' }}>
        <h6 className="card-subtitle fw-normal" style={{ marginTop: '0', fontSize: '15px' }}>
          <i className={'fa fa-' + props.icon} style={{ fontSize: '1.1rem' }} />
          <div style={{ marginLeft: '25px', marginTop: '-20px' }}>{props.name}</div>
        </h6>
      </div>
      {/* </WidgetContainer> */}
    </ListGroup.Item>
  );
}

export default WidgetCard;
