export enum SocialNetwork {
  GOOGLE = 'google',
  GITHUB = 'github'
}

export enum NavMenus {
  PROFILE = 'PROFILE',
  ACCOUNT = 'ACCOUNT',
  SOCIALLOGIN = 'SOCIALLOGIN'
}

export enum SETTINGS_MENU {
  ACCOUNT = 'ACCOUNT',
  GENERAL = 'GENERAL',
  STUDIOS = 'STUDIOS',
  PREFERENCES = 'PREFERENCES',
  ACCESSIBILITY = 'ACCESSIBILITY',
  HELP = 'HELP',
  BILLING = 'BILLING',
  SUPPORT = 'SUPPORT'
}

export enum Preferences {
  SHOW_GRIDS = 'designer-show_grids',
  NUMBER_OF_COLUMNS = 'modeler-number_of_columns',
  HIDE_COLUMNS = 'modeler-hide_columns',
  DARK_THEME = 'exocode-theme'
}
