import React, { useState } from 'react';
import styles from './styles.module.css';
import { Modal, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

export type WalkthroughProps = {
  show: boolean;
  onClose: () => void;
  fromHelp: boolean;
};

enum VideoType {
  LONG,
  SHORT
}

export default function ExocodingWalkthroughModal(props: WalkthroughProps) {
  const { t } = useTranslation();

  const [video, setVideo] = useState<VideoType>(props.fromHelp ? VideoType.LONG : VideoType.SHORT);

  function handleChangeVideo() {
    setVideo(video === VideoType.SHORT ? VideoType.LONG : VideoType.SHORT);
  }

  return (
    <>
      <Modal show={props.show} onHide={props.onClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{t('Walkthrough.title')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {video === VideoType.LONG && (
            <video width="100%" controls autoPlay>
              <source
                src="https://exocoding.com/wp-content/uploads/2024/07/exocoding_tutorial.mp4"
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
          )}
          {video === VideoType.SHORT && (
            <video width="100%" controls autoPlay>
              <source
                src="https://exocoding.com/wp-content/uploads/2024/07/introducing_exocoding.mp4"
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
          )}
          <div className={styles.bottomHalf}>
            {props.fromHelp ? (
              <p className={styles.description}>{t('Walkthrough.videoDescription')}</p>
            ) : (
              <p className={styles.description}>{t('Walkthrough.firstDescription')}</p>
            )}
            {props.fromHelp ? (
              <div className={styles.buttons}>
                <Button
                  className={styles.button}
                  variant="secondary"
                  href="mailto:support@exocoding.com"
                >
                  {t('Walkthrough.emailUs')}
                </Button>
                <Button
                  className={styles.button}
                  variant="secondary"
                  href="https://calendly.com/exocodyboy/exocoding-meeting"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t('Walkthrough.bookCall')}
                </Button>
                <Button
                  className={styles.button}
                  variant="secondary"
                  href="https://roadmap.exocoding.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t('Walkthrough.submitFeedback')}
                </Button>
              </div>
            ) : (
              <div className={styles.buttons}>
                <Button variant="secondary" className={styles.button} onClick={handleChangeVideo}>
                  {video === VideoType.SHORT
                    ? t('Walkthrough.goToLong')
                    : t('Walkthrough.goToShort')}
                </Button>
                <a href="#" onClick={props.onClose}>
                  Skip
                </a>
              </div>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
