import React from 'react';
import SearchModules from './search_modules';
import styles from './style.module.css';

function AppModules() {
  return (
    <div className={`${styles.moduleListWrapper}`}>
      <SearchModules />
    </div>
  );
}

export default AppModules;
