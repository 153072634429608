import { useOutletContext } from 'react-router-dom';
import { Page, Modal, Layout, ILayoutDefault } from '../types';

type UseViewsContextType = {
  pages: Page[];
  modals: Modal[];
  layouts: Layout[];
  fetchViews: () => Promise<void>;
  layoutDefault: ILayoutDefault;
};

export const useViews = () => {
  return useOutletContext<UseViewsContextType>();
};
