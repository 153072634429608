import { CheckData, CheckStyles } from './index';
import { LayoutComponent } from '../../../../designer/types';

export const CHECKBOX_TEMPLATE: LayoutComponent<CheckData, CheckStyles> = {
  uuid: 'empty',
  type: 'CHECK',
  data: {
    checkStyle: 'checkbox',
    label: 'Checkbox',
    width: 100,
    widthUnit: '%',
    maxWidth: 'none',
    minWidth: 'none',
    widthCalc: 20,
    height: 50,
    heightUnit: 'px',
    maxHeight: 'none',
    minHeight: 'none'
  },
  styles: {
    marginTop: 10,
    marginRight: 10,
    marginBottom: 10,
    marginLeft: 10,
    css: ''
  }
};
