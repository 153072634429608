import { AccordionData, AccordionStyle } from './index';
import { LayoutComponent } from 'modules/designer/types';

export const ACCORDION_TEMPLATE: LayoutComponent<AccordionData, AccordionStyle> = {
  uuid: 'empty',
  type: 'ACCORDION',
  data: {
    items: ['Home', 'About'],
    width: 100,
    widthUnit: '%',
    maxWidth: 'none',
    minWidth: 'none',
    widthCalc: 20,
    height: 'fit-content',
    heightUnit: 'px',
    maxHeight: 'none',
    minHeight: 'none',
    activeKey: 'Home'
  },
  styles: {
    marginTop: 10,
    marginRight: 10,
    marginBottom: 10,
    marginLeft: 10,
    borderTop: 0,
    borderRight: 0,
    borderBottom: 0,
    borderLeft: 0,
    paddingTop: 10,
    paddingRight: 10,
    paddingBottom: 10,
    paddingLeft: 10,
    css: ''
  }
};
