import React from 'react';
import Icon from '../icon';
import styles from './style.module.css';
import { useTranslation } from 'react-i18next';

export type ThemeComponentProps = {
  id: string;
  backgroundColor: string;
  primaryColor: string;
  secondaryColor: string;
  textColor: string;
  type?: 'radio' | 'checkbox';
  group?: string;
  checked?: boolean;
  onChange?: () => void;
  disabled?: boolean;
};

function ThemePreview(props: ThemeComponentProps) {
  const { t } = useTranslation();

  const mainContainer = {
    backgroundColor: props.backgroundColor,
    padding: '20px'
  };
  const primaryContainer = {
    backgroundColor: props.primaryColor,
    zIndex: '0'
  };
  const secondaryContainer = {
    backgroundColor: props.secondaryColor,
    marginLeft: '-15px'
  };

  const headingStyle = {
    color: props.textColor,
    fontSize: '20px'
  };
  const bodyStyle = {
    color: props.textColor,
    fontSize: '14px'
  };

  let checkedItem = <></>;
  if (props.checked) {
    checkedItem = (
      <div className={styles.checked}>
        <Icon iconName="check"></Icon>
      </div>
    );
  }

  return (
    <div
      className={styles.radioToolbar}
      onClick={() => {
        props.onChange && props.onChange();
      }}
    >
      <input
        type={props.type}
        className="btn-check"
        name={props.group}
        id={`${props.id}Theme`}
        onChange={props.onChange}
        checked={props.checked}
        disabled={props.disabled}
        required
      />
      <label
        className={`${styles.themeLabel} ${props.disabled && styles.disabled}`}
        id={props.id}
        style={mainContainer}
        htmlFor={props.id}
      >
        {props.disabled && (
          <span
            className={`position-absolute top-0 start-100 translate-middle badge rounded-pill bg-primary ${styles.commingSoon}`}
          >
            {t('Soon')}
          </span>
        )}
        <div className={styles.themeHeader}>
          <div className={styles.colorContainer}>
            <div className={styles.colorDiv} style={primaryContainer}></div>
            <div className={styles.colorDiv} style={secondaryContainer}></div>
          </div>
          {checkedItem}
        </div>
        <div style={headingStyle}>{`${props.id} Mode`}</div>
        <div style={bodyStyle}>{t('new_application.step4.Body')}</div>
      </label>
    </div>
  );
}

export default ThemePreview;
