import { LabelData, LabelStyles } from './index';
import { LayoutComponent } from '../../../../designer/types';

export const LABEL_TEMPLATE: LayoutComponent<LabelData, LabelStyles> = {
  uuid: 'empty',
  type: 'LABEL',
  data: {
    width: 100,
    widthUnit: '%',
    maxWidth: 'none',
    minWidth: 'none',
    widthCalc: 20,
    height: 28,
    heightUnit: 'px',
    maxHeight: 'none',
    minHeight: 'none',
    labelType: 'text',
    text: 'Text'
  },
  styles: {
    textAlign: 'start',
    marginTop: 10,
    marginRight: 10,
    marginBottom: 10,
    marginLeft: 10,
    borderTop: 0,
    borderRight: 0,
    borderBottom: 0,
    borderLeft: 0,
    paddingTop: 0,
    paddingRight: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    css: ''
  }
};
