import { LayoutComponent } from '../../../types';

export const OUTLET_COMPONENT_TEMPLATE: LayoutComponent = {
  uuid: 'empty',
  type: 'OUTLET',
  data: {
    width: 100,
    widthUnit: '%',
    maxWidth: 'none',
    minWidth: 100,
    minWidthUnit: 'px',
    height: 100,
    heightUnit: '%',
    maxHeight: 'none',
    minHeight: 100,
    minHeightUnit: 'px'
  },
  styles: {
    marginTop: 0,
    marginBottom: 0,
    marginLeft: 0,
    marginRight: 0,
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: 0,
    css: ''
  }
};
