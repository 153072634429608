import { AlertData, AlertStyles } from './index';
import { LayoutComponent } from '../../../../designer/types';

export const ALERT_TEMPLATE: LayoutComponent<AlertData, AlertStyles> = {
  uuid: 'empty',
  type: 'ALERT',
  data: {
    text: 'This is a success alert!',
    width: 100,
    widthUnit: '%',
    maxWidth: 'none',
    minWidth: 'none',
    widthCalc: 20,
    height: 60,
    heightUnit: 'px',
    maxHeight: 'none',
    minHeight: 'none',
    header: false
  },
  styles: {
    background: 'success',
    marginTop: 10,
    marginRight: 10,
    marginBottom: 10,
    marginLeft: 10,
    borderTop: 0,
    borderRight: 0,
    borderBottom: 0,
    borderLeft: 0,
    paddingTop: 0,
    paddingRight: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    css: ''
  }
};
