import React, { useCallback, useEffect } from 'react';
import { AppFullInfo } from 'modules/project/types';
import { AppProperties, Template } from 'modules/designer/types';
import PropertiesApp from 'routes/dashboard/components/app_settings_modal/components/properties_app';
import { DataSource } from '../../../../modules/project/enum';
import { toSnakeCase } from '../../../../utils/utils';
import { Row } from 'react-bootstrap';
import { TemplatesList } from 'modules/designer/studio/toolbars/views_toolbar/templates_list';
import { useTranslation } from 'react-i18next';
import { TemplatesService } from 'modules/designer/services';
import { VIEWS_TYPES } from 'modules/designer/studio/frames';
import produce from 'immer';

export type Step6Props = {
  /** Application info to use on the form */
  appInfo: AppFullInfo;
  /** Callback on form changes */
  onChange: <K extends keyof AppFullInfo>(key: K, value: AppFullInfo[K]) => void;
};

function Step6(props: Step6Props) {
  const randomString = [...Array(20)].map(() => (~~(Math.random() * 36)).toString(36)).join('');
  const secret = btoa(randomString);
  const [properties, setProperties] = React.useState<AppProperties[]>([]);
  const [templates, setTemplates] = React.useState<Template[]>();
  const { t } = useTranslation();

  const fetchTemplates = useCallback(async () => {
    await TemplatesService.getTemplates('createPage', VIEWS_TYPES.LAYOUT, '').then(
      (fetchedTemplates) => {
        fetchedTemplates = fetchedTemplates.sort((a, b) => {
          if (a.name === 'Blank') return -1;
          if (b.name === 'Blank') return 1;
          return a.name.localeCompare(b.name);
        });
        setTemplates(fetchedTemplates);
        if (fetchedTemplates[0]) handleDefaultLayoutChange(fetchedTemplates[0].uuid);
      }
    );
  }, []);

  useEffect(() => {
    fetchTemplates();
  }, [fetchTemplates]);

  function handleDefaultLayoutChange(templateUuid: string) {
    const frontend = produce(props.appInfo.frontend, (draft) => {
      draft.defaultLayout = templateUuid !== '' ? templateUuid : undefined;
    });

    props.onChange('frontend', frontend);
  }

  useEffect(() => {
    const isPostgresql = props.appInfo.data_sources.some((ds) => ds.type === DataSource.POSTGRESQL);
    let dbUrl;
    if (isPostgresql) {
      dbUrl = 'jdbc:postgresql://localhost:5432/' + toSnakeCase(props.appInfo.name);
    } else {
      dbUrl = 'jdbc:h2:mem:default;DB_CLOSE_DELAY=-1';
    }
    setProperties([
      {
        key: 'spring.datasource.url',
        value: dbUrl,
        description: null,
        comment: false
      },
      {
        key: 'spring.datasource.username',
        value: 'sa',
        description: null,
        comment: false
      },
      {
        key: 'spring.datasource.password',
        value: '',
        description: null,
        comment: false
      },
      {
        key: 'spring.h2.console.enabled',
        value: 'true',
        description: null,
        comment: false
      },
      {
        key: 'spring.jpa.show-sql',
        value: 'true',
        description: 'SQL Logging - JPA/Hibernate Logging (High Level: show only query)',
        comment: true
      },
      {
        key: 'spring.jpa.properties.hibernate.show_sql',
        value: 'true',
        description: null,
        comment: false
      },
      {
        key: 'spring.jpa.properties.hibernate.format_sql',
        value: 'true',
        description: null,
        comment: false
      },
      {
        key: 'logging.level.org.hibernate.SQL',
        value: 'DEBUG',
        description: 'SQL Logging - Hibernate Logging (Low Level: show query + params)',
        comment: true
      },
      {
        key: 'logging.level.org.hibernate.type.descriptor.sql.BasicBinder',
        value: 'TRACE',
        description: null,
        comment: true
      },
      {
        key: 'logging.level.org.hibernate.event.internal.DefaultPersistEventListener',
        value: 'TRACE',
        description: 'Hibernate Logging (Low Level: entity states)',
        comment: true
      },
      {
        key: 'spring.jpa.defer-datasource-initialization',
        value: 'true',
        description: null,
        comment: false
      },
      {
        key: 'spring.jpa.hibernate.ddl-auto',
        value: 'create',
        description: 'Customize Hibernate database schema generation process',
        comment: false
      },
      {
        key: 'jwt.secret',
        value: secret,
        description: 'JWT properties',
        comment: false
      },
      {
        key: 'jwt.expiration',
        value: '5*60*60*1000',
        description: 'Time in milliseconds',
        comment: false
      }
    ]);
  }, []);

  useEffect(() => {
    props.onChange('properties', properties);
  }, [properties]);

  return (
    <>
      {/* <PropertiesApp
        properties={properties}
        setProperties={setProperties}
        isLoading={false}
        nameApp={props.appInfo.name}
        showDesignerFeatures={props.showDesignerFeatures}
      /> */}

      <h5 className="text-body-primary mt-5 mb-3">
        {t('new_application.step4.DefaultLayoutTemplate')}
      </h5>
      <Row className="mb-3">
        <TemplatesList
          templates={templates ?? []}
          selectedTemplate={props.appInfo.frontend.defaultLayout ?? ''}
          setSelectedTemplate={(template: string) => handleDefaultLayoutChange(template)}
          nullable
        />
      </Row>
    </>
  );
}

export default Step6;
