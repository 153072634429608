import { NavbarData, NavbarStyles } from './index';
import { LayoutComponent } from '../../../../designer/types';

export const NAVBAR_TEMPLATE: LayoutComponent<NavbarData, NavbarStyles> = {
  uuid: 'empty',
  type: 'NAVBAR',
  data: {
    height: 50,
    heightUnit: 'px',
    maxHeight: 'none',
    minHeight: 'none',
    placement: 'top',
    showTitle: true,
    title: '',
    titlePosition: 'left',
    links: {
      items: {},
      children: {}
    },
    automaticLabels: false,
    linksPosition: 'left'
  },
  styles: {
    marginTop: 10,
    marginRight: 10,
    marginBottom: 10,
    marginLeft: 10,
    borderTop: 0,
    borderRight: 0,
    borderBottom: 0,
    borderLeft: 0,
    paddingTop: 0,
    paddingRight: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    themeColor: 'primary',
    css: ''
  }
};
