import React, { useEffect, useState } from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import styles from './styles.module.css';
import { useTranslation } from 'react-i18next';
import SessionContext from 'modules/auth/store';

type PasswordFieldsProps = {
  newPassword: string;
  confirmPassword: string;
  handleNewPassword: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleConfirmPassword: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isLoading: boolean;
};

export function validatePassword(password: string) {
  const passwordRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d).{8,}$');
  const validity = passwordRegex.test(password) || false;
  return validity;
}

function PasswordFields({
  newPassword,
  confirmPassword,
  handleNewPassword,
  handleConfirmPassword,
  isLoading
}: PasswordFieldsProps) {
  const { t } = useTranslation();
  const [newPasswordType, setNewPasswordType] = useState('password');
  const [confirmPasswordType, setConfirmPasswordType] = useState('password');
  const [validConfirmation, setValidConfirmation] = useState(true);
  const session = React.useContext(SessionContext);
  const isDark = session.preferences['exocode-theme'];

  useEffect(() => {
    setValidConfirmation(
      newPassword !== '' && confirmPassword !== '' && confirmPassword !== newPassword
    );
  }, [newPassword, confirmPassword]);

  function handleCheckPasswordConfirmation(value: string, ignoreState?: boolean) {
    const validity = value === newPassword && value.length > 0;

    if (!ignoreState) {
      setValidConfirmation(validity);
    }
    return validity;
  }

  return (
    <>
      <Form.Group className="mb-3" controlId="formPassword">
        <Form.Label className={`text-secondary ${styles.FormTitle}`}>{t('Password')}</Form.Label>
        <InputGroup className={styles.MainInputGroup}>
          <Form.Control
            className={`${styles.FormControl} ${
              isDark || isDark == undefined ? 'bg-dark border-secondary text-white-50' : ''
            }`}
            onChange={handleNewPassword}
            type={newPasswordType}
            value={newPassword}
            required
            maxLength={128}
            isInvalid={!validatePassword(newPassword)}
            autoComplete="new-password"
          />

          <InputGroup.Text
            className={`${styles.inputGroup} ${newPassword.length ? '' : 'error'} ${
              isDark || isDark == undefined ? 'bg-dark border-secondary text-white-50' : ''
            }`}
            onClick={() => {
              newPasswordType === 'password'
                ? setNewPasswordType('text')
                : setNewPasswordType('password');
            }}
          >
            {newPasswordType === 'password' ? (
              <i className="fa-regular fa-eye-slash"></i>
            ) : (
              <i className="fa-regular fa-eye"></i>
            )}
          </InputGroup.Text>
          <Form.Control.Feedback id="bodyMessage" type="invalid">
            {t('sign_up.PasswordInvalid')}
          </Form.Control.Feedback>
        </InputGroup>
        <Form.Label className={`text-secondary ${styles.FormDescription}`}>
          {t('account_settings.password_settings.NewPasswordSub')}
        </Form.Label>
      </Form.Group>
      <Form.Group className="mb-3" controlId="formPasswordConfirmation">
        <Form.Label className="text-secondary">{t('sign_up.PasswordConfirmation')}</Form.Label>
        <InputGroup className={styles.MainInputGroup}>
          <Form.Control
            className={`${styles.FormControl} ${
              isDark || isDark == undefined ? 'bg-dark border-secondary text-white-50' : ''
            }`}
            onChange={handleConfirmPassword}
            type={confirmPasswordType}
            value={confirmPassword}
            required
            maxLength={128}
            isInvalid={!validConfirmation}
            autoComplete="off"
            onBlur={() => {
              handleCheckPasswordConfirmation(confirmPassword);
            }}
          />
          <InputGroup.Text
            className={`${styles.inputGroup} ${
              isDark || isDark == undefined ? 'bg-dark border-secondary text-white-50' : ''
            }`}
            onClick={() => {
              confirmPasswordType === 'password'
                ? setConfirmPasswordType('text')
                : setConfirmPasswordType('password');
            }}
          >
            {confirmPasswordType === 'password' ? (
              <i className="fa-regular fa-eye-slash"></i>
            ) : (
              <i className="fa-regular fa-eye"></i>
            )}
          </InputGroup.Text>
          <Form.Control.Feedback id="bodyMessage" type="invalid">
            {t('sign_up.DifferentPasswords')}
          </Form.Control.Feedback>
        </InputGroup>
        <Form.Label className={`text-secondary ${styles.FormDescription}`}>
          {t('account_settings.password_settings.ConfirmNewPasswordSub')}
        </Form.Label>
      </Form.Group>
    </>
  );
}

export default PasswordFields;
