import React, { useContext, useState } from 'react';
import FunctionDescription from './FunctionDescription';
import ActionEditor from './ActionEditor';
import { ActionsManifests } from '../../store/types/manifestsAndInputs';
import ParameterRelationshipEditor from '../editor/function_header/parameter_editor/parameter_relationship_editor';
import CrudTypeEditor from '../editor/function_header/crudtype_editor/crudtype_editor';
import Icon from 'web_ui/icon';
import { useSelector } from 'react-redux';
import { FunctionEditorState } from 'web_ui/function_editor/store/types/function_editor_state';
import HelpPopover from 'web_ui/workboard/sidebar/controls/components/Popover';
import { useTranslation } from 'react-i18next';
import { AvailableFrameworks } from '../editor/function_header/function_signatures';
import { AppContext } from '../../../../modules/project/store/app_context';

type EditActionToolbarProps = {
  manifests: ActionsManifests;
  framework?: AvailableFrameworks;
};

function EditActionToolbar({ manifests, framework = 'spring' }: EditActionToolbarProps) {
  const { t } = useTranslation();
  const [showInputs, setShowInputs] = useState(false);
  const params = useSelector((state: FunctionEditorState) => state.parameters);
  const function_id = useSelector((state: FunctionEditorState) => state.editor.functionId);
  const functions = useSelector((state: FunctionEditorState) => state.functions);
  const hasBackend = useContext(AppContext)?.projectInformation?.has_backend;

  const toggleShowInputs = () => {
    setShowInputs(!showInputs);
  };

  return (
    <div
      style={{
        padding: '0.5rem',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: '10px'
      }}
    >
      <FunctionDescription />

      {framework === 'spring' && (
        <>
          {hasBackend && (
            <button
              style={{
                display: 'flex',
                alignItems: 'center',
                fontSize: '1rem',
                backgroundColor: 'transparent',
                borderColor: 'transparent',
                justifyContent: 'space-between'
              }}
              onClick={toggleShowInputs}
            >
              CrudAssistant
              <Icon iconName={showInputs ? 'angle-up' : 'angle-down'} />
            </button>
          )}
          {showInputs && (
            <>
              <div style={{ marginTop: '10px' }}>
                <div style={{ marginBottom: '5px' }}>
                  CRUD Type{' '}
                  <HelpPopover
                    helpBoxProps={{
                      description: t('logicBuilder.CrudEditorTooltip') ?? ''
                    }}
                    placement="top"
                  >
                    <span>
                      <Icon padding={'10px'} brands={'regular'} iconName={'circle-question'} />
                    </span>
                  </HelpPopover>
                </div>
                <CrudTypeEditor />
              </div>
              {functions[function_id] &&
                functions[function_id].parameters.map((id) => (
                  <div key={id} style={{ marginTop: '10px' }}>
                    <div style={{ marginBottom: '5px' }}>
                      {params[id].name}
                      <HelpPopover
                        helpBoxProps={{
                          description: t('logicBuilder.RelationshipEditorTooltip') ?? ''
                        }}
                        placement="top"
                      >
                        <span>
                          <Icon padding={'10px'} brands={'regular'} iconName={'circle-question'} />
                        </span>
                      </HelpPopover>
                    </div>
                    <ParameterRelationshipEditor uuid={id} />
                  </div>
                ))}
            </>
          )}
          <hr />
        </>
      )}

      <ActionEditor manifests={manifests} />
    </div>
  );
}

export default EditActionToolbar;
