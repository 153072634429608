import React, { ReactNode } from 'react';
import styles from './styles.module.css';
import {
  LOOKUP_KEYS,
  SUBSCRIPTION_PLANS_FEATURES,
  SubscriptionPlanFeature
} from 'modules/payments/types';
import { useTranslation } from 'react-i18next';
import HelpPopover from 'web_ui/workboard/sidebar/controls/components/Popover';
import HelpIcon from '../../../../web_ui/workboard/sidebar/controls/components/HelpIcon';
import { Button } from 'react-bootstrap';

export type PricingTableProps = {
  onClick?: () => void;
  i18nKeyButtonLabel?: string;
  context: 'SETTINGS_PAGE' | 'WELCOME_PAGE';
};

const TEMP_PRICES: Record<string, string> = {
  free_plan: 'Free',
  basic_plan: '29€',
  starter_plan: '99€',
  professional_plan: '799€',
  enterprise_plan: 'Custom',
  free_plan_year: 'Free',
  basic_plan_year: '24€',
  starter_plan_year: '82€',
  professional_plan_year: '665€',
  enterprise_plan_year: 'Custom'
};

export function PricingTable(props: PricingTableProps) {
  const { t } = useTranslation();
  const subscribedTo = 'basic_plan';

  const getFeatureValue = (featureList: SubscriptionPlanFeature[], idx: number): ReactNode => {
    const feature = featureList[idx];
    if (feature.tooltip) {
      return <HelpIcon />;
    }
    if (feature.available) {
      if (feature.limit) {
        return <>{feature.limit}</>;
      } else {
        return <i className={`fa-solid fa-check ${styles.checkmarkIcon}`}></i>;
      }
    } else {
      return <i className={`fa-solid fa-times ${styles.timesIcon}`} style={{}}></i>;
    }
  };

  const getPopOverText = (featureList: SubscriptionPlanFeature[], idx: number): string => {
    const feature = featureList[idx];
    return feature.tooltip ?? '';
  };

  const shouldHaveLeftBorder = (plan: string) => {
    const plans = Object.keys(LOOKUP_KEYS);
    const planIdx = plans.findIndex((p) => p === plan);
    const userPlanIdx = plans.findIndex((p) => p === subscribedTo);
    if (userPlanIdx + 1 === planIdx) {
      return false;
    }
    return true;
  };

  const shouldHaveRightBorder = (plan: string) => {
    const plans = Object.keys(LOOKUP_KEYS);
    const planIdx = plans.findIndex((p) => p === plan);
    const userPlanIdx = plans.findIndex((p) => p === subscribedTo);
    if (userPlanIdx - 1 === planIdx) {
      return false;
    }
    return true;
  };

  const shouldHaveBoth = (plan: string) => {
    if (plan === subscribedTo) {
      return true;
    }
    const plans = Object.keys(LOOKUP_KEYS);
    const planIdx = plans.findIndex((p) => p === plan);
    const userPlanIdx = plans.findIndex((p) => p === subscribedTo);
    if (userPlanIdx - 1 !== planIdx && userPlanIdx + 1 !== planIdx) {
      return true;
    }
    return false;
  };

  const headerCellPlan = (plan: string) => {
    return (
      <div
        className={`${styles.header_CellContent} ${plan === subscribedTo && styles.cell_Emphasis}`}
      >
        <div className={`${styles.header_CellTitle} ${styles[plan + '_title']}`}>
          {LOOKUP_KEYS[plan]}
        </div>
      </div>
    );
  };

  return (
    <>
      <table className={`${styles.table}`}>
        {/* Invisible row at the top. */}
        <tr>
          <td className={`${styles.header_Cell}`}></td>
          {Object.keys(LOOKUP_KEYS).map((plan) => {
            const rows = plan === subscribedTo ? 2 : 1;
            return (
              <td
                key={plan}
                rowSpan={rows}
                className={`${styles.header_Cell} ${
                  plan === subscribedTo && styles[plan + '_column_header']
                }`}
              >
                {subscribedTo === plan && headerCellPlan(plan)}
              </td>
            );
          })}
        </tr>
        <tr>
          <td className={`${styles.header_Cell}`}></td>
          {Object.keys(LOOKUP_KEYS).map((plan) => {
            if (plan === subscribedTo) return null;
            return (
              <td
                className={`${styles.header_Cell} ${
                  shouldHaveLeftBorder(plan) && styles[plan + '_column_header_left']
                } ${shouldHaveRightBorder(plan) && styles[plan + '_column_header_right']} ${
                  shouldHaveBoth(plan) && styles[plan + '_column_header']
                }`}
                key={plan}
              >
                {headerCellPlan(plan)}
              </td>
            );
          })}
        </tr>
        {SUBSCRIPTION_PLANS_FEATURES.free_plan.map((_, idx) => {
          const featureName = SUBSCRIPTION_PLANS_FEATURES.free_plan[idx].name;
          const description = SUBSCRIPTION_PLANS_FEATURES.free_plan[idx].description;
          return (
            <tr key={idx}>
              <td className={`${styles.cell_Feature}`}>
                {featureName}

                {description && (
                  <HelpPopover
                    placement="right"
                    helpBoxProps={{
                      title: description
                    }}
                  >
                    <HelpIcon />
                  </HelpPopover>
                )}
              </td>
              {Object.keys(LOOKUP_KEYS).map((plan) => {
                const popOverText = getPopOverText(SUBSCRIPTION_PLANS_FEATURES[plan], idx);
                return (
                  <td
                    className={`${styles.cell_FeatureValue} ${
                      plan !== subscribedTo && styles.nonSubscribedColumn
                    } ${shouldHaveLeftBorder(plan) && styles[plan + '_column_left']} ${
                      shouldHaveRightBorder(plan) && styles[plan + '_column_right']
                    } ${shouldHaveBoth(plan) && styles[plan + '_column']}`}
                    key={plan}
                  >
                    <HelpPopover
                      placement="right"
                      helpBoxProps={{
                        title: popOverText
                      }}
                      disabled={!popOverText}
                    >
                      <div
                        className={`${styles.cell_FeatureValueInner} ${
                          plan === 'professional_plan' && styles.cell_Emphasis
                        }`}
                      >
                        {getFeatureValue(SUBSCRIPTION_PLANS_FEATURES[plan], idx)}
                      </div>
                    </HelpPopover>
                  </td>
                );
              })}
            </tr>
          );
        })}
        <tr>
          <td className={`${styles.cell_Feature}`}>{t('Price')}</td>
          {Object.keys(LOOKUP_KEYS).map((plan) => {
            const rowsSpan = subscribedTo === plan ? 2 : 1;
            return (
              <td
                className={`${styles.cell_FooterValue} ${
                  shouldHaveLeftBorder(plan) && styles[plan + '_column_footer_left']
                } ${shouldHaveRightBorder(plan) && styles[plan + '_column_footer_right']} ${
                  shouldHaveBoth(plan) && styles[plan + '_column_footer']
                }`}
                key={plan}
                rowSpan={rowsSpan}
              >
                <>
                  {plan === 'free_plan' && (
                    <>
                      <p>
                        <span className={`${styles.Pricing}`}>
                          <span className={styles.Price}>{TEMP_PRICES[plan]}</span>
                        </span>
                      </p>
                    </>
                  )}
                  {(plan === 'starter_plan' || plan === 'professional_plan') && (
                    <>
                      <p>
                        <span className={`${styles.Pricing}`}>
                          <span className={styles.Price}>{TEMP_PRICES[plan]}</span>
                          <span className={styles.PriceDescription1}> / month</span>
                        </span>
                      </p>
                      <p>
                        <span className={`${styles.Pricing}`}>
                          <span className={styles.Price}>{TEMP_PRICES[`${plan}_year`]}</span>
                          <span className={styles.PriceDescription1}> / year</span>
                        </span>
                      </p>
                    </>
                  )}
                  {plan === 'basic_plan' && (
                    <div className={`${plan === 'basic_plan' && styles.cell_Emphasis}`}>
                      <div className={`${styles.PricingWrapper}`}>
                        <p className={styles.Price}>Free</p>
                        <span className={styles.PriceDescription1}> for the first 3 months</span>
                      </div>
                      <div className={styles.PriceDescription2}>
                        29€/month or 24€/year thereafter
                      </div>
                    </div>
                  )}
                  {plan === 'enterprise_plan' && (
                    <div className={`${styles.cell_Emphasis}`}>
                      <Button
                        style={{ width: '100%' }}
                        variant="primary"
                        href="mailto:sales@exocoding.com"
                      >
                        {t('billing.ContactSales')}
                      </Button>
                    </div>
                  )}
                </>
              </td>
            );
          })}
        </tr>
        {/* Invisible row at the bottom. */}
        <tr>
          <td className={`${styles.cell_Feature}`}></td>
          {Object.keys(LOOKUP_KEYS).map((plan) => {
            if (plan === subscribedTo) return null;
            return <td key={plan} className={`${styles.cell_Feature}`}></td>;
          })}
        </tr>
      </table>
    </>
  );
}
