import { LayoutComponent } from '../../../../designer/types';
import { CardData, CardStyles } from './index';

export const CARD_COMPONENT_TEMPLATE: LayoutComponent<CardData, CardStyles> = {
  uuid: 'empty',
  type: 'CARD',
  data: {
    title: 'Header',
    width: 200,
    widthUnit: 'px',
    maxWidth: 'none',
    minWidth: 'none',
    height: 150,
    heightUnit: 'px',
    maxHeight: 'none',
    minHeight: 'none',
    header: true
  },
  styles: {
    themeColor: 'light',
    marginTop: 10,
    marginBottom: 10,
    marginLeft: 10,
    marginRight: 10,
    borderTop: 0,
    borderRight: 0,
    borderBottom: 0,
    borderLeft: 0,
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: 0,
    css: ''
  },
  events: {}
};
