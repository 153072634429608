import React, { useCallback, useContext, useEffect, useState } from 'react';
import styles from './styles.module.css';
import { Button, Col, Form, Modal, Row, Tab, Tabs } from 'react-bootstrap';
import { EndpointExtended, METHODS, Method } from 'modules/logic_builder/types';
import { LogicTab } from './logic_tab';
import { SecurityTab } from './security_tab';
import { OtherTab } from './other_tab';
import { EndpointInstance } from '../../endpoints_list/endpoint_instance';
import { EndpointsService } from 'modules/logic_builder/services';
import { validatePath } from '../controller_creator_dialog';
import LogicBuilderContext from 'modules/logic_builder/store';
import Confirmation from 'web_ui/confirmation';
import { useTranslation } from 'react-i18next';
import { endpointNameRegex } from 'utils/regex';

type EndpointEditorDialogProps = {
  show: boolean;
  onClose: () => void;
  endpointId: string;
};

export function EndpointEditorDialog(props: EndpointEditorDialogProps) {
  const [endpointExtended, setEndpointExtended] = useState<EndpointExtended>();
  const [selectedTab, setSelectedTab] = useState('logic');
  const [isNameInvalid, setIsNameInvalid] = useState(false);
  const [isPathInvalid, setIsPathInvalid] = useState(false);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const { module_id, fetchEndpoints } = useContext(LogicBuilderContext);
  const [isNative, setIsNative] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  const fetchEndpointExtended = useCallback(async () => {
    await EndpointsService.getEndpoint(props.endpointId).then((fetchedEndpoint) => {
      setEndpointExtended(fetchedEndpoint);
      setIsNative(fetchedEndpoint?.native ?? false);
      setIsPathInvalid(validatePath(fetchedEndpoint.path));
      setIsNameInvalid(!endpointNameRegex.test(fetchedEndpoint.name));
    });
  }, [props.endpointId]);

  useEffect(() => {
    if (!props.show) return;

    fetchEndpointExtended();
    setIsNameInvalid(false);
    setIsPathInvalid(false);
    setSelectedTab('logic');
  }, [fetchEndpointExtended, props.show]);

  async function onDelete() {
    if (isLoading) return;
    if (!module_id || !endpointExtended) return;

    setIsLoading(true);
    await EndpointsService.deleteEndpoint(endpointExtended.uuid)
      .then(() => {
        fetchEndpoints(module_id);
        setShowConfirmationDialog(false);
        props.onClose();
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  async function onSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    if (isLoading) return;
    if (isPathInvalid || isNameInvalid) return;
    if (!module_id || !endpointExtended) return;

    setIsLoading(true);
    await EndpointsService.updateEndpoint(endpointExtended.uuid, endpointExtended)
      .then(() => {
        fetchEndpoints(module_id);
        props.onClose();
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  return (
    <>
      <Modal show={props.show} onHide={props.onClose} size="xl" centered scrollable>
        <Form onSubmit={onSubmit} className={styles.MainWrapperForm}>
          <Modal.Header closeButton>
            <Modal.Title>{t('logicBuilder.EndpointEditor')}</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ overflowY: 'auto' }}>
            {/* Endpoint */}
            {endpointExtended && (
              <EndpointInstance
                endpoint={endpointExtended}
                className={styles.Gap}
                showEndpointEditorOnClick={false}
                showEditBtn={false}
              />
            )}

            {/* Form */}

            <Row>
              <Col sm={4}>
                <Form.Group as={Row} className="mb-3" controlId="formEndpointMethod">
                  <Form.Label column sm={4}>
                    {t('logicBuilder.Method')}
                  </Form.Label>
                  <Col sm={8}>
                    <Form.Select
                      value={endpointExtended?.method ?? METHODS.GET}
                      disabled={isNative}
                      onChange={(e) =>
                        setEndpointExtended({
                          ...endpointExtended,
                          method: e.target.value as Method
                        } as EndpointExtended)
                      }
                      required
                    >
                      {Object.keys(METHODS).map((method) => {
                        return (
                          <option key={method} value={method}>
                            {METHODS[method as Method]}
                          </option>
                        );
                      })}
                    </Form.Select>
                  </Col>
                </Form.Group>
              </Col>
              <Col sm={8}>
                <Form.Group as={Row} className="mb-3" controlId="formEndpointMethod">
                  <Form.Label column sm={2}>
                    {t('logicBuilder.Name')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      type="text"
                      placeholder={`${t('logicBuilder.Name')}`}
                      value={endpointExtended?.name ?? ''}
                      disabled={isNative}
                      isInvalid={isNameInvalid}
                      maxLength={56}
                      onChange={(e) => {
                        setEndpointExtended({
                          ...endpointExtended,
                          name: e.target.value
                        } as EndpointExtended);
                        setIsNameInvalid(!endpointNameRegex.test(e.target.value));
                      }}
                      required
                    />
                    <Form.Control.Feedback type={'invalid'}>
                      {endpointExtended?.name.length === 0 && t('RequiredField')}
                      {endpointExtended?.name.length !== 0 && t('InvalidField')}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
              </Col>
            </Row>

            <Form.Group as={Row} className="mb-3" controlId="formEndpointSummary">
              <Form.Label column sm={2}>
                {t('logicBuilder.Summary')}
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  type="text"
                  placeholder={`${t('logicBuilder.Summary')}`}
                  value={endpointExtended?.summary ?? ''}
                  disabled={isNative}
                  maxLength={120}
                  onChange={(e) =>
                    setEndpointExtended({
                      ...endpointExtended,
                      summary: e.target.value
                    } as EndpointExtended)
                  }
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3" controlId="formEndpointPath">
              <Form.Label column sm={2}>
                {t('logicBuilder.Path')}
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  type="text"
                  placeholder={`/${t('logicBuilder.Path')}`}
                  value={endpointExtended?.path ?? ''}
                  isInvalid={isPathInvalid}
                  disabled={isNative}
                  maxLength={150}
                  onChange={(e) => {
                    setEndpointExtended({
                      ...endpointExtended,
                      path: e.target.value
                    } as EndpointExtended);
                    setIsPathInvalid(validatePath(e.target.value));
                  }}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  {t('logicBuilder.ValidPath')}
                </Form.Control.Feedback>
              </Col>
            </Form.Group>

            {/* Logic/Security/Other/Examples tabs */}
            <div className={styles.TabsWrapper}>
              <Tabs
                id="endpointsEditorTabs"
                activeKey={selectedTab}
                onSelect={(k) => setSelectedTab(k ?? 'logic')}
              >
                <Tab
                  eventKey="logic"
                  title={`${t('logicBuilder.Logic')}`}
                  className={styles.TabWrapper}
                >
                  {endpointExtended && <LogicTab endpointExtended={endpointExtended} />}
                </Tab>
                <Tab
                  eventKey="security"
                  title={`${t('logicBuilder.Security')}`}
                  className={styles.TabWrapper}
                >
                  {endpointExtended && (
                    <SecurityTab
                      endpointExtended={endpointExtended}
                      setEndpointExtended={(ee) => setEndpointExtended(ee)}
                    />
                  )}
                </Tab>
                <Tab
                  eventKey="other"
                  title={`${t('logicBuilder.Other')}`}
                  className={styles.TabWrapper}
                >
                  {endpointExtended && (
                    <OtherTab
                      endpointExtended={endpointExtended}
                      setEndpointExtended={(ee) => setEndpointExtended(ee)}
                    />
                  )}
                </Tab>
              </Tabs>
            </div>
          </Modal.Body>
          {/* Footer buttons */}
          <Modal.Footer>
            <Button
              id={'deleteButton'}
              variant="danger"
              onClick={() => setShowConfirmationDialog(true)}
              disabled={isNative}
            >
              {t('logicBuilder.createEndpointDialog.delete')}
            </Button>
            <Button id={'cancelButton'} variant="secondary" onClick={() => props.onClose()}>
              {t('logicBuilder.createEndpointDialog.cancel')}
            </Button>
            <Button
              id={'saveButton'}
              variant="primary"
              type="submit"
              disabled={isNameInvalid || isPathInvalid || isNative}
            >
              {t('logicBuilder.createEndpointDialog.save')}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
      <Confirmation
        show={showConfirmationDialog}
        message={`${t('deleteQuotes.endpoint')} (${endpointExtended?.name ?? ''})`}
        onConfirmation={onDelete}
        onCancel={() => setShowConfirmationDialog(false)}
        onClose={() => setShowConfirmationDialog(false)}
      />
    </>
  );
}
