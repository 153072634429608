import { ButtonData, ButtonStyles } from './index';
import { LayoutComponent } from '../../../../designer/types';

export const BUTTON_TEMPLATE: LayoutComponent<ButtonData, ButtonStyles> = {
  uuid: 'empty',
  type: 'BUTTON',
  data: {
    text: 'OK',
    width: 80,
    widthUnit: 'px',
    maxWidth: 'none',
    minWidth: 'none',
    height: 30,
    heightUnit: 'px',
    maxHeight: 'none',
    minHeight: 'none'
  },
  styles: {
    themeColor: 'primary',
    paddingTop: 0,
    paddingRight: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    borderTop: 0,
    borderRight: 0,
    borderBottom: 0,
    borderLeft: 0,
    marginTop: 10,
    marginRight: 10,
    marginBottom: 10,
    marginLeft: 10,
    css: ''
  },
  events: {}
};
