import React from 'react';
import { AuthService } from 'modules/auth/services';
import SessionContext from 'modules/auth/store';
import { UserProfile } from 'modules/auth/types/auth_types';
import { Button, Container, Form } from 'react-bootstrap';

import styles from './styles.module.css';
import { useTranslation } from 'react-i18next';

type UserInfoFormProps = {
  user: UserProfile;
  clearState: boolean;
  next?: () => void;
};

function UserInfoForm(props: UserInfoFormProps) {
  const [isLoading, setIsLoading] = React.useState(false);
  const session = React.useContext(SessionContext);
  const [name, setName] = React.useState(session.user?.name ? session.user?.name : '');

  const { t } = useTranslation();
  /**
   * Used to submit the update user information.
   *
   * @param event
   */
  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setIsLoading(true);

    try {
      const user = await AuthService.updateUserInfo(props.user.id, name);
      session.setUser(user);
      setIsLoading(false);
      if (props.clearState) window.history.replaceState({}, document.title);
      if (props.next) props.next();
    } catch (error) {
      alert(error);
      setIsLoading(false);
    }
  };

  return (
    <Form className={styles.Form} onSubmit={handleSubmit}>
      <Form.Group className={styles.FormSection} controlId="formName">
        <Form.Label className={`text-body-primary ${styles.FormTitle}`}>{t('Name')}</Form.Label>
        <Form.Control
          className={styles.FormControl}
          onChange={(e) => {
            setName(e.target.value);
          }}
          type="text"
          value={name}
          disabled={isLoading}
          maxLength={256}
          style={{ width: '28rem' }}
        />
        <Form.Label className={`text-body-tertiary ${styles.FormDescription}`}>
          {t('account_settings.account_info.NameSub')}
        </Form.Label>
      </Form.Group>
      <Container className={styles.SubmitButton}>
        <Button id={'saveButton-1'} variant="primary" type="submit" disabled={isLoading}>
          {t('navigation_options.Submit')}
        </Button>
      </Container>
    </Form>
  );
}

export default UserInfoForm;
