import React, { useCallback, useEffect, useState } from 'react';
import { Button, Form, ListGroup, Modal, Spinner } from 'react-bootstrap';
import { CrudInputEndpoint, FunctionParameterCrud } from 'modules/logic_builder/types';
import { useTranslation } from 'react-i18next';
import { CrudAutomationService, FunctionService } from 'modules/logic_builder/services';
import styles from './styles.module.css';
import { CrudData } from '..';
import { validatePath } from 'modules/logic_builder/components/dialogs/controller_creator_dialog';

const EndpointMethods = {
  get: 'Get',
  post: 'Post',
  put: 'Put',
  delete: 'Delete'
};
type EndpointMethod = keyof typeof EndpointMethods;

type EndpointMethodDetail = {
  task: boolean;
  single: boolean;
  multi: boolean;
  multiId: boolean;
  protected: boolean;
};

type SwitchSelections = Record<EndpointMethod, EndpointMethodDetail>;

type AddEndpointsDialogProps = {
  crudData: CrudData;
  serviceUuid: string;
  show: boolean;
  onClose: () => void;
  moduleId: string;
  onChange: (data: CrudData) => void;
  loading?: (val: boolean) => void;
};

export function AddEndpointsDialog(props: AddEndpointsDialogProps) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedObject, setSelectedObject] = useState<string | undefined>();
  const [selectedService, setSelectedService] = useState<string | undefined>();
  const [pathPrefix, setPathPrefix] = useState('');
  const [multipleState, setMultipleState] = useState<{
    create: boolean;
    read: boolean;
    update: boolean;
    delete: boolean;
  }>({ create: false, delete: false, read: false, update: false });
  const [oneState, setOneState] = useState<{
    create: boolean;
    read: boolean;
    update: boolean;
    delete: boolean;
  }>({ create: false, delete: false, read: false, update: false });
  const [multipleIdState, setMultipleIdState] = useState<{
    create: boolean;
    read: boolean;
    update: boolean;
    delete: boolean;
  }>({ create: false, delete: false, read: false, update: false });
  const [isInvalid, setIsInvalid] = useState(false);
  const [switchSelections, setSwitchSelections] = useState<SwitchSelections>();
  const [errorMessages, setErrorMessages] = useState<Record<string, string>>({});

  const currEntityName = props.crudData.entities[props.crudData.entityUuid ?? '']?.entityName;

  useEffect(() => {
    if (!props.show) return;
    if (props.crudData.selectedService === '') {
      getServicesList();
    }
  }, []);

  useEffect(() => {
    setSelectedService(props.crudData.selectedService ?? props.crudData.services[0].uuid);
    setSelectedObject(props.crudData.selectedObject ?? props.crudData.objects[0].uuid);
  }, [props.crudData.selectedService]);

  useEffect(() => {
    if (!props.show || !switchSelections) return;

    const isAnySwitchSelected = Object.values(switchSelections).find((s) => {
      return s.multi || s.single || s.multiId;
    });
    setIsInvalid(isAnySwitchSelected == null);
  }, [props.show, switchSelections]);

  useEffect(() => {
    if (!selectedObject || !selectedService) return;
    setErrorMessages({});
    loadPatch();
  }, [selectedObject, selectedService]);

  async function getServicesList() {
    if (!props.moduleId || !props.crudData.entityUuid) return;
    setIsLoading(true);

    props.loading && props.loading(true);
    const serviceList = await CrudAutomationService.getEntityServiceResume(
      props.moduleId,
      props.crudData.entityUuid
    );
    const crud = {
      ...props.crudData,
      services: serviceList
    } as CrudData;
    if (serviceList[0] && serviceList[0].uuid) {
      crud.selectedService = serviceList[0].uuid;
    }
    props.onChange(crud);
    setIsLoading(false);
    props.loading && props.loading(false);
  }

  async function loadPatch() {
    if (!props.moduleId || !props.crudData.entityUuid) return;
    setIsLoading(true);

    props.loading && props.loading(true);
    // Query every possible combination to find which ones should be available.
    const crudInputEndpoint: CrudInputEndpoint = {
      objectUuid: selectedObject,
      serviceUuid: selectedService,
      post: {
        single: true,
        multi: true,
        secure: true
      },
      get: {
        single: true,
        multi: true,
        secure: true
      },
      put: {
        single: true,
        multi: true,
        secure: true
      },
      delete: {
        single: true,
        multi: true,
        multiId: true,
        secure: true
      }
    };
    let endpoints = await CrudAutomationService.getEndpointsPrototypes(
      props.moduleId,
      props.crudData.entityUuid,
      crudInputEndpoint
    );
    endpoints = endpoints.filter((endpoint) => endpoint.action);
    const one = {
      create: endpoints.find((f) => f.crudType === 'CREATE_ONE') !== undefined,
      delete: endpoints.find((f) => f.crudType === 'DELETE_ONE') !== undefined,
      read: endpoints.find((f) => f.crudType === 'READ_ONE') !== undefined,
      update: endpoints.find((f) => f.crudType === 'UPDATE_ONE') !== undefined
    };
    const multi = {
      create: endpoints.find((f) => f.crudType === 'CREATE_MANY') !== undefined,
      delete: endpoints.find((f) => f.crudType === 'DELETE_MANY') !== undefined,
      read: endpoints.find((f) => f.crudType === 'READ_MANY') !== undefined,
      update: endpoints.find((f) => f.crudType === 'UPDATE_MANY') !== undefined
    };
    const multiId = {
      create: false,
      delete: endpoints.find((f) => f.crudType === 'DELETE_MANY_ID') !== undefined,
      read: false,
      update: false
    };
    setOneState(one);
    setMultipleState(multi);
    setMultipleIdState(multiId);
    setSwitchSelections({
      get: {
        task: one.read || multi.read,
        single: one.read,
        multi: multi.read,
        multiId: multiId.read,
        protected: one.read || multi.read || multiId.read
      },
      post: {
        task: one.create || multi.create,
        single: one.create,
        multi: multi.create,
        multiId: multiId.create,
        protected: one.create || multi.create || multiId.create
      },
      put: {
        task: one.update || multi.update,
        single: one.update,
        multi: multi.update,
        multiId: multiId.update,
        protected: one.update || multi.update || multiId.update
      },
      delete: {
        task: one.delete || multi.delete || multiId.delete,
        single: one.delete,
        multi: multi.delete,
        multiId: multiId.delete,
        protected: one.delete || multi.delete || multiId.delete
      }
    });
    setIsLoading(false);
    props.loading && props.loading(false);
  }

  async function loadFunctionsAndEndpoints() {
    if (!props.moduleId || !props.crudData.entityUuid || !switchSelections) return;
    setIsLoading(true);

    let crudFunctions = undefined;
    if (selectedService && selectedService !== props.crudData.functions.at(0)?.serviceUuid) {
      // Get functions of the selected service
      crudFunctions = await FunctionService.getFunctionsByService(
        selectedService,
        props.moduleId
      ).then((functionsList) => {
        functionsList.forEach((func) => {
          // it needs to be true if it's false will not be show in summary
          func.isNew = true;
        });
        // Get function parameters
        functionsList.forEach(async (func) => {
          const currentParameters = await FunctionService.getParameters(func.uuid);
          func.parameters = currentParameters as FunctionParameterCrud[];
        });
        return functionsList;
      });
      setIsLoading(false);
    }

    const crudInputEndpoint: CrudInputEndpoint = {
      objectUuid: selectedObject,
      serviceUuid: selectedService,
      post: {
        single: switchSelections.post.single,
        multi: switchSelections.post.multi,
        multiId: switchSelections.post.multiId,
        secure: switchSelections.post.protected
      },
      get: {
        single: switchSelections.get.single,
        multi: switchSelections.get.multi,
        multiId: switchSelections.get.multiId,
        secure: switchSelections.get.protected
      },
      put: {
        single: switchSelections.put.single,
        multi: switchSelections.put.multi,
        multiId: switchSelections.put.multiId,
        secure: switchSelections.put.protected
      },
      delete: {
        single: switchSelections.delete.single,
        multi: switchSelections.delete.multi,
        multiId: switchSelections.delete.multiId,
        secure: switchSelections.delete.protected
      }
    };

    // Get endpoint prototypes
    const crudEndpoints = await CrudAutomationService.getEndpointsPrototypes(
      props.moduleId,
      props.crudData.entityUuid,
      crudInputEndpoint
    ).then((crudEndpointList) => {
      setIsLoading(true);
      crudEndpointList.forEach((item) => {
        item.isNew = true;
        if (props.crudData.selectedController) {
          item.controllerUuid = props.crudData.selectedController.uuid ?? '';
        }
        if (pathPrefix.length > 1) {
          item.path = pathPrefix + item.path;
        }
      });
      return crudEndpointList;
    });
    // Combine results + update crudData
    const newCrud = {
      ...props.crudData,
      functions: crudFunctions ?? props.crudData.functions,
      endpoints: [...props.crudData.endpoints, ...crudEndpoints]
    };
    props.onChange(newCrud);
    setIsLoading(false);
  }

  async function onSubmit() {
    if (isLoading) return;
    if (!validateInputs()) {
      setIsLoading(false);
      return;
    }
    setIsLoading(true);
    props.loading && props.loading(true);
    await loadFunctionsAndEndpoints().finally(() => {
      setIsLoading(false);
      props.loading && props.loading(false);
    });
    props.onClose();
  }

  const handleChangePathPrefix = (value: string) => {
    const errorMessage = validatePrefixPathInput(value);
    const nextErrMessages = {
      ...errorMessages,
      pathPrefix: errorMessage
    };
    setErrorMessages(nextErrMessages);
    setPathPrefix(value);
  };

  // Return the error message.
  const validatePrefixPathInput = useCallback((input: string): string => {
    const checkInput = !validatePath(input);
    if (checkInput) {
      return '';
    }
    return 'inputValidationErrorMessages.GenericErrorMessage';
  }, []);

  const handleSelectionsProperty = useCallback(
    (
      endpointMethod: EndpointMethod,
      task: boolean,
      requestOne: boolean,
      requestMulti: boolean,
      requestMultiId: boolean,
      protectedEndpoint: boolean
    ) => {
      if (!switchSelections) {
        return;
      }
      const nextSelectionsMethod: EndpointMethodDetail = {
        ...switchSelections[endpointMethod],
        task: task,
        single: requestOne,
        multi: requestMulti,
        multiId: requestMultiId,
        protected: protectedEndpoint
      };
      const nextSelections = {
        ...switchSelections,
        [endpointMethod]: nextSelectionsMethod
      };
      setSwitchSelections(nextSelections);
    },
    [switchSelections]
  );

  /**
   * Validate every input that needs validation.
   * Returns false if one them isn't valid.
   */
  const validateInputs = (): boolean => {
    const errorMessage = validatePrefixPathInput(pathPrefix);
    const nextErrorMessages = {
      ...errorMessages,
      pathPrefix: errorMessage
    };
    setErrorMessages(nextErrorMessages);
    return errorMessage === '';
  };

  return (
    <Modal
      show={props.show}
      onHide={() => {
        props.onClose();
      }}
      centered
    >
      <Modal.Header closeButton id="formModal">
        <Modal.Title>{t('automation.step4.AddEndpoint')}</Modal.Title>
      </Modal.Header>
      <Modal.Body id="bodyModal">
        {isLoading ? (
          <div className="d-flex justify-content-center">
            <Spinner animation="border" variant="secondary" />
          </div>
        ) : (
          <>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: '1rem',
                alignItems: 'center'
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '0.5rem'
                }}
              >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {t('automation.step4.Class')}
                </div>
                <Form.Select
                  id="selectService"
                  onChange={(e) => setSelectedService(e.target.value)}
                  value={selectedService}
                >
                  {props.crudData.services &&
                    Object.values(props.crudData.services).map((service) => {
                      return (
                        <option key={service.name} value={service.uuid}>
                          {service.name}
                        </option>
                      );
                    })}
                </Form.Select>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '0.5rem'
                }}
              >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {t('automation.step4.DataObject')}
                </div>
                <Form.Select
                  id="selectObject"
                  onChange={(e) => setSelectedObject(e.target.value)}
                  value={selectedObject}
                >
                  {props.crudData.objects &&
                    Object.values(props.crudData.objects).map((object) => {
                      return (
                        <option key={object.name} value={object.uuid}>
                          {object.name}
                        </option>
                      );
                    })}
                </Form.Select>
              </div>
            </div>
            <div>
              <Form.Group className="mb-2 mt-2">
                <Form.Label>Path prefix</Form.Label>
                <Form.Control
                  id="pathPrefix"
                  placeholder="/path"
                  onChange={(e) => handleChangePathPrefix(e.target.value)}
                  type="text"
                  value={pathPrefix}
                  isInvalid={!!errorMessages.pathPrefix}
                  maxLength={255}
                />
                <Form.Control.Feedback type={'invalid'}>
                  {t(errorMessages.pathPrefix)}
                </Form.Control.Feedback>
              </Form.Group>
            </div>
            <Form>
              <ListGroup>
                <ListGroup.Item className={styles.switchGroupItem}>
                  <span className={styles.rowNameItem} />
                  <div className={styles.rowSwitchItem}>One</div>
                  <div className={styles.rowSwitchItem}>Mult. Id</div>
                  <div className={styles.rowSwitchItem}>Multiple</div>
                  <div className={styles.rowSwitchItem}>Protected</div>
                </ListGroup.Item>
                {switchSelections &&
                  Object.keys(EndpointMethods).map((method) => {
                    const typedMethod = method as EndpointMethod;
                    const current = switchSelections[typedMethod];
                    let available: { one: boolean; multi: boolean; multiId: boolean } = {
                      one: false,
                      multi: false,
                      multiId: false
                    };
                    switch (typedMethod) {
                      case 'get':
                        available = {
                          one: oneState.read,
                          multi: multipleState.read,
                          multiId: multipleIdState.read
                        };
                        break;
                      case 'put':
                        available = {
                          one: oneState.update,
                          multi: multipleState.update,
                          multiId: multipleIdState.update
                        };
                        break;
                      case 'post':
                        available = {
                          one: oneState.create,
                          multi: multipleState.create,
                          multiId: multipleIdState.create
                        };
                        break;
                      case 'delete':
                        available = {
                          one: oneState.delete,
                          multi: multipleState.delete,
                          multiId: multipleIdState.delete
                        };
                        break;
                    }
                    return (
                      <div key={method}>
                        <ListGroup.Item className={styles.switchGroupItem}>
                          <Form.Label
                            className={styles.rowNameItem}
                            style={{
                              maxWidth: '97%',
                              textOverflow: 'ellipsis',
                              overflow: 'hidden'
                            }}
                          >
                            {method.toUpperCase()} {currEntityName}
                          </Form.Label>
                          <Form.Check
                            className={styles.rowSwitchItem}
                            type="switch"
                            id={`${method}-task-single`}
                            checked={current.single}
                            disabled={!available.one}
                            onChange={(e) =>
                              handleSelectionsProperty(
                                typedMethod,
                                e.target.checked,
                                e.target.checked,
                                current.multi,
                                current.multiId,
                                !e.target.checked && !current.multi
                                  ? e.target.checked
                                  : current.protected
                              )
                            }
                          />
                          {method !== 'delete' && <span className={styles.rowSwitchItem} />}
                          {method === 'delete' && (
                            <Form.Check
                              className={styles.rowSwitchItem}
                              type="switch"
                              id={`${method}-task-multi-id`}
                              checked={current.multiId}
                              disabled={!available.multiId}
                              onChange={(e) => {
                                handleSelectionsProperty(
                                  typedMethod,
                                  e.target.checked,
                                  current.single,
                                  current.multi,
                                  e.target.checked,
                                  !e.target.checked && !current.single && !current.multi
                                    ? e.target.checked
                                    : current.protected
                                );
                              }}
                            />
                          )}
                          <Form.Check
                            className={styles.rowSwitchItem}
                            type="switch"
                            id={`${method}-task-multi`}
                            checked={current.multi}
                            disabled={!available.multi}
                            onChange={(e) => {
                              handleSelectionsProperty(
                                typedMethod,
                                e.target.checked,
                                current.single,
                                e.target.checked,
                                current.multiId,
                                !e.target.checked && !current.single
                                  ? e.target.checked
                                  : current.protected
                              );
                            }}
                          />
                          <Form.Check
                            className={styles.rowSwitchItem}
                            id={`${method}-task-protected`}
                            checked={switchSelections[typedMethod].protected}
                            disabled={!available.one && !available.multi && !available.multiId}
                            onChange={(e) =>
                              handleSelectionsProperty(
                                typedMethod,
                                current.task,
                                current.single,
                                current.multi,
                                current.multiId,
                                current.single || current.multi || current.multiId
                                  ? e.target.checked
                                  : false
                              )
                            }
                          />
                        </ListGroup.Item>
                      </div>
                    );
                  })}
              </ListGroup>
            </Form>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button id="cancelButton" variant="secondary" onClick={() => props.onClose()}>
          {t('Cancel')}
        </Button>
        <Button
          id="saveButton"
          variant="primary"
          type="submit"
          disabled={isInvalid}
          onClick={onSubmit}
        >
          {t('Save')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
