import { ProgressBarData, ProgressBarStyles } from './index';
import { LayoutComponent } from '../../../../designer/types';

export const PROGRESS_BAR_TEMPLATE: LayoutComponent<ProgressBarData, ProgressBarStyles> = {
  uuid: 'empty',
  type: 'PROGRESSBAR',
  data: {
    heightUnit: 'px',
    height: 15,
    widthUnit: '%',
    width: 100,
    widthCalc: 20,
    value: 70,
    isAnimated: true,
    hasLabel: true
  },
  styles: {
    background: 'success',
    marginTop: 10,
    marginRight: 10,
    marginBottom: 10,
    marginLeft: 10,
    borderTop: 0,
    borderRight: 0,
    borderBottom: 0,
    borderLeft: 0,
    paddingTop: 0,
    paddingRight: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    css: ''
  }
};
