import React, { ComponentType, useState } from 'react';
import styles from './styles.module.css';
import HeaderOptions from './header_options';
import HeaderReturn from './header_return';
import { useSelector } from 'react-redux';
import { EditorPanel } from './editor_panel';
import useFetchTypes from '../../../action_inputs/utils/useFetchTypes';
import {
  AvailableFrameworks,
  FunctionSignature,
  FunctionSignatureProps
} from './function_signatures';
import HeaderSignatureCheck from './header_signature_check';
import { FunctionEditorState } from '../../../store/types/function_editor_state';

type FunctionHeaderProps = {
  types: string[];
  framework?: AvailableFrameworks;
};

function FunctionHeader({ types, framework = 'spring' }: FunctionHeaderProps) {
  const function_id = useSelector((state: FunctionEditorState) => state.editor.functionId);
  const [edit, setEdit] = useState(false);
  const { fetchedTypes } = useFetchTypes(types, framework);

  const Signature = FunctionSignature[framework] as ComponentType<FunctionSignatureProps>;

  return (
    <>
      <div className={`border-primary ${styles.FunctionSignatureWrapper}`} id="functionHeader">
        <div style={{ display: 'flex', gap: '0.2 rem' }}>
          <HeaderReturn />
          {framework === 'spring' && <HeaderSignatureCheck functionId={function_id} />}
          <div style={{ paddingLeft: '8px', paddingTop: '6px' }}>
            <Signature types={fetchedTypes} />
          </div>
        </div>
        <HeaderOptions edit={edit} setEdit={setEdit} />
      </div>
      <div className={`border-primary ${styles.HeaderEditorWrapper}`}>
        {edit && (
          <EditorPanel.Root>
            <EditorPanel.ReturnTypeAndName types={fetchedTypes} framework={framework} />
            <EditorPanel.ParametersList types={fetchedTypes} />
            {framework === 'spring' && <EditorPanel.Options />}
          </EditorPanel.Root>
        )}
      </div>
      <hr />
    </>
  );
}

export default FunctionHeader;
