import React, { useCallback, useContext, useState } from 'react';
import SessionContext from 'modules/auth/store';
import UserInfoForm from 'modules/auth/components/user_info';
import PasswordChangeForm from 'modules/auth/components/password_change_form';
import styles from './styles.module.css';
import { Button, Col, ProgressBar } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FaGithub } from 'react-icons/all';
import { IntegrationsService } from '../../../../modules/integrations/services';
import Confirmation from '../../../../web_ui/confirmation';
import HelpPopover from 'web_ui/workboard/sidebar/controls/components/Popover';
import ChangeEmailForm from '../../../../modules/auth/components/change_email_form';
import DeleteAccountForm from '../../../../modules/auth/components/delete_account_form';
import useTitle from 'hooks/useTitle';
import OpenAiForm from 'modules/auth/components/integrations/components/open_ai_form';
import Icon from 'web_ui/icon';
import {
  SystemRoleAuthority,
  SystemRoleAuthorityName,
  UserProfile
} from 'modules/auth/types/auth_types';
import { byteConverter } from 'utils/utils';

export const API_URL = process.env.REACT_APP_API_URL;

const handleGetAIUsageLimit = (user?: UserProfile) => {
  const openAIRole = user?.systemRoles[0]?.systemRoleAuthorities.find((role) => {
    return role.name === SystemRoleAuthorityName.AI_TOKEN_LIMIT;
  });

  return {
    usage: user?.totals?.numberOfUsedAiToken,
    limit: openAIRole?.subscriptionPlanLimits?.featureLimit
  };
};

function Account() {
  const session = useContext(SessionContext);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const getAIUsageLimit = useCallback(() => handleGetAIUsageLimit(session.user!), [session.user]);
  const { usage, limit } = getAIUsageLimit();
  const { t } = useTranslation();

  useTitle(t('tab.settingsAcc.acc'));

  async function uninstallGitHub() {
    await IntegrationsService.uninstallFromUser();
    session.reloadUser();
    setShowConfirmationDialog(false);
  }

  const renderingCorrectNumberDone = (authority: string) => {
    if (!session.user) return '';
    switch (authority) {
      case 'ADD_APP':
        return session.user.totals.numberOfCreatedApplications;
      case 'ADD_USER':
        return session.user.totals.numberOfInvitedUsers;
      case 'UPLOAD_ASSETS':
        return session.user.totals.storageUsed;
    }
  };

  const filteringAuthorities = () => {
    if (
      session.user &&
      session.user.systemRoles[0] &&
      session.user.systemRoles[0].systemRoleAuthorities
    ) {
      return session.user.systemRoles[0].systemRoleAuthorities.filter(
        (item) =>
          item.name === 'ADD_APP' || item.name === 'ADD_USER' || item.name === 'UPLOAD_ASSETS'
      );
    }
    return [];
  };

  const renderingCorrectIcon = (authority: string) => {
    switch (authority) {
      case 'ADD_APP':
        return <Icon iconName="folder-tree" extraProps="me-2" />;
      case 'ADD_USER':
        return <Icon iconName="user" extraProps="me-2" />;
      case 'UPLOAD_ASSETS':
        return <Icon iconName="bucket" extraProps="me-2" />;
    }
  };

  const calculatingPercentStorage = (item: SystemRoleAuthority) => {
    return session.user
      ? (session.user.totals.storageUsed / item.subscriptionPlanLimits.featureLimit) * 100
      : 0;
  };

  const showingSizes = (item: SystemRoleAuthority): string[] => {
    if (!session.user) return [];
    const initial =
      parseInt(byteConverter(session.user.totals.storageUsed, 2, 'MB').replace(/\D/g, '')) > 1024
        ? byteConverter(session.user.totals.storageUsed, 2)
        : byteConverter(session.user.totals.storageUsed, 2, 'MB');

    const final =
      parseInt(
        byteConverter(item.subscriptionPlanLimits.featureLimit, 2, 'MB').replace(/\D/g, '')
      ) > 1024
        ? byteConverter(item.subscriptionPlanLimits.featureLimit, 2)
        : byteConverter(item.subscriptionPlanLimits.featureLimit, 2, 'MB');

    return [initial, final];
  };

  return (
    <>
      <Col className={styles.Section}>
        <div className={`border-bottom ${styles.SectionTitleDiv}`}>
          <span className={`text-body-emphasis ${styles.SectionTitle}`}>
            {t('account_settings.account_info.EditProfile')}
          </span>
        </div>
        <div className={styles.FormContent}>
          {session.user && <UserInfoForm user={session.user} clearState={false} />}
        </div>
      </Col>

      <Col className={styles.Section}>
        <div className={`border-bottom ${styles.SectionTitleDiv}`}>
          <span className={`text-body-emphasis ${styles.SectionTitle}`}>
            {t('appResume.Integrations')}
          </span>
        </div>
        <div className={styles.FormContent}>
          <div className={styles.Section}>
            {session.user?.gitHubIntegration ? (
              <>
                <HelpPopover
                  placement={'top'}
                  helpBoxProps={{
                    title: t('account_settings.integrations.githubDisconnectTooltip')!
                  }}
                >
                  <Button
                    id={'disconnectGitHub'}
                    variant="primary"
                    type="submit"
                    className="w-100"
                    onClick={() => setShowConfirmationDialog(true)}
                  >
                    <FaGithub />
                    <span className="ps-2">
                      {t('account_settings.integrations.githubDisconnect')}
                    </span>
                  </Button>
                </HelpPopover>
              </>
            ) : (
              <Button
                href={`${API_URL}/integrations/github/install`}
                id={'connectToGitHub'}
                className="w-100"
              >
                <FaGithub />
                <span className="ps-2">{t('account_settings.integrations.githubConnect')}</span>
              </Button>
            )}
          </div>
          <div className={styles.Section}>{session.user && <OpenAiForm session={session} />}</div>
        </div>
      </Col>

      {/* Quotas */}
      <Col className={styles.Section}>
        <div className={`border-bottom ${styles.SectionTitleDiv}`}>
          <span className={`text-body-emphasis ${styles.SectionTitle}`}>{t('Quotas')}</span>
        </div>
        <div style={{ marginTop: 10, display: 'flex', flexDirection: 'column' }}>
          {!session?.user?.openAIKey && (
            <Col className="text-body-bold ">
              OpenAI {t('account_settings.integrations.OpenAIUsageQuota')}: {''}
              <b className={`me-4 ${usage === limit ? 'text-danger' : 'text-body'}`}>
                {usage} / {limit}
              </b>
              {usage === limit && `* ${t('account_settings.integrations.PutYourOwnOpenAIKey')}`}
            </Col>
          )}
          {filteringAuthorities().map((item, index) => (
            <span key={index} style={{ marginTop: 5 }}>
              {renderingCorrectIcon(item.name)}
              {t('tab.settingsAcc.' + item.name)}:{' '}
              {item.name === 'UPLOAD_ASSETS' ? (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 5,
                    width: 400,
                    minWidth: 200
                  }}
                >
                  <span>{showingSizes(item)[0]}</span>
                  <ProgressBar
                    style={{ flexGrow: 1 }}
                    now={calculatingPercentStorage(item)}
                    label={`${calculatingPercentStorage(item).toFixed(1)}%`}
                  />
                  <span>{showingSizes(item)[1]}</span>
                </div>
              ) : (
                <span style={{ fontWeight: 'bold' }}>
                  {renderingCorrectNumberDone(item.name)} /{' '}
                  {item.subscriptionPlanLimits.featureLimit ?? ''}
                </span>
              )}
            </span>
          ))}
        </div>
      </Col>

      <Col className={styles.Section}>
        <div className={`border-bottom ${styles.SectionTitleDiv}`}>
          <span className={`text-body-emphasis ${styles.SectionTitle}`}>
            {t('account_settings.password_settings.ChangePassword')}
          </span>
        </div>
        <div className={styles.FormContent}>
          {session.user && <PasswordChangeForm user={session.user} />}
        </div>
      </Col>

      {/* Change email */}
      <Col className={styles.Section}>
        <div className={`border-bottom ${styles.SectionTitleDiv}`}>
          <span className={`text-body-emphasis ${styles.SectionTitle}`}>
            {t('account_settings.change_email.ChangeEmail')}
          </span>
        </div>
        <div className={styles.FormContent}>
          {session.user && <ChangeEmailForm user={session.user} />}
        </div>
      </Col>

      {/* Delete account */}
      <Col className={styles.Section}>
        <div className={`border-bottom ${styles.SectionTitleDiv}`}>
          <span className={`text-body-emphasis ${styles.SectionTitle}`}>
            {t('account_settings.delete_account.DeleteAccount')}
          </span>
        </div>
        <div className={styles.FormContent}>
          {session.user && <DeleteAccountForm user={session.user} />}
        </div>
      </Col>

      <Confirmation
        show={showConfirmationDialog}
        message={t('account_settings.integrations.githubDisconnect')}
        onCancel={() => setShowConfirmationDialog(false)}
        onConfirmation={() => uninstallGitHub()}
        onClose={() => setShowConfirmationDialog(false)}
      />
    </>
  );
}

export default Account;
