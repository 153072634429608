import React, { forwardRef, memo } from 'react';
import { ComponentUUID } from '../../../types';
import * as Icons from 'react-icons/fa';
import { parseCustomCss, removeDeclarationsWithUndefinedValue } from 'utils/cssUtils';
import { convertingCSS } from 'utils/utils';
import { useSelector } from 'react-redux';
import { InterfaceStudioState } from '../../store';

type FaIconName = keyof typeof Icons | '';

export type BadgesButtonData = {
  width?: number;
  widthUnit?: number | string;
  maxWidth?: number | string;
  maxWidthUnit?: string;
  minWidth?: number | string;
  minWidthUnit?: string;
  widthCalc: number;
  height?: number;
  heightUnit?: number | string;
  maxHeight?: number | string;
  maxHeightUnit?: string;
  minHeight?: number | string;
  minHeightUnit?: string;
  text: string | '';
  leftIcon?: FaIconName | '';
  leftIconRightMargin?: number;
  leftIconSize?: number;
  leftIconStaticColor?: string;
  leftIconThemeColor?: string;
  rightIcon?: FaIconName | '';
  rightIconLeftMargin?: number;
  rightIconSize?: number;
  rightIconStaticColor?: string;
  rightIconThemeColor?: string;
  tooltip?: string;
  disabled?: boolean;
  disabledVar?: string;
  translateKey?: string;
  hasTranslation?: string;
  visible?: string;
  type?: string;
  count?: string;
};

export type BadgesButtonStyles = {
  themeColor?:
    | 'primary'
    | 'secondary'
    | 'success'
    | 'info'
    | 'warning'
    | 'danger'
    | 'light'
    | 'dark';
  badgesColor?: string;
  borderBadges?: string;
  staticColor?: string;
  color?: string;
  fontSize?: number;
  borderRadius?: number;
  marginTop?: number;
  marginBottom?: number;
  marginLeft?: number;
  marginRight?: number;
  borderTop?: number;
  borderLeft?: number;
  borderRight?: number;
  borderBottom?: number;
  paddingTop?: number;
  paddingLeft?: number;
  paddingRight?: number;
  paddingBottom?: number;
  fontWeight?: string;
  lineHeight?: number | string;
  textDecoration?: string | 'none' | 'uppercase' | 'capitalize' | 'capitalize' | 'lowercase';
  textTransform?: string;
  fontFamily?: string;
  fontStyle?: string;
  css?: string;
  borderColor?: string;
  boxshadow?: string;
};

export type BadgesButtonComponentProps = {
  uuid: ComponentUUID;
  data: BadgesButtonData;
  styles: BadgesButtonStyles;
};

function BadgesButton(props: BadgesButtonComponentProps, ref: React.Ref<any>) {
  const theme = useSelector((state: InterfaceStudioState) => state.theme);

  const style = {
    ...(props.data.width &&
      props.data.widthUnit && {
        width: props.data.width + '' + props.data.widthUnit
      }),
    ...(props.data.height &&
      props.data.heightUnit && {
        height: props.data.height + '' + props.data.heightUnit
      }),
    ...(props.data.width &&
      !props.data.widthUnit && {
        width: props.data.width
      }),
    ...(props.data.height &&
      !props.data.heightUnit && {
        height: props.data.height
      }),
    ...(props.data.maxHeight &&
      props.data.maxHeightUnit && {
        maxHeight: props.data.maxHeight + '' + props.data.maxHeightUnit
      }),
    ...(props.data.maxHeight &&
      !props.data.maxHeightUnit && {
        maxHeight: props.data.maxHeight
      }),
    ...(props.data.minHeight &&
      props.data.minHeightUnit && {
        minHeight: props.data.minHeight + '' + props.data.minHeightUnit
      }),
    ...(props.data.minHeight &&
      !props.data.minHeightUnit && {
        minHeight: props.data.minHeight
      }),
    ...(props.data.maxWidth &&
      props.data.maxWidthUnit && {
        maxWidth: props.data.maxWidth + '' + props.data.maxWidthUnit
      }),
    ...(props.data.maxWidth &&
      !props.data.maxWidthUnit && {
        maxWidth: props.data.maxWidth
      }),
    ...(props.data.minWidth &&
      props.data.minWidthUnit && {
        minWidth: props.data.minWidth + '' + props.data.minWidthUnit
      }),
    ...(!props.data.minWidthUnit && {
      minWidth: props.data.minWidth
    }),
    color: props.styles.color,
    fontSize: `${props.styles.fontSize}px`,
    borderRadius: `${props.styles.borderRadius}px`,
    paddingTop: `${props.styles.paddingTop}px`,
    paddingRight: `${props.styles.paddingRight}px`,
    paddingBottom: `${props.styles.paddingBottom}px`,
    paddingLeft: `${props.styles.paddingLeft}px`,
    borderTop: `${props.styles.borderTop}px solid`,
    borderRight: `${props.styles.borderRight}px solid`,
    borderBottom: `${props.styles.borderBottom}px solid`,
    borderLeft: `${props.styles.borderLeft}px solid`,
    marginTop: `${props.styles.marginTop}px`,
    marginRight: `${props.styles.marginRight}px`,
    marginBottom: `${props.styles.marginBottom}px`,
    marginLeft: `${props.styles.marginLeft}px`,
    backgroundColor: `${props.styles.staticColor}`,
    fontWeight: `${props.styles.fontWeight}`,
    lineHeight: `${props.styles.lineHeight}`,
    textDecoration: `${props.styles.textDecoration}`,
    ...(props.styles.fontFamily && {
      fontFamily: `${props.styles.fontFamily}`
    }),
    fontStyle: `${props.styles.fontStyle}`,
    borderColor:
      props.styles.borderColor &&
      convertingCSS(props.styles.borderColor, theme.colors[props.styles.borderColor]),
    boxShadow: props.styles.boxshadow,
    overflow: props.styles.boxshadow ? 'visible' : undefined
  };

  const disabledButtonStyle = props.data.disabled
    ? {
        opacity: '0.65'
      }
    : {};

  const LeftIcon = props.data.leftIcon ? Icons[props.data.leftIcon] : null;
  const leftIconStyle = {
    marginRight: `${props.data.leftIconRightMargin ? props.data.leftIconRightMargin : '0'}px`,
    color: props.data.leftIconStaticColor
  };
  const RightIcon = props.data.rightIcon ? Icons[props.data.rightIcon] : null;
  const rightIconStyle = {
    marginLeft: `${
      props.data.rightIconLeftMargin && props.data.rightIconLeftMargin !== 0
        ? props.data.rightIconLeftMargin
        : '0'
    }px`,
    color: `${props.data.rightIconStaticColor}`
  };

  if (props.data.widthCalc && props.data.widthUnit !== 'px') {
    style.width = `calc(${style.width} - ${props.data.widthCalc}px)`;
  }

  removeDeclarationsWithUndefinedValue(style);

  function renderingBadge(type: string) {
    switch (type) {
      case 'Normal':
        return (
          <button
            ref={ref}
            type="button"
            className={`btn btn-${props.styles.themeColor} ${props.styles.textTransform} `}
            title={props.data.hasTranslation ? `{${props.data.translateKey}}` : props.data.tooltip}
            style={{ ...style, ...disabledButtonStyle, ...parseCustomCss(props.styles.css) }}
            disabled={props.data.disabled}
          >
            {props.data.leftIcon && (
              <div
                className={`d-inline text-${props.data.leftIconThemeColor}`}
                style={leftIconStyle}
              >
                {LeftIcon && <LeftIcon size={props.data.leftIconSize} />}
              </div>
            )}

            {props.data.hasTranslation ? `{${props.data.translateKey}}` : props.data.text}

            <span className={`badge bg-${props.styles.badgesColor}`}>{props.data.count}</span>

            {props.data.rightIcon && (
              <div
                className={`d-inline text-${props.data.rightIconThemeColor}`}
                style={rightIconStyle}
              >
                {RightIcon && <RightIcon size={props.data.rightIconSize} color="currentColor" />}
              </div>
            )}
          </button>
        );
      case 'Rounded-Pill':
        return (
          <button
            ref={ref}
            type="button"
            className={`btn btn-${props.styles.themeColor} ${props.styles.textTransform} position-relative`}
            title={props.data.hasTranslation ? `{${props.data.translateKey}}` : props.data.tooltip}
            style={{ ...style, ...disabledButtonStyle, ...parseCustomCss(props.styles.css) }}
            disabled={props.data.disabled}
          >
            {props.data.leftIcon && (
              <div
                className={`d-inline text-${props.data.leftIconThemeColor}`}
                style={leftIconStyle}
              >
                {LeftIcon && <LeftIcon size={props.data.leftIconSize} />}
              </div>
            )}

            {props.data.hasTranslation ? `{${props.data.translateKey}}` : props.data.text}

            <span
              className={`position-absolute top-0 start-100 translate-middle badge rounded-pill bg-${props.styles.badgesColor}`}
            >
              {props.data.count}
            </span>

            {props.data.rightIcon && (
              <div
                className={`d-inline text-${props.data.rightIconThemeColor}`}
                style={rightIconStyle}
              >
                {RightIcon && <RightIcon size={props.data.rightIconSize} color="currentColor" />}
              </div>
            )}
          </button>
        );
      default:
        return (
          <>
            <button
              ref={ref}
              type="button"
              className={`btn btn-${props.styles.themeColor} ${props.styles.textTransform} `}
              title={
                props.data.hasTranslation ? `{${props.data.translateKey}}` : props.data.tooltip
              }
              style={{ ...style, ...disabledButtonStyle, ...parseCustomCss(props.styles.css) }}
              disabled={props.data.disabled}
            >
              {props.data.leftIcon && (
                <div
                  className={`d-inline text-${props.data.leftIconThemeColor}`}
                  style={leftIconStyle}
                >
                  {LeftIcon && <LeftIcon size={props.data.leftIconSize} />}
                </div>
              )}

              {props.data.hasTranslation ? `{${props.data.translateKey}}` : props.data.text}

              <span className={`badge bg-${props.styles.badgesColor}`}>{props.data.count}</span>

              {props.data.rightIcon && (
                <div
                  className={`d-inline text-${props.data.rightIconThemeColor}`}
                  style={rightIconStyle}
                >
                  {RightIcon && <RightIcon size={props.data.rightIconSize} color="currentColor" />}
                </div>
              )}
            </button>
          </>
        );
    }
  }
  return <>{props.data.type && renderingBadge(props.data.type)}</>;
}
export * from './manifest';
export * from './template';
export default memo(forwardRef(BadgesButton));
