import { InputLinkData, InputLinkStyles } from '.';
import { LayoutComponent } from 'modules/designer/types';

export const INPUT_LINK_TEMPLATE: LayoutComponent<InputLinkData, InputLinkStyles> = {
  uuid: 'empty',
  type: 'INPUTLINK',
  data: {
    label: 'Link',
    width: 100,
    widthUnit: '%',
    maxWidth: 'none',
    minWidth: 'none',
    widthCalc: 20,
    height: 'fit-content',
    heightUnit: '',
    maxHeight: 'none',
    minHeight: 'none'
  },
  styles: {
    marginTop: 10,
    marginRight: 10,
    marginBottom: 10,
    marginLeft: 10,
    borderTop: 0,
    borderRight: 0,
    borderBottom: 0,
    borderLeft: 0,
    paddingTop: 0,
    paddingRight: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    css: '',
    iconHeight: '1rem'
  }
};
