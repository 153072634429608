import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import styles from './styles.module.css';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import {
  ParameterEndpoint,
  DATA_TYPES,
  INPUT_TYPES,
  InputType,
  DataType,
  ObjectSimple
} from 'modules/logic_builder/types';
import { EndpointsService, ObjectsService } from 'modules/logic_builder/services';
import Confirmation from 'web_ui/confirmation';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { parameterNameRegex } from '../../../../../utils/regex';

type EndpointParameterEditorProps = {
  fetchParameters: () => void;
  parameterWithObjectTypeExists: boolean;
  parameter: ParameterEndpoint;
  endpointId: string;
  show: boolean;
  onClose: () => void;
};

export function EndpointParameterEditorDialog(props: EndpointParameterEditorProps) {
  const [parameter, setParameter] = useState<ParameterEndpoint>();
  const [isNameInvalid, setIsNameInvalid] = useState(false);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const { module_id } = useParams();
  const [objects, setObjects] = useState<ObjectSimple[]>([]);
  const [isNative, setIsNative] = useState(false);
  const [errorMessage, setErrorMessage] = useState<boolean>(false);
  const { t } = useTranslation();
  const [disableTypeSelectInput, setDisableTypeSelectInput] = useState(false);

  const fetchObjects = useCallback(async () => {
    if (!module_id) return;
    await ObjectsService.getObjectsByModule(module_id).then((fetchedObjects) => {
      setObjects(fetchedObjects);
    });
  }, [module_id]);

  useEffect(() => {
    if (!props.show) return;

    fetchObjects();
  }, [fetchObjects, props.show]);

  useEffect(() => {
    if (!props.show) return;

    setParameter(props.parameter);
    setDisableTypeSelectInput(props.parameter.inputType === 'BODY');
    setIsNative(props.parameter.native ?? false);
  }, [props.parameter, props.show]);

  async function onDelete() {
    if (!parameter || !parameter.uuid) return;

    await EndpointsService.deleteParameter(props.endpointId, parameter.uuid).then(() => {
      props.fetchParameters();
      setShowConfirmationDialog(false);
      props.onClose();
    });
  }

  async function onSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.stopPropagation();
    event.preventDefault();

    if (!parameter || !parameter.uuid || !module_id) return;

    await EndpointsService.updateParameter(props.endpointId, parameter.uuid, parameter)
      .then(() => {
        props.fetchParameters();
        props.onClose();
      })
      .catch((err) => {
        setErrorMessage(true);
      });
  }

  function parameterInputTypeOnChange(event: ChangeEvent<HTMLSelectElement>) {
    const localEndpointParameter = {
      ...parameter,
      inputType: event.target.value as InputType
    } as ParameterEndpoint;

    if (event.target.value === 'BODY') {
      localEndpointParameter.type = 'OBJECT';
      setDisableTypeSelectInput(true);
    } else {
      if (disableTypeSelectInput) {
        localEndpointParameter.objectUuid = '';
        localEndpointParameter.type = 'BOOLEAN';
        setDisableTypeSelectInput(false);
      }
    }

    setParameter(localEndpointParameter);
  }

  return (
    <>
      <Modal
        show={props.show}
        onHide={() => {
          setErrorMessage(false);
          props.onClose();
        }}
        className={styles.ParameterEditor}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{t('logicBuilder.endepoint.ParameterEditor')}</Modal.Title>
        </Modal.Header>
        <Form onSubmit={onSubmit}>
          <Modal.Body>
            {errorMessage && (
              <span className={styles.errorMsg}>
                {`*${t('logicBuilder.errorCreatingEditingEndpointParameters')}`}
              </span>
            )}
            <Form.Group className="mb-3" controlId="formParameterName">
              <Form.Label>{t('logicBuilder.Name')}</Form.Label>
              <Form.Control
                type="text"
                disabled={isNative}
                placeholder={`*${t('logicBuilder.Name')}...`}
                required
                isInvalid={isNameInvalid}
                value={parameter?.name ?? ''}
                onChange={(e) => {
                  setIsNameInvalid(!parameterNameRegex.test(e.target.value));
                  setParameter({ ...parameter, name: e.target.value } as ParameterEndpoint);
                }}
              />
              <Form.Control.Feedback type="invalid">
                {t('logicBuilder.ValidName')}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formParameterInputType">
              <Form.Label>Input</Form.Label>
              <Form.Select
                required
                disabled={isNative}
                onChange={parameterInputTypeOnChange}
                value={parameter?.inputType ?? 'default'}
              >
                {!parameter?.inputType && <option value="default">Select input type</option>}
                {Object.keys(INPUT_TYPES).map((inputKey) => {
                  return (
                    <option
                      key={inputKey}
                      value={inputKey}
                      disabled={inputKey === 'BODY' && props.parameterWithObjectTypeExists}
                    >
                      {INPUT_TYPES[inputKey as InputType]}
                    </option>
                  );
                })}
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formType">
              <Form.Label>{t('logicBuilder.Type')}</Form.Label>
              <Form.Select
                required
                disabled={parameter?.inputType === 'BODY' || isNative}
                onChange={(e) =>
                  setParameter({
                    ...parameter,
                    type: e.target.value as DataType
                  } as ParameterEndpoint)
                }
                value={parameter?.type ?? 'default'}
              >
                {!parameter?.type && <option value="default">Select parameter type</option>}
                {Object.keys(DATA_TYPES).map((typeKey) => {
                  if (typeKey === 'OBJECT' && parameter?.inputType !== 'BODY') return null;
                  if (typeKey === 'PAGE') return null;

                  return (
                    <option key={typeKey} value={typeKey}>
                      {DATA_TYPES[typeKey as DataType]}
                    </option>
                  );
                })}
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formDescription">
              <Form.Label>{t('logicBuilder.Description')}</Form.Label>
              <Form.Control
                as="textarea"
                disabled={isNative}
                placeholder={`${t('logicBuilder.Description')}...`}
                rows={2}
                maxLength={64}
                className="ml-3"
                onChange={(e) =>
                  setParameter({ ...parameter, description: e.target.value } as ParameterEndpoint)
                }
                value={parameter?.description ?? ''}
              />
            </Form.Group>

            <Row className="mb-3">
              <Col sm={6}>
                <Form.Group controlId="formIsArray">
                  <Form.Check
                    inline
                    disabled={isNative}
                    label="Is Array"
                    type="checkbox"
                    name="isArray"
                    onChange={(e) =>
                      setParameter({ ...parameter, list: e.target.checked } as ParameterEndpoint)
                    }
                    checked={parameter?.list ?? false}
                  />
                </Form.Group>
              </Col>

              <Col sm={6}>
                <Form.Group controlId="formIsRequired">
                  <Form.Check
                    inline
                    disabled={isNative}
                    label="Is Required"
                    type="checkbox"
                    name="isRequired"
                    onChange={(e) =>
                      setParameter({
                        ...parameter,
                        required: e.target.checked
                      } as ParameterEndpoint)
                    }
                    checked={parameter?.required ?? false}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Form.Group className="mb-3" controlId="formType">
              <Form.Label>{t('logicBuilder.Objects')}</Form.Label>
              <Form.Select
                required
                disabled={parameter?.inputType !== 'BODY' || isNative}
                onChange={(e) =>
                  setParameter({
                    ...parameter,
                    objectUuid: e.target.value === 'default' ? '' : e.target.value
                  } as ParameterEndpoint)
                }
                value={parameter?.objectUuid ?? 'default'}
              >
                {!parameter?.objectUuid && <option value={'default'}>Select object</option>}
                {objects.map((object) => {
                  return (
                    <option key={object.uuid} value={object.uuid}>
                      {object.name}
                    </option>
                  );
                })}
              </Form.Select>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button
              id="deleteButton"
              variant="danger"
              onClick={() => setShowConfirmationDialog(true)}
              disabled={isNative}
            >
              {t('logicBuilder.Delete')}
            </Button>
            <Button id="cancelButton" variant="secondary" onClick={() => props.onClose()}>
              {t('logicBuilder.Cancel')}
            </Button>
            <Button
              id="saveButton"
              variant="primary"
              type="submit"
              disabled={isNameInvalid || !parameter?.inputType || !parameter?.type || isNative}
            >
              {t('logicBuilder.Save')}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
      <Confirmation
        show={showConfirmationDialog}
        message={`${t('logicBuilder.DeleteParameter')}`}
        onConfirmation={onDelete}
        onCancel={() => setShowConfirmationDialog(false)}
        onClose={() => setShowConfirmationDialog(false)}
      />
    </>
  );
}
