import React, { ReactNode, useEffect } from 'react';
import styles from './styles.module.css';
import { Frame, FrameUUID } from '.';
import { ContextMenuOption } from '../ContextMenuItem';
import FrameRenderer from './FrameRenderer';
import Background from '../background';
import { useDispatch, useSelector } from 'react-redux';
import { InterfaceStudioState } from 'modules/designer/studio/store';
import {
  setSelectedComponent,
  setSelectedView
} from '../../../modules/designer/studio/store/actions/studio';
import { useLocation, useParams } from 'react-router-dom';
import { LocalStorageManager } from 'utils/localStorage/localstorage';
import { ErrorBoundary } from 'react-error-boundary';
import GenericFallback from 'error_boundaries/genericFallback';

import { useQuery } from 'hooks/useQuery';

interface FrameWrapperProps {
  frames: { [key: FrameUUID]: Frame };
  isLeftSidebarOpen: boolean;
  isRightSidebarOpen: boolean;
  fetchViews: () => void;
  children?: ReactNode;
  contextMenuItems?: ContextMenuOption[];
  showSettings?: boolean;
}

function FrameWrapper({
  frames,
  children,
  isRightSidebarOpen,
  isLeftSidebarOpen,
  fetchViews
}: FrameWrapperProps) {
  const queryParameters = useQuery();
  const selectedViewport = useSelector((state: InterfaceStudioState) => state.studio.viewportMode);
  const { view_id, custom_component_id, app_id, module_id } = useParams();
  const dispatch = useDispatch();

  function handleBackgroudClick() {
    dispatch(setSelectedComponent(null));
    if (view_id) dispatch(setSelectedView(view_id));
    if (custom_component_id) dispatch(setSelectedView(custom_component_id));
  }

  const isFromVsCodeExt = (): boolean => {
    const itemFound = queryParameters.get('vscode');
    if (itemFound) {
      return Boolean(itemFound);
    } else {
      return false;
    }
  };

  return (
    <main
      style={{
        marginLeft: isLeftSidebarOpen ? '240px' : '0',
        width: isRightSidebarOpen
          ? isLeftSidebarOpen
            ? `calc(100vw - ${isFromVsCodeExt() ? '480px' : '545px'})`
            : `calc(100vw - ${isFromVsCodeExt() ? '240px' : '305px'})`
          : isLeftSidebarOpen
          ? `calc(100vw - ${isFromVsCodeExt() ? '240px' : '305px'})`
          : `calc(100vw - ${isFromVsCodeExt() ? '0px' : '65px'})`
      }}
      className={styles.frameWrapperContainer}
      onClick={handleBackgroudClick}
    >
      <div
        style={{
          overflow: 'auto',
          maxWidth: '100%',
          height: '100%',
          width: '98%',
          paddingRight: '10px',
          position: 'relative',
          zIndex: 1
        }}
      >
        <div
          className={`${styles.framesContainer} ${styles.activable}`}
          style={
            selectedViewport !== 'desktop' ? { display: 'flex', justifyContent: 'center' } : {}
          }
        >
          <FrameRenderer frames={frames} />
          {children}
        </div>
      </div>

      <Background />
    </main>
  );
}

export default FrameWrapper;
