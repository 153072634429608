import { generate, parse, walk } from 'css-tree';

// Transforms min-height -> minHeight.
const hyphensToCamelCase = (css: string): string => {
  return css.replace(/-([a-z])/g, (g) => {
    return g[1].toUpperCase();
  });
};

export const parseCustomCss = (css: string | undefined): Record<string, string> => {
  if (!css) return {};

  const customCss: Record<string, string> = {};
  // Record<'css declaration', 'css value'>
  // E.g.: { color: 'red' }. This is returned by this function and injected directly
  // in the styles attribute.

  // Wrap css with '.cssSelector' so that it is a valid css string and can be parsed
  // correctly.
  const ast = parse('.cssSelector{' + css + '}');

  walk(ast, (node) => {
    if (node.type === 'Declaration') {
      // Css declaration
      const declaration = hyphensToCamelCase(node.property);

      // Css value
      let value = generate(node.value);

      if (value && node.important) {
        value += ' !important';
      }

      if (value) {
        customCss[declaration] = value;
      }
    }
  });

  return customCss;
};

export const removeDeclarationsWithUndefinedValue = (
  styles: Record<string, string | number | undefined>
) => {
  for (const declaration of Object.keys(styles)) {
    if (styles[declaration] == null) {
      delete styles[declaration];
    }
  }
};
