import React, { ComponentType, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { InterfaceStudioState } from '../../store';
import { COMPONENTS_MANIFEST } from '../../exocode_components';
import { changeComponentStyle } from '../../store/actions/components';
import { changeViewStyle } from '../../store/actions/views';
import { VIEWS_MANIFEST } from '../../frames';
import { ComponentManifest, LayoutComponent, View, ViewManifest } from '../../../types';
import { ViewStyles } from '../../frames/page_view';
import { ControlProps, ControlsList } from 'web_ui/workboard/sidebar/controls';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.css';
import ComponentHeader from '../components/component_header';

type StylesToolbarProps = {
  name?: string;
  icon?: string;
};

function StylesToolbar(props: StylesToolbarProps) {
  const dispatch = useDispatch();
  const selectedComponent = useSelector(
    (state: InterfaceStudioState) => state.studio.selectedComponent.uuid
  );
  const selectedView = useSelector((state: InterfaceStudioState) => state.studio.selectedView);
  const components = useSelector((state: InterfaceStudioState) => state.components);
  const views = useSelector((state: InterfaceStudioState) => state.views);
  const [manifest, setManifest] = React.useState<ComponentManifest | ViewManifest>();
  const [viewSource, setViewSource] = React.useState<View>();
  const [componentSource, setComponentSource] = React.useState<LayoutComponent>();

  const { t } = useTranslation();
  const handleChange = React.useCallback(
    (value: string, key: string) => {
      if (selectedComponent) {
        dispatch(changeComponentStyle(selectedComponent, key, value));
      } else if (selectedView) {
        dispatch(changeViewStyle(selectedView, key as keyof ViewStyles, value));
      }
    },
    [dispatch, selectedComponent, selectedView]
  );

  React.useEffect(() => {
    if (selectedComponent && components[selectedComponent]) {
      setManifest(COMPONENTS_MANIFEST[components[selectedComponent].type]);
      setComponentSource(components[selectedComponent]);
    } else if (selectedView && views && views[selectedView]) {
      setManifest(VIEWS_MANIFEST[views[selectedView].type]);
      setViewSource(views[selectedView]);
    }
  }, [components, selectedComponent, selectedView, views]);

  function getLabel(label: string) {
    return t('designer.right_side.' + label);
  }
  return (
    <>
      {(selectedComponent || selectedView) && (
        <>
          <ComponentHeader name={props.name} icon={props.icon} />
          <div className={`${styles.controlList} p-3 pt-0`}>
            {manifest?.styles.map((item, index) => {
              const Control = ControlsList[item.controlType] as ComponentType<ControlProps>;
              let value;
              if (item.key === 'themeColor' || item.key === 'staticColor') {
                value = {
                  staticColor: componentSource?.styles['staticColor'],
                  themeColor: componentSource?.styles['themeColor']
                };
              } else {
                value = item.key ? componentSource?.styles[item.key] : componentSource?.styles;
              }
              return selectedComponent ? (
                <Control
                  key={`${selectedComponent}-${index}`}
                  id={item.key}
                  label={getLabel(item.controlLabel)}
                  value={value}
                  options={item.controlOptions}
                  onChange={handleChange}
                  tooltip={item.tooltip}
                />
              ) : (
                <Control
                  key={`${selectedComponent}-${index}`}
                  id={item.key}
                  label={getLabel(item.controlLabel)}
                  value={
                    item.key ? viewSource?.styles[item.key as keyof ViewStyles] : viewSource?.styles
                  }
                  options={item.controlOptions}
                  onChange={handleChange}
                  tooltip={item.tooltip}
                />
              );
            })}
          </div>
        </>
      )}
    </>
  );
}

export default memo(StylesToolbar);
